import { Injectable, Inject } from "@angular/core";
import { Subscription } from 'rxjs';
import { AssembleaService } from './assemblea.service';
import { ReportRequest, ReportParameters } from '../models/assemblea/ReportRequest';
import { ReportTypes, ReportCustomProperties } from '../models/assemblea/enums';
import { SysConfigService } from './sys-config.service';
import { SysConfigItems } from '../models/assemblea/SysConfigItems';
import { ReportUtils, isNullOrUndefined } from '../utils/utils';
import { MatDialog } from '@angular/material/dialog';
import { ReportUsedAsNotarilDeedDialogData, ReportUsedAsNotarilDeedDialogComponent } from '../components/dialogs/report-used-as-notaril-deed-dialog/report-used-as-notaril-deed-dialog.component';
import { PrintedReport } from '../models/assemblea/PrintedReport';
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: 'root'
})
export class PrintingService {
  constructor(private assembleaService: AssembleaService, private sysConfigService: SysConfigService, private dialog: MatDialog, private ngxService: NgxUiLoaderService) {
  }
  public async SendPrintRequest(request: ReportRequest) {


    //SE è una stampa su carta e contiene dati in tempo reale, allora il numero di copie predefinito è preso dal sysconfig
    if (!(request.Parameters.IsPreview || request.Parameters.IsExport)) {
      if (request.ReportType == ReportTypes.Movements || request.ReportType == ReportTypes.ShareholderPresents) {

        let ShowDetailData: boolean = false;

        if (request.Parameters.CustomProperties != null && request.Parameters.CustomProperties.has(ReportCustomProperties.ShowSummaryData)) {
          ShowDetailData = request.Parameters.CustomProperties.get(ReportCustomProperties.ShowSummaryData);
          if (ShowDetailData) {
            let copies: number = 2;
            copies = this.sysConfigService.GetSysConfigValue(SysConfigItems.DefaultStatCopiesNumber, copies);
            request.Parameters.Copies = copies;
          }
        }
      }
    }


    if (ReportUtils.IsReportConDatiSensibiliAgliAnnulli(request.ReportType)) {
      //Chiedi se Ufficiale o no
      let ask: boolean = true;
      ask = this.sysConfigService.GetSysConfigValue(SysConfigItems.EveryTimeAskReportType, ask);
      if (ask) {
        let dialogData: ReportUsedAsNotarilDeedDialogData = new ReportUsedAsNotarilDeedDialogData({
          Request: request
        });
        let dialog = this.dialog.open(ReportUsedAsNotarilDeedDialogComponent, { data: dialogData });
        let result = await dialog.afterClosed().toPromise();
        if (isNullOrUndefined(result)) {
          return;
        } else {
          request = result;
        }
      }
      else {
        let official: boolean = true;
        official = this.sysConfigService.GetSysConfigValue(SysConfigItems.DefaultReportType, official);
        request.Parameters.UsedAsNotarilDeed = official;
      }
    }
    try {
      this.ngxService.start();
    await this.assembleaService.enqueuePrintJobAsync(request);
    } finally {
      this.ngxService.stop();
    }
  }

  public DashboardSendPrintRequest(request: ReportRequest) {
    //SE è una stampa su carta e contiene dati in tempo reale, allora il numero di copie predefinito è preso dal sysconfig
    if (!(request.Parameters.IsPreview || request.Parameters.IsExport)) {
      if (request.ReportType == ReportTypes.Movements || request.ReportType == ReportTypes.ShareholderPresents) {

        let ShowDetailData: boolean = false;

        if (request.Parameters.CustomProperties != null && request.Parameters.CustomProperties.has(ReportCustomProperties.ShowSummaryData)) {
          ShowDetailData = request.Parameters.CustomProperties.get(ReportCustomProperties.ShowSummaryData);
          if (ShowDetailData) {
            let copies: number = 2;
            copies = this.sysConfigService.GetSysConfigValue(SysConfigItems.DefaultStatCopiesNumber, copies);
            request.Parameters.Copies = copies;
          }
        }
      }
    }

    this.assembleaService.enqueuePrintJobAsync(request);

  }


  /// <summary>
  /// Chiamata sincrona usata solo nella printpage
  /// </summary>
  /// <param name="prid"></param>
  /// <param name="errorMessage"></param>
  public GetPrintedReportDocument(data: PrintedReport): string {
    let errorMessage: string = "";

    let reportRequest: ReportRequest = new ReportRequest();
    let parameters: ReportParameters = new ReportParameters();
    parameters.CustomProperties = new Map();

    parameters.PrintOnServer = false;
    parameters.IsPreview = true;
    parameters.EventDescr = '';
    parameters.QueryTime = null;

    parameters.PrinterName = "";
    parameters.UsedAsNotarilDeed = false;


    parameters.PRID = data.PRID;
    reportRequest.ReportType = ReportTypes.CustomMergedReport;

    reportRequest.Parameters = parameters;

    this.assembleaService.enqueuePrintedJobAsync(reportRequest);

    //try {
    //    let repx: any[] = null;
    //    let pdf: any[] = null;
    //  xdatanet.Assemblea.Presenter.ApplicationContext.Instance.SessionService.GetPrintedReportDocument(prid, ref repx, ref pdf);
    //  if (!isNullOrUndefined(repx)) {

    //        dispatcher.Invoke((Action)(() => {


    //            PrintPreviewWpf preview = new PrintPreviewWpf(new ReportResponse() { Document = repx });
    //            preview.Show();

    //        }));
    //    }
    //} catch (Exception exception) {
    //    string msg = String.Concat("Attenzione!", Environment.NewLine, "Si è verificato un'errore durante il caricamento dell'anteprima del report.");
    //    string detail = String.Concat(Environment.NewLine, Environment.NewLine, "Dettaglio Errore:", Environment.NewLine, exception.Message);
    //    Logger.Error(String.Concat(msg, detail), exception);
    //    errorMessage = String.Concat(msg, detail);
    //}
    return errorMessage;
  }
}
