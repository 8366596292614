import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { isNullOrWhiteSpace } from '../../shared/utils/utils';
import { IdentityService, NotificationService } from '../../shared/services';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent } from '../../shared/components/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { AssembleaError } from '../../shared/models/assemblea/AssembleaError';
import { PollDetail } from '../../shared/models/assemblea/PollDetail';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { SysConfigItems } from '../../shared/models/assemblea/SysConfigItems';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SysConfig } from '../../shared/models/assemblea/SysConfig';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { PollTypes, EventType, PollInterval, VoteType, VoteCollectionMode } from '../../shared/models/assemblea/enums';
import CustomStore from 'devextreme/data/custom_store';
import { formatMessage } from 'devextreme/localization';
import { Subscription } from 'rxjs';

declare var $, jQuery: any

const datepipe: DatePipe = new DatePipe('en-US')

@Component({
  selector: 'app-wizard-page',
  templateUrl: './wizard-page.component.html',
  styleUrls: ['./wizard-page.component.scss']
})
export class WizardPageComponent implements OnInit, OnDestroy {

  /*store della tabella delle configurazioni*/
  SysConfigTable: { store: any[] };

  /* array utilizzati per le combobox*/
  public pollTypes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_PALESE", "") }, { ID: 1, Name: formatMessage("LABEL_SEGRETA", "") }];
  public fca_elettiva: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_FCA", "") },{ ID: 1, Name: formatMessage("LABEL_FCA_NV", "") }, { ID: 3, Name: formatMessage("LABEL_ELETTIVA", "") }];
  public votePresentationOrders: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_ORDINE_NUMERO_VOTI", "") }, { ID: 1, Name: formatMessage("LABEL_ORDINE_NUMERO_AZIONI", "") }, { ID: 2, Name: formatMessage("LABEL_ORDINE_FISSO", "") }];
  public comboSiNo: { ID: boolean, Name: string }[] = [{ ID: false, Name: formatMessage("LABEL_NO", "") }, { ID: true, Name: formatMessage("LABEL_YES", "") }];
  public comboSi: { ID: boolean, Name: string }[] = [{ ID: false, Name: " " }, { ID: true, Name: formatMessage("LABEL_YES", "") }];

  /*funzione utilizzata per ricavare la descrizione (segreta/palese) dall'enum corrispondente al ID*/
  public pollTypeValue(key: number) { return PollTypes[key]; }

  /*funzione utilizzata per ricavare la descrizione (segreta/palese) dall'enum corrispondente al ID*/
  public pollTypeShortValue(key: number) { return key == PollTypes.Palese?'P':'S' }

  /*utilizzata per visualizzare il dettaglio (preferenze) della votazione al click su una votazione*/
  private _VotazioneSelezionata = 0;
  public get VotazioneSelezionata() {
    return this._VotazioneSelezionata;
  }
  public set VotazioneSelezionata(value) {
    this._VotazioneSelezionata = value;
  }

  /* mostra/nasconde i dettagli (preferenze) di una votazione al click */
  show(e) {
    this.VotazioneSelezionata = this.VotazioneSelezionata == e.rowIndex ? -1 : e.rowIndex;
  }

  /*lista delle votazioni recuperate da db*/
  private _votazioni: any;
  public get votazioni(): any {
    return this._votazioni;
  }
  public set votazioni(value: any) {
    this._votazioni = value;
  }


  /*variabili per gestire gli step del wizard*/
  private _LastStep: number = 4;
  public get LastStep(): number {
    return this._LastStep;
  }
  public set LastStep(value: number) {
    this._LastStep = value;
  }
  private _ActualStep: number = 1;
  public get ActualStep(): number {
    return this._ActualStep;
  }
  public set ActualStep(value: number) {
    this._ActualStep = value;
  }

  /*campi corrispondenti alle SysConfig del primo step di configurazione*/
  private _nome_azienda: string = "";
  public get NomeAzienda(): string {
    return this._nome_azienda;
  }
  public set NomeAzienda(value: string) {
    this._nome_azienda = value;
  }

  private _nome_assemblea: string = "";
  public get NomeAssemblea(): string {
    return this._nome_assemblea;
  }
  public set NomeAssemblea(value: string) {
    this._nome_assemblea = value;
  }

  private _nome_convocazione: string = "";
  public get NomeConvocazione(): string {
    return this._nome_convocazione;
  }
  public set NomeConvocazione(value: string) {
    this._nome_convocazione = value;
  }

  private _luogo_convocazione: string = "";
  public get LuogoConvocazione(): string {
    return this._luogo_convocazione;
  }
  public set LuogoConvocazione(value: string) {
    this._luogo_convocazione = value;
  }

  private _dataConv: Date = new Date();
  public get DataConvocazione(): Date {
    return this._dataConv;
  }
  public set DataConvocazione(value: Date) {
    if (value != this._dataConv) {
      this._dataConv = value;
    }
  }

  private _LogoConvocazione: string = "";
  public get LogoConvocazione(): string {
    return this._LogoConvocazione;
  }
  public set LogoConvocazione(value: string) {
    this._LogoConvocazione = value;
  }

  /*utilizzato per l'img del logo dell'assemblea*/
  public get LogoConfigID(): number {
    return SysConfigItems.CompanyLogo;
  }

  /*variabile per visibilità dei campi in creazione di una nuova votazione rispetto alla modifica*/
  private _IsNewVotazione: boolean = false;
  public get IsNewVotazione(): boolean {
    return this._IsNewVotazione;
  }
  public set IsNewVotazione(value: boolean) {
    this._IsNewVotazione = value;
  }

  /*per gestire la visibilità del campo "MultipleItemMaxNum" in base al flag "AllowMultipleItem"*/
  private _NewAllowMultipleItem: boolean = false;
  public get NewAllowMultipleItem(): boolean {
    return this._NewAllowMultipleItem;
  }
  public set NewAllowMultipleItem(value: boolean) {
    this._NewAllowMultipleItem = value;
  }
  setAllowMultipleItemCellValue(rowData: any, value: any, currentRowData: any) {
    this.NewAllowMultipleItem = value;
    rowData.AllowMultipleItem = value;
  }

  /*per gestire la visibilità del campo "VotoSegretoConPeso" in base al "PollType" segreto/palese*/
  private _NewPollTypeSecret: boolean = false;
  public get NewPollTypeSecret(): boolean {
    return this._NewPollTypeSecret;
  }
  public set NewPollTypeSecret(value: boolean) {
    this._NewPollTypeSecret = value;
  }
  setPollTypeCellValue(rowData: any, value: any, currentRowData: any) {
    this.NewPollTypeSecret = value;
    rowData.PollType = value;
  }



  /*campi corrispondenti alle SysConfig dello step di configurazione avanzata*/
  private _NumeroSociFisso: boolean = false;
  public get NumeroSociFisso(): boolean {
    return this._NumeroSociFisso;
  }
  public set NumeroSociFisso(value: boolean) {
    this._NumeroSociFisso = value;
  }

  private _NumeroSoci: number;
  public get NumeroSoci(): number {
    return this._NumeroSoci;
  }
  public set NumeroSoci(value: number) {
    this._NumeroSoci = value;
  }

  private _NumeroAzioniFisso: boolean = false;
  public get NumeroAzioniFisso(): boolean {
    return this._NumeroAzioniFisso;
  }
  public set NumeroAzioniFisso(value: boolean) {
    this._NumeroAzioniFisso = value;
  }

  private _NumeroAzioni: number;
  public get NumeroAzioni(): number {
    return this._NumeroAzioni;
  }
  public set NumeroAzioni(value: number) {
    this._NumeroAzioni = value;
  }

  private _AbilitaVotoDisgiunto: boolean = false;
  public get AbilitaVotoDisgiunto(): boolean {
    return this._AbilitaVotoDisgiunto;
  }
  public set AbilitaVotoDisgiunto(value: boolean) {
    this._AbilitaVotoDisgiunto = value;
  }

  private _onlineEnabled: boolean=false;
  get OnlineEnabled(): boolean { return this._onlineEnabled; }
  set OnlineEnabled(value: boolean) {
    this._onlineEnabled = value;

  }

  ngOnInit() { }

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  loadConfig() {
    
    this.OnlineEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.OnlineMovementEnabled, this.OnlineEnabled);
    
  }


  constructor(private ngxService: NgxUiLoaderService,
    private dialog: MatDialog,
    public identityService: IdentityService,
    public sysConfigService: SysConfigService,
    private assembleaService: AssembleaService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef, private router: Router, public _DomSanitizationService: DomSanitizer) {

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);

    /*carica le SysConfig per il primo step e mette i valori nei campi della gui*/
    this.ngxService.start();
    this.loadSysConfigTable().then(() => {
      this.RefreshWizardConfigurationFirstStep();
      this.ngxService.stop();
    });


    /*per abilitare il binding per la gestione della visualizzazione dei campi
     * MultipleItemMaxNum e VotoSegretoConPeso
     * in base a AllowMultipleItem e PollType */
    this.setAllowMultipleItemCellValue = this.setAllowMultipleItemCellValue.bind(this);
    this.setPollTypeCellValue = this.setPollTypeCellValue.bind(this);

    /*store per il CRUD delle votazioni*/
    this.votazioni = new CustomStore({
      key: "PEID",
      load: () => {
        let vot = this.assembleaService.LoadPollEntities();
        vot.then((values) => {
          /*ciclo sulle preferenze per popolare il campo fittizio calcolato "Maggioranza" in base al DefaultPDID*/
          for (let i = 0; i < values.length; i++) {
            for (let j = 0; j < values[i].PollDetails.length; j++) {
              values[i].PollDetails[j].Maggioranza = false;
              values[i].PollDetails[j].MaggioranzaOnline = false;

              if (values[i].PollDetails[j].PDID == values[i].DefaultPDID) {
                values[i].PollDetails[j].Maggioranza = true;
              }

              if (values[i].PollDetails[j].PDID == values[i].DefaultPDIDOnline) {
                values[i].PollDetails[j].MaggioranzaOnline = true;
              }
            }
          }
        });
        return vot;
      },
      insert: (values) => {
        this.VotazioneSelezionata = - 1; //per nascondere il dettaglio delle preferenze di un eventuale votazione selezionata

        /*Se è stato scelto un valore tra Elettiva e F/C/A allora richiamo la stored di creazione Poll con preferenze standard*/
        if (values.TipoPreferenza != null && values.TipoPreferenza != undefined) {
          return this.assembleaService.insertPollStandardVote(values.TipoPreferenza, values.Title, ''
            , values.PollType, PollInterval.Intervallo, VoteType.Manuale, true,
            values.MultipleItemMaxNum != undefined ? values.MultipleItemMaxNum : 1, values.VotePresentationOrder);
        }
        else {
          /*altrimenti creo una votazione senza preferenze*/
          return this.assembleaService.savePollEntity(values);
        }
      },
      remove: (key) => {
        this.VotazioneSelezionata = - 1;
        return this.assembleaService.deletePollEntities([key]);//elmina la votazione
      },

      update: (key, values) => {
        /*In fase di update mi vengono restituiti solo i campi modificati*/
        return this.assembleaService.updatePollEntity(key, values);//aggiorna i campi della votazione modificati
      }

    });
  }


  /*evento per spostare il wizard allo step successivo*/
  async NextStep_Click() {

    switch (this.ActualStep) {
      case 1: /*se sto passando dallo step 1 al 2 salvo le configurazioni del primo step*/
        this.ngxService.start();
        this.SaveConfigFirstStep().then(() => {
          this.ngxService.stop();
        });
        break;
      case 2:/*se sto passando dallo step 2 allo step 3 carico le configurazioni avanzate*/
        this.ngxService.start();
        this.loadSysConfigTable().then(() => {
          this.RefreshWizardConfigurationAdvanced();
          this.ngxService.stop();
        });
        break;
      case 3:/*se sto passando dallo step 3 al successivo salvo le configurazioni avanzate*/
        this.ngxService.start();
        this.SaveConfigAdvanced().then(() => {
          this.ngxService.stop();
        });
        break;
      default:
        break;
    }
    this.VotazioneSelezionata = 0;
    this.ActualStep++;

  }

  /*evento per spostare il wizard allo step precedente*/
  async PreviousStep_Click() {

    switch (this.ActualStep) {
      case 2: /* se sto tornando dallo step 2 allo step 1 ricarico le configurazioni */
        this.ngxService.start();
        this.loadSysConfigTable().then(() => {
          this.RefreshWizardConfigurationFirstStep();
          this.ngxService.stop();
        });
        break;
      default:
        break;
    }
    this.VotazioneSelezionata = 0;
    this.ActualStep--;
  }

  /*evento per tornare alla home dopo aver concluso il wizard*/
  async BackHome_Click() {
    this.router.navigate(['/home']);
  }

  async GoConfigSoci_Click() {
    this.router.navigate(['/import/type']);
  }

  /* Richiama il servizio per recuperare le SysConfig*/
  private async loadSysConfigTable() {
    try {
      let result = await this.assembleaService.getSysConfigTableForEditing(2);
      this.SysConfigTable = { store: SysConfig.ToListOfInstance(result) };
      //this.OriginalSysConfigTable = SysConfig.ToListOfInstance(result);
      //this.InError = false;
      return this.SysConfigTable;
    }
    catch (e) {
      //this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Non è stato possibile caricare la tabella di configurazione aggiornata.",
            details: error.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }

  /* carica le configurazioni nelle variabili locali utilizzate dalla GUI */
  private RefreshWizardConfigurationFirstStep() {
    try {
      this.NomeAzienda = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyName).ActualValue;
      this.NomeAssemblea = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MeetingName).ActualValue;
      this.NomeConvocazione = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationName).ActualValue;
      let _data: string = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationDate).ActualValue;
      if (!isNullOrWhiteSpace(_data))
        this.DataConvocazione = new Date(_data.replace(' ', 'T'));
      this.LuogoConvocazione = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationLocation).ActualValue;
      this.LogoConvocazione = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyLogo).ActualValue;

    }
    catch
    {

    }
  }

  /* Salvataggio delle configurazioni modficabili al primo step */
  private async SaveConfigFirstStep() {

    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyName).ActualValue = this.NomeAzienda;
    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MeetingName).ActualValue = this.NomeAssemblea;
    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationName).ActualValue = this.NomeConvocazione;
    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationDate).ActualValue = datepipe.transform(this.DataConvocazione, 'yyyy-MM-dd HH:mm:ss.000')

    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationLocation).ActualValue = this.LuogoConvocazione;
    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyLogo).ActualValue = this.LogoConvocazione;

    var SysConfigTableToSave: any[] = [];

    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyName));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MeetingName));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationName));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationDate));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ConvocationLocation));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyLogo));

    /* dopo aver salvato le configurazioni le ricarico da DB */
    await this.assembleaService.saveSysConfigList(SysConfigTableToSave);
    this.sysConfigService.load();
    this.loadSysConfigTable().then(() => {
      this.RefreshWizardConfigurationFirstStep();
    });

  }

  /*  ----------- Metodi per la visualizzazione e l'upload del logo ------------ */
  public changeImage(e) {
    console.log(e);
    let configId = $(e.target).data('configid');
    var img = $('img[data-configid="' + configId + '"]');
    let file = e.target.files[0];
    this.previewImage(file, img, configId);
  }
  public selectImage(e, configId) {
    console.log('selectImage', e, configId);
    $('#img_selector_' + configId).click();
  }

  private previewImage(file, target, configId) {

    var reader = new FileReader();
    var sanitizer = this._DomSanitizationService;
    var that = this;
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        // Render thumbnail.
        let index = (<string>e.target.result).indexOf('base64') + 7;
        let b64: string = (<string>e.target.result).substr(index);
        that.LogoConvocazione = b64;
      };
    })(file);

    reader.readAsDataURL(file);
  }
  /*  ----------- Metodi per la visualizzazione e l'upload del logo ------------ */




  /* carica le configurazioni avanzate nelle variabili locali utilizzate dalla GUI */
  private RefreshWizardConfigurationAdvanced() {
    try {
      this.NumeroSociFisso = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedShareholdersNumberFeature).ActualValue == 0 ? false : true;
      this.NumeroSoci = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedShareholdersNumber).ActualValue;

      this.NumeroAzioniFisso = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedSharesNumberFeature).ActualValue == 0 ? false : true;
      this.NumeroAzioni = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedSharesNumber).ActualValue;

      this.AbilitaVotoDisgiunto = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.IndividuallyShareholderVoteEnabled).ActualValue==0?false:true;

    }
    catch
    {

    }
  }

  /* Salvataggio delle configurazioni avanzate modficabili */
  private async SaveConfigAdvanced() {

    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedShareholdersNumberFeature).ActualValue = this.NumeroSociFisso ? 1 : 0;
    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedShareholdersNumber).ActualValue = this.NumeroSoci;
   
    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedSharesNumberFeature).ActualValue = this.NumeroAzioniFisso ? 1 : 0;
    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedSharesNumber).ActualValue = this.NumeroAzioni;

    this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.IndividuallyShareholderVoteEnabled).ActualValue = this.AbilitaVotoDisgiunto?1:0;

    var SysConfigTableToSave: any[] = [];

    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedShareholdersNumberFeature));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedShareholdersNumber));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedSharesNumberFeature));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.FixedSharesNumber));
    SysConfigTableToSave.push(this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.IndividuallyShareholderVoteEnabled));

    /* dopo aver salvato le configurazioni le ricarico da DB */
    await this.assembleaService.saveSysConfigList(SysConfigTableToSave);
    await this.sysConfigService.load();
    await this.loadSysConfigTable()
    this.RefreshWizardConfigurationAdvanced();

  }


  /*pre-valorizza alcuni campi relativi alla nuova preferenza che si va ad inserire*/
  preferenzaInitNewRow(event, data) {
    event.data.PEID = data.PEID;
    event.data.IsSpecial = false;
    event.data.CountIgnore = false;
    event.data.SortN = 1;
    event.data.TelevoterMapping = 1;
    event.data.Maggioranza = false;
    event.data.MaggioranzaOnline = false;

    if (data.PollDetails.length > 0) {
      let lastDetail = data.PollDetails.reduce(function (prev, current) {
        return (prev.LRSortN > current.LRSortN) ? prev : current
      });
      if (lastDetail.SortN != null && lastDetail.SortN != undefined)
        event.data.SortN = lastDetail.SortN + 1;
      if (lastDetail.TelevoterMapping != null && lastDetail.TelevoterMapping != undefined)
        event.data.TelevoterMapping = lastDetail.TelevoterMapping + 1;
    }

  }

  /* gestisce il salvataggio di una nuova preferenza*/
  preferenzaInserted(event, data) {
    event.data.PDID = null;
    //event.data.PEID = null; //generato al salvataggio
    //string event.data.Descr = ; //popolato da gui
    event.data.TDescr = null;
    //int ? event.data.SortN = ;//lo popolo con un progressivo in fase di creazione riga
    event.data.AddedOnTheFly = 0;
    //event.data.IsSpecial = 0;//popolato da gui
    //event.data.CountIgnore = 0;//popolato da gui
    event.data.IsHidden = 0;
    event.data.IsDeactivated = 0;
    //int ? event.data.TelevoterMapping = ; //lo popolo con un progressivo in fase di creazione riga
    event.data.IsReconciliated = false;
    event.data.LongDescr = null;
    event.data.GroupDescr = null;
    event.data.ExternalMapping = '';
    event.data.FavAstContr = '';
    event.data.TAG = null;
    event.data.IsPrintHidden = false;
    event.data.IsListVote = false;

    this.assembleaService.savePollDetail(event.data).then((value) => {
      event.data.PDID = value.Identity;
      if (event.data.Maggioranza == true || event.data.MaggioranzaOnline == true) {

        if (event.data.Maggioranza == true) {
          for (let i = 0; i < data.PollDetails.length; i++) {
            if (data.PollDetails[i].PDID != event.data.PDID) {
              data.PollDetails[i].Maggioranza = false;
            }
          }
          data.DefaultPDID = event.data.PDID;
        }

        if (event.data.MaggioranzaOnline == true) {
          for (let i = 0; i < data.PollDetails.length; i++) {
            if (data.PollDetails[i].PDID != event.data.PDID) {
              data.PollDetails[i].MaggioranzaOnline = false;
            }
          }
          data.DefaultPDIDOnline = event.data.PDID;
        }

        this.assembleaService.savePollEntity(data);
      }
    });

  }

  /* gestisce la modifica di una preferenza, aggiornando il voto di maggioranza della votazione
   * nel caso in cui sia stata selezionata come votazione di maggioranza */
  preferenzaUpdated(event, data) {
    this.assembleaService.savePollDetail(event.data);
    if ((event.data.Maggioranza == true && event.data.PDID != data.DefaultPDID) || (event.data.MaggioranzaOnline == true && event.data.PDID != data.DefaultPDIDOnline)) {
      if (event.data.Maggioranza == true && event.data.PDID != data.DefaultPDID) {
        for (let i = 0; i < data.PollDetails.length; i++) {
          if (data.PollDetails[i].PDID != event.data.PDID) {
            data.PollDetails[i].Maggioranza = false;
          }
        }
        data.DefaultPDID = event.data.PDID;
      }
      if (event.data.MaggioranzaOnline == true && event.data.PDID != data.DefaultPDIDOnline) {
        for (let i = 0; i < data.PollDetails.length; i++) {
          if (data.PollDetails[i].PDID != event.data.PDID) {
            data.PollDetails[i].MaggioranzaOnline = false;
          }
        }
        data.DefaultPDIDOnline = event.data.PDID;
      }
      this.assembleaService.savePollEntity(data);
    }
    else if ((event.data.Maggioranza == false && event.data.PDID == data.DefaultPDID) || (event.data.MaggioranzaOnline == false && event.data.PDID == data.DefaultPDIDOnline)) {
      if (event.data.Maggioranza == false && event.data.PDID == data.DefaultPDID) { data.DefaultPDID = null; }
      if (event.data.MaggioranzaOnline == false && event.data.PDID == data.DefaultPDIDOnline) { data.DefaultPDIDOnline = null; }
      this.assembleaService.savePollEntity(data);
    }
  }

  /* elimina una preferenza, aggiornando il voto di maggioranza della votazione a null
   * nel caso in cui sia stata eliminata proprio la preferenza di maggioranza */
  preferenzaRemoved(event, data) {
    if (event.data.PDID != undefined) {
      if ((event.data.Maggioranza == true && event.data.PDID == data.DefaultPDID) || (event.data.MaggioranzaOnline == true && event.data.PDID == data.DefaultPDIDOnline)) {
        if (event.data.Maggioranza == true && event.data.PDID == data.DefaultPDID) { data.DefaultPDID = null; }
        if (event.data.MaggioranzaOnline == true && event.data.PDID == data.DefaultPDIDOnline) { data.DefaultPDIDOnline = null; }
        this.assembleaService.savePollEntity(data);
      }
      this.assembleaService.deletePollDetails([event.data.PDID]);
    }

  }


  /*gestione del edit e delete della griglia delle votazioni*/
  private dataGrid: any;

  saveGridInstance(e) {
    this.dataGrid = e.component;
  }

  /*pre-valorizza alcuni campi relativi alla nuova votazione che si va ad inserire
   Li aggiungo tutti (anche quelli null) in modo da averlli alla fine nell'oggetto che passerò al servizio per l'inserimento*/
  handleInitNewRow(e) {
    this.IsNewVotazione = true;
    this.NewAllowMultipleItem = false;
    this.NewPollTypeSecret = false;

    var votazioni = e.component.getDataSource().items();

    let pd: PollDetail[] = [];
    e.data.PollDetails = pd;

    e.data.PEID = null;
    e.data.PollType = 0;
    e.data.EvaluationType = PollInterval.Intervallo;
    e.data.VoteType = VoteType.Manuale;
    e.data.AllowMultipleItem = false;
    e.data.MultipleItemMaxNum = 1;
    e.data.MultipleItemMinNum = 1;
    e.data.AllowExternalItem = false;
    e.data.AllowSpecialVotes = true;
    e.data.ODG = votazioni == null || votazioni.length == 0 ? 1 : votazioni[votazioni.length - 1].ODG + 1;
    //e.data.Title = ;//popolato da gui
    e.data.TTitle = '';
    e.data.Descr = '';
    e.data.TDescr = '';
    e.data.SortN = votazioni == null || votazioni.length == 0 ? 1 : votazioni[votazioni.length - 1].SortN + 1;
    e.data.VCID = null;
    e.data.VotePresentationOrder = 0;
    e.data.OrderSpecialItemAsc = false;
    e.data.DefaultPDID = null;
    e.data.AutoConfirmVote = true;
    e.data.CalcolaPercentualeSuTotaleNominale = false;
    e.data.ExternalMapping = '';
    e.data.ExternalItemPrefix = null;
    e.data.VotePerSingleShareholder = 0;
    e.data.ModalitaScrutinioSmartphone = VoteCollectionMode.TutteAttive;
    e.data.TipologiaVotazione = EventType.Votazione;
    e.data.VisibleOnline = true;
    e.data.VotoSegretoConPeso = false;
    e.data.UseMaxItemsPerTag = false;
    e.data.DefaultPDIDOnline = null;
  }


  handleEdit(e) {
    this.IsNewVotazione = false;
    this.NewAllowMultipleItem = e.data.AllowMultipleItem;
    this.NewPollTypeSecret = e.data.PollType == 0 ? false : true;
    this.dataGrid.editRow(e.rowIndex);
  }
  handleDelete(e) {
    this.dataGrid.deleteRow(e.rowIndex);
  }









}


