import { Component, Input, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { SysConfigService } from '../../services/sys-config.service';
import { SysConfigItems, vPotentialAttendant, VipNoteStyle, CapabilityItems } from '../../models/assemblea/assemblea-models';
import { AssembleaService } from '../../services/assemblea.service';
import notify from 'devextreme/ui/notify';
import { formatMessage } from 'devextreme/localization';
import { IdentityService } from '../../services';
import { ShareholderTypeSelectorComponent } from './shareholder-type-selector/shareholder-type-selector.component';
import { MatDialog } from '@angular/material/dialog'
import { Subject, Subscription } from 'rxjs';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../utils/utils';
import { config } from 'process';
import { ShareholderZoneSelectorComponent } from './shareholder-zone-selector/shareholder-zone-selector.component';

class ViewConfig {
  UseNoSBAndNoSC: boolean;
  SharesLabel: string;
  SharesBLabel: string;
  SharesCLabel: string;
  SharesDLabel: string;

  CertificatesDeliveryManagementEnabled: boolean;
  CertificatesDeliveryVisible: boolean;

  VipNoteFontSize: number;
  VipNoteShareholderTypeColor: string;
  VipNoteSingleColor: string;

  BirthDateVisible: boolean;
  BirthDateText: string;
  BirthPlaceVisible: boolean;
  BirthPlaceText: string;
  AddressVisible: boolean;
  AddressText: string;
  CityVisible: boolean;
  CityText: string;
  ZipCodeVisible: boolean;
  ZipCodeText: string;
  AgencyVisible: boolean;
  AgencyText: string;
  SharesVisible: boolean;
  SharesBVisible: boolean;
  SharesCVisible: boolean;
  SharesDVisible: boolean;
  SharesText: string;
  SharesBText: string;
  SharesCText: string;
  SharesDText: string;

  ProvinceVisible: boolean;
  ProvinceText: string;

  CFPIVAVisible: boolean;
  CFPIVAText: string;

  CustomField1Visible: boolean;
  CustomField1Text: string;
  CustomField2Visible: boolean;
  CustomField2Text: string;
  CustomField3Visible: boolean;
  CustomField3Text: string;

  ZonaVisible: boolean;
  ZonaText: string;
  GruppoVisible: boolean;
  GruppoText: string;

  CSIDVisible: boolean;
  CSIDText: string;
  CDGVisible: boolean;
  CDGText: string;

  InfoSocioFontSize: number;

  ShareholderDocVisible: boolean;
  ShareholderDocText: string;
  ShareholderDocValue: number;
  ShareholderDocFolder: string;

  CategoriesVisible: boolean;
  CategoriesText: string;
}
class Capabilities {
  CanViewShares: boolean;
  CanEditShareholderType: boolean;
  CanEditShareholderZone: boolean;
}
@Component({
  selector: 'asse-search-and-show-shareholder',
  templateUrl: './search-and-show-shareholder.component.html',
  styleUrls: ['./search-and-show-shareholder.component.scss']
})
/** SearchAndShowShareholder component*/
export class SearchAndShowShareholderComponent implements AfterViewInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  private _selectedPA: vPotentialAttendant = null;
  get SelectedPA() {
    if (isNullOrUndefined(this._selectedPA)) return null;
    return this._selectedPA;
  }
  @Input() set SelectedPA(pa: vPotentialAttendant) {
    this._selectedPA = pa;
    this.setupVipNoteStyles();
    this.setupInfo();
  }
  @Input() Query: string = "";
  @ViewChild(ShareholderTypeSelectorComponent) ShareholderTypeSelector: ShareholderTypeSelectorComponent;
  @ViewChild(ShareholderZoneSelectorComponent) ShareholderZoneSelector: ShareholderZoneSelectorComponent;
  @ViewChild('SearchBox') SearchBox: DxTextBoxComponent;
  configs: ViewConfig = new ViewConfig();
  capabilities: Capabilities = new Capabilities();

  searchButtonOptions: any;
  vipNoteStyles: VipNoteStyle[] = [];
  loaderVisible: boolean = false;

  ShareholderTypeDescrText: string = "";
  ShareholderForeground: string = "";
  ShareholderTypeImgUrl: string = "";

  PerformSearch: Subject<string> = new Subject();
  PerformFind: Subject<vPotentialAttendant> = new Subject();

  constructor(dialog: MatDialog, public sysConfigService: SysConfigService, private assembleaService: AssembleaService, public identityService: IdentityService, private cd: ChangeDetectorRef) {
    this.searchButtonOptions = {
      icon: "search",
      type: "default",
      stylingMode: "text",
      onClick: () => {
        if (!isNullOrWhiteSpace(this.Query)){
          this.PerformSearch.next(this.Query);
        }
      }
    };

    let sysConfigSub = this.sysConfigService.ready$.subscribe(() => {
      this.loadConfig();
      //this.cd.detectChanges();
    });
    this.subscriptions.add(sysConfigSub);

    this.loadCapabilities();

    assembleaService.getVipNoteStyles().then(styles => {
      this.vipNoteStyles = styles.map(style => { //<-- gli stili sono pensati per WPF e quindi il codice colore ha anche la parte alpha (ARGB) e ha quindi 2 FF in più eli rimuovo
        style.Foreground = '#' + style.Foreground.substring(3);
        style.Background = '#' + style.Background.substring(3);
        return new VipNoteStyle(style);
      });
    }, error => {
      notify('Error loading VipNoteStyles ' + error.error, 'warning', 5000);
    });
  }
  ngAfterViewInit(): void {
    let sub = this.ShareholderTypeSelector.OnConfirm.subscribe(STID => {
      this.loaderVisible = true;
      this.assembleaService.updatePotentialAttendantShareholderType(this.SelectedPA.PAID, STID).then(() => {
        this.assembleaService.findVPotentialAttendantByPAID(this.SelectedPA.PAID).then(pa => {
          this.SelectedPA = pa;
          this.setupVipNoteStyles();
          this.setupInfo();
          this.loaderVisible = false;
        }, error => {
          notify(error.error, 'error', 5000);
          this.SelectedPA = null; //<-- così è costretto a rifare una ricerca per caricare i dati corretti
          this.loaderVisible = false;
        });
      }, error => {
        notify(error.error, 'error', 5000);
        this.loaderVisible = false;
      });
    });
    this.subscriptions.add(sub);

    sub = this.ShareholderZoneSelector.OnConfirm.subscribe(ZoneId => {
      this.loaderVisible = true;
      this.assembleaService.updatePotentialAttendantShareholderZone(this.SelectedPA.PAID, ZoneId).then(() => {
        this.assembleaService.findVPotentialAttendantByPAID(this.SelectedPA.PAID).then(pa => {
          this.SelectedPA = pa;
          this.setupVipNoteStyles();
          this.setupInfo();
          this.loaderVisible = false;
        }, error => {
          notify(error.error, 'error', 5000);
          this.SelectedPA = null; //<-- così è costretto a rifare una ricerca per caricare i dati corretti
          this.loaderVisible = false;
        });
      }, error => {
        notify(error.error, 'error', 5000);
        this.loaderVisible = false;
      });
    });
    this.subscriptions.add(sub);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.PerformSearch.complete();
    this.PerformFind.complete();
  }
  ChangeSelectedPA(pa: vPotentialAttendant) {
    this.SelectedPA = pa;
    this.setupVipNoteStyles();
    this.setupInfo();
  }
  FocusSearch() {
    if (!isNullOrUndefined(this.SearchBox))
      this.SearchBox.instance.focus()
  }

  onKeyDownSearch(e) {
    var keyCode = e.event.key;
    if (keyCode == "Enter") {
      if (!isNullOrWhiteSpace(this.Query)) {
        this.PerformSearch.next(this.Query);
      }
    }

  }
  setupInfo() {
    this.configs.CategoriesVisible = false;
    if (this.SelectedPA != null) {

      this.assembleaService.GetOwnedCategories(this.SelectedPA.PAID).then(cat => {
        this.SelectedPA.Categories = cat;
        if (this.SelectedPA.Categories.length > 0) {
          this.configs.CategoriesVisible = true;
        }
      });

      this.ShareholderTypeImgUrl = 'assets/images/sht/' + this.SelectedPA.ImgID + '.png';
      let mes: string = "";
      if (this.SelectedPA.CanAccess == true) {
        if ((this.SelectedPA.CanVote != true)) {
          mes += formatMessage("LABEL_MP_CANT_VOTE", "");
        }
        if ((this.SelectedPA.CanHaveDelegate == true)) {
          mes += formatMessage("LABEL_MP_CAN_HAVE_N_DELEGATION", ...[this.SelectedPA.MaxDelegate.toString(), this.SelectedPA.MaxDelegate > 1 ? formatMessage("LABEL_DELEGHE_LOWECASE", "") : formatMessage("LABEL_DELEGA_LOWERCASE", "")]);
        }
        else {
          mes += formatMessage("LABEL_MP_CANT_HAVE_DELEGATION", "");
        }

        if (this.SelectedPA.CanDelegate == true) {
          mes += formatMessage("LABEL_MP_CAN_DELEGATE", "");
        }
        else {
          mes += formatMessage("LABEL_MP_CANT_DELEGATE", "");
        }
      }
      else {
        mes += formatMessage("LABEL_MP_CANT_ENTER", "");
      }

      this.ShareholderTypeDescrText = mes;
      this.ShareholderForeground = "#09A309";

      if (this.SelectedPA.Vip == true || this.SelectedPA.STVip == true) {
        this.ShareholderTypeImgUrl = 'assets/images/sht/shtype_vip.png';
        this.ShareholderForeground = "#CF2525";
      }
      
    }
  }
  setupVipNoteStyles() {
    if (this.SelectedPA != null) {
      this.SelectedPA.VipNoteStyle = null;
      if (this.SelectedPA.VipNoteType != null) {
        if (this.vipNoteStyles != null && this.vipNoteStyles.length > 0) {
          this.SelectedPA.VipNoteStyle = this.vipNoteStyles.find(style => { return style.VipID == this.SelectedPA.VipNoteType });
        }
      }
      if (this.SelectedPA.VipNoteStyle == null) {
        this.SelectedPA.VipNoteStyle = new VipNoteStyle({
          VipID: 0,
          Descr: "default",
          Foreground: "White",
          Background: '#' + this.configs.VipNoteSingleColor.substring(2),
          FontSize: this.configs.VipNoteFontSize,
          FontWeight: "Normal"
        });

      }

      this.SelectedPA.STVipNoteStyle = null;
      if (this.SelectedPA.STVipNoteType != null) {
        if (this.vipNoteStyles != null && this.vipNoteStyles.length > 0) {
          this.SelectedPA.STVipNoteStyle = this.vipNoteStyles.map((style) => { style.Background = '#' + style.Background.substring(2); return new VipNoteStyle(style); }).find(style => { style.VipID == this.SelectedPA.STVipNoteType });
        }
      }
      if (this.SelectedPA.STVipNoteStyle == null) {
        this.SelectedPA.STVipNoteStyle = new VipNoteStyle({
          VipID: 0,
          Descr: "default",
          Foreground: "White",
          Background: this.configs.VipNoteShareholderTypeColor,
          FontSize: this.configs.VipNoteFontSize,
          FontWeight: "Normal"
        });
      }
    }
  }
  loadCapabilities() {
    this.capabilities.CanViewShares = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanViewShares });
    this.capabilities.CanEditShareholderType = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanEditShareholderType });
    this.capabilities.CanEditShareholderZone = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanEditShareholderZone });
  }
  loadConfig() {
    this.configs.UseNoSBAndNoSC = this.sysConfigService.GetSysConfigValue(SysConfigItems.UseNoSBAndNoSC, false);
    this.configs.SharesLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesLabel, "Azioni");
    this.configs.SharesBLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBLabel, "Azioni B");
    this.configs.SharesCLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCLabel, "Azioni C");
    this.configs.SharesDLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDLabel, "Azioni D");
    this.configs.SharesVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesVisible, false);
    this.configs.SharesBVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBVisible, false);
    this.configs.SharesCVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCVisible, false);
    this.configs.SharesDVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDVisible, false);
    this.configs.SharesText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesText, "Azioni");
    this.configs.SharesBText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBText, "Azioni B");
    this.configs.SharesCText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCText, "Azioni C");
    this.configs.SharesDText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDText, "Azioni D");

    this.configs.CertificatesDeliveryManagementEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.CertificatesDeliveryManagementEnabled, false);
    this.configs.CertificatesDeliveryVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CertificatesDeliveryVisible, false);

    this.configs.VipNoteFontSize = this.sysConfigService.GetSysConfigValue(SysConfigItems.VipNoteFontSize, 14);
    this.configs.VipNoteShareholderTypeColor = this.sysConfigService.GetSysConfigValue(SysConfigItems.VipNoteShTypeColor, "Orange");
    this.configs.VipNoteSingleColor = this.sysConfigService.GetSysConfigValue(SysConfigItems.VipNoteSingleColor, "Red");

    this.configs.BirthDateVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.BirthDateVisible, false);
    this.configs.BirthDateText = this.sysConfigService.GetSysConfigValue(SysConfigItems.BirthDateText, "Data di nascita");
    this.configs.BirthPlaceVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.BirthPlaceVisible, false);
    this.configs.BirthPlaceText = this.sysConfigService.GetSysConfigValue(SysConfigItems.BirthPlaceText, "Luogo di nascita");
    this.configs.AddressVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.AddressVisible, false);
    this.configs.AddressText = this.sysConfigService.GetSysConfigValue(SysConfigItems.AddressText, "Indirizzo");
    this.configs.CityVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CityVisible, false);
    this.configs.CityText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CityText, "Città");
    this.configs.ZipCodeVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.ZipcodeVisible, false);
    this.configs.ZipCodeText = this.sysConfigService.GetSysConfigValue(SysConfigItems.ZipcodeText, "CAP");
    this.configs.AgencyVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.AgencyVisible, false);
    this.configs.AgencyText = this.sysConfigService.GetSysConfigValue(SysConfigItems.AgencyLabel, "Agenzia");


    this.configs.ProvinceVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.ProvinceVisible, false);
    this.configs.ProvinceText = this.sysConfigService.GetSysConfigValue(SysConfigItems.ProvinceText, "Provincia");

    this.configs.CFPIVAVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CFPIVAVisible, false);
    this.configs.CFPIVAText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CFPIVAText, "C.F. o P.IVA");

    this.configs.CustomField1Visible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CustomField1Visible, false);
    this.configs.CustomField1Text = this.sysConfigService.GetSysConfigValue(SysConfigItems.CustomField1Text, "Custom Field 1");
    this.configs.CustomField2Visible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CustomField2Visible, false);
    this.configs.CustomField2Text = this.sysConfigService.GetSysConfigValue(SysConfigItems.CustomField2Text, "Custom Field 2");
    this.configs.CustomField3Visible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CustomField3Visible, false);
    this.configs.CustomField3Text = this.sysConfigService.GetSysConfigValue(SysConfigItems.CustomField3Text, "Custom Field 3");

    this.configs.ZonaVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.ZonaVisible, false);
    this.configs.ZonaText = this.sysConfigService.GetSysConfigValue(SysConfigItems.ZonaText, "Zona");
    this.configs.GruppoVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.GruppoVisible, false);
    this.configs.GruppoText = this.sysConfigService.GetSysConfigValue(SysConfigItems.GruppoText, "Gruppo");

    this.configs.CSIDVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CSIDVisible, false);
    this.configs.CSIDText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CSIDText, "Codice");

    this.configs.CDGVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CDGVisible, false);
    this.configs.CDGText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CDGText, "CDG");

    this.configs.InfoSocioFontSize = this.sysConfigService.GetSysConfigValue(SysConfigItems.InfoSocioFontSize, 14);

    this.configs.ShareholderDocVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShareholderDocVisible, false);
    this.configs.ShareholderDocText = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShareholderDocText, "");
    this.configs.ShareholderDocValue = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShareholderDocValue, 0);
    this.configs.ShareholderDocFolder = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShareholderDocFolder, "");

    this.configs.CategoriesText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CategoriesText, "");

  }

  changeShareholderType() {
    this.ShareholderTypeSelector.show(this.SelectedPA.STID);
  }

  changeShareholderZone() {
    this.ShareholderZoneSelector.show(this.SelectedPA.DelegationZone);
  }

  public ShowPACertificationStatus() {
    //if (this.configs.CertificatesDeliveryManagementEnabled && SelectedShareHolder != null) {
    //    if (SelectedShareHolder.HasCertification == true) {
    //        HasCertificationForeground = new SolidColorBrush(Colors.Green);
    //        HasCertificationFontWeight = FontWeights.Bold;
    //    }
    //    else {
    //        HasCertificationForeground = new SolidColorBrush(Colors.Red);
    //        HasCertificationFontWeight = FontWeights.Bold;
    //    }
    //}
    //else {
    //    if (CertificatesDeliveryVisible) {
    //        HasCertificationForeground = Brushes.DimGray;
    //        HasCertificationFontWeight = FontWeights.Normal;
    //    }
    //}
  }
}
