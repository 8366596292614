import { Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AssembleaService } from '../../../services/assemblea.service';
import notify from 'devextreme/ui/notify';
import { DxFormComponent } from 'devextreme-angular';
import { ShareholderZone } from '../../../models/assemblea/ShareholderZone';

class FormData {
  ZoneId: string
}

@Component({
    selector: 'asse-shareholder-zone-selector',
    templateUrl: './shareholder-zone-selector.component.html',
    styleUrls: ['./shareholder-zone-selector.component.scss']
})
/** shareholder-zone-selector component*/
export class ShareholderZoneSelectorComponent implements OnDestroy {
  @ViewChild(DxFormComponent) form: DxFormComponent

  @Input() selectedZone: string = null;
  @Input() shareholderZones: ShareholderZone[] = []
  popupVisible: boolean = false;
  loaderVisible: boolean = false;
  formData: FormData = new FormData();
  OnClose: Subject<void> = new Subject();
  OnCancel: Subject<void> = new Subject();
  OnConfirm: Subject<string> = new Subject();
  constructor(private assembleaService: AssembleaService) {
    if (this.shareholderZones == null || this.shareholderZones == undefined || this.shareholderZones.length == 0) {
      this.loaderVisible = true;
      this.assembleaService.loadZones().then(
        Zones => { this.shareholderZones = Zones },
        error => { notify('Error loading shareholder zones: ' + error, 'warning', 5000) }
      ).finally(() => {
        this.loaderVisible = false;
      });
    }
    this.onOkClick = this.onOkClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.formData.ZoneId = this.selectedZone;
  }
  ngOnDestroy(): void {
    this.OnClose.complete();
    this.OnCancel.complete();
    this.OnConfirm.complete();
  }
  show(zone?: string) {
    this.selectedZone = zone;
    this.formData.ZoneId = zone;
    this.popupVisible = true;
  }
  close() {
    this.popupVisible = false;
    this.OnClose.next();
  }

  confirm() {
    this.OnConfirm.next(this.formData.ZoneId);
    this.clear();
    this.close();
  }
  cancel() {
    this.clear();
    this.close();
  }
  clear() {
    this.selectedZone = null;
  }
  onPopupHiding(e) {
    this.OnClose.next();
  }
  onOkClick(e) {
    let result = this.form.instance.validate();
    if (result.isValid) {
      this.confirm();
    }
  }
  onCancelClick(e) {
    this.cancel();
  }
}
