import { Injectable } from '@angular/core';
import { ConfigurationService } from '.';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PotentialAttendant, vPotentialAttendant, VipNoteStyle, ShareholderType, AccountingDetail, Report, VoteCardDeliveryInformation, AccountingDetailStatus, SysConfig, Principal, PollEntity, PollDetail, vLegalRepresent, Role, Capability, PreVote } from '../models/assemblea/assemblea-models';
import { IFindPotentialAttendantResponse } from '../models/responses/IFindPotentialAttendantResponse';
import { ReportRequest } from '../models/assemblea/ReportRequest';
import { vEventoAssemblea } from '../models/assemblea/vEventoAssemblea';
import { PollVote } from '../models/assemblea/PollVote';
import { vShareholders } from '../models/assemblea/vShareholders';
import { DelegatorsToDelegate } from '../models/assemblea/DelegatorsToDelegate';
import { ImportDelegatorsRequest } from '../models/request/ImportDelegatorsRequest';
import { ImportResults } from '../models/responses/ImportResults';
import { ProjectionGroupType, SmartphoneStatus, PollingBoothStatus } from '../models/assemblea/enums';
import { ProjectionGroup } from '../models/assemblea/ProjectionGroup';
import { ProjectionPage } from '../models/assemblea/ProjectionPage';
import { SpeechTopic, SpeechTopicOnline, TopicLocalization, SpeechRequestOnline } from '../models/assemblea/SpeechTopic';
import { CurrentProjectionPageResponse } from '../models/responses/CurrentProjectionPageResponse';
import { ResetSummary } from '../models/assemblea/ResetSummary';
import { PollEntity2PeidStid } from '../models/assemblea/PollEntity2PeidStid';
import { RealTimePoll } from '../models/assemblea/RealTimePoll';
import { SoftwareInfo } from '../models/assemblea/SoftwareInfo';
import { VoteCard } from '../models/assemblea/VoteCard';
import { PollEntityTagConfig } from '../models/assemblea/PollEntityTagConfig';
import { CollectedVotesStatus } from '../models/assemblea/CollectedVotesStatus';
import { PollVoteStats } from '../models/assemblea/PollVoteStats';
import { vPollDetail } from '../models/assemblea/vPollDetail';
import { GetCurrentSmartphoneOpenedPEIDResponse } from '../models/responses/GetCurrentSmartphoneOpenedPEIDResponse';
import { vActualVoteBuffer } from '../models/assemblea/vActualVoteBuffer';
import { VoteBufferStatus } from '../models/assemblea/VoteBufferStatus';
import { SmartphoneOperationStatus } from '../models/assemblea/SmartphoneOperationStatus';
import { ServiceSmartphoneResponse } from '../models/responses/ServiceSmartphoneResponse';
import { PollingBoothOperationStatus } from '../models/assemblea/PollingBoothOperationStatus';
import { ServicePollingBoothResponse } from '../models/responses/ServicePollingBoothResponse';
import { Agency } from '../models/assemblea/Agency';
import { PrintedReport } from '../models/assemblea/PrintedReport';
import { ProjectionImageContent } from '../models/assemblea/ProjectionImageContent';
import { ProjectionPageToGroup } from '../models/assemblea/ProjectionPageToGroup';
import { StoredReport2Event } from '../models/assemblea/StoredReport2Event';
import { Culture } from '../models/assemblea/Culture';
import { ExtraResourceType } from '../models/assemblea/ExtraResourceType';
import { ExtraResource } from '../models/assemblea/ExtraResource';
import { SmartphoneLog } from '../models/assemblea/SmartphoneLog';
import { SmartphoneLogDetail } from '../models/assemblea/SmartphoneLogDetail';
import { isNullOrUndefined } from '../utils/utils';
import { AsseBackgroundJob } from '../models/assemblea/AsseBackgroundJob';
import { PreSysConfig } from '../models/assemblea/PreSysConfig';
import { ResourcesDictionaryItem } from '../models/assemblea/LocalizationsModel';
import { ShareholderZone } from '../models/assemblea/ShareholderZone';
import { ComunicazioneMT } from '../models/assemblea/ComunicazioneMT';
import { ShareholderCategory } from '../models/assemblea/ShareholderCategory';
import { ImportCategoryRequest } from '../models/request/ImportCategoryRequest';
import { ShareholderToCategory } from '../models/assemblea/ShareholderToCategory';
import { ImportPrincipalRequest } from '../models/request/ImportPrincipalRequest';
import { EPollVotingSessions } from '../models/assemblea/EPollVotingSessions';

@Injectable({
  providedIn: 'root'
})
export class AssembleaService {
  private configurationReady: boolean = false;
  constructor(private configurationService: ConfigurationService, private http: HttpClient) { }

  public async getSysConfigs() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetSysConfigs`).toPromise();
  }

  public async getServerDatetime() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetServerDatetime`).toPromise<Date>();
  }

  public async getLegalRepresentsByPAID(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetLegalRepresentsByPAID?paid=${paid}`).toPromise();
  }
  public async findPotentialAttendant(query: string, includeNonShareholders: boolean) {
    return await this.http.post<IFindPotentialAttendantResponse>(`${this.configurationService.serverSettings.webApiServiceUrl}/findpotentialattendant`, { query, includeNonShareholders }).toPromise<IFindPotentialAttendantResponse>();
  }

  public async findPotentialAttendantByCodes(query: string, includeNonShareholders: boolean) {
    return await this.http.post<IFindPotentialAttendantResponse>(`${this.configurationService.serverSettings.webApiServiceUrl}/FindPotentialAttendantByCodes`, { query, includeNonShareholders }).toPromise<IFindPotentialAttendantResponse>();
  }

  public async findVPotentialAttendantByPAID(paid: number) {
    return await this.http.get<vPotentialAttendant>(`${this.configurationService.serverSettings.webApiServiceUrl}/FindVPotentialAttendantByPAID?paid=${paid}`).toPromise<vPotentialAttendant>();
  }
  /*[HttpGet]
      public List<vPotentialAttendant> GetNotSharhoderDelegateByPAID(int paid)*/
  public async getNotSharhoderDelegateByPAID(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetNotSharhoderDelegateByPAID?paid=${paid}`).toPromise<any>();
  }


  public async insertAndGetvPotentialAttendant(attendant: PotentialAttendant) {
    return await this.http.post<vPotentialAttendant>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertAndGetvPotentialAttendant`, { newpa: attendant }).toPromise<vPotentialAttendant>();
  }
  public async savevPotentialAttendant(attendant: PotentialAttendant) {
    return await this.http.post<number>(`${this.configurationService.serverSettings.webApiServiceUrl}/SavevPotentialAttendant`, attendant).toPromise<number>();
  }

  public async insertOrUpdatevPotentialAttendant(attendant: vPotentialAttendant) {
    return await this.http.post<number>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertOrUpdatevPotentialAttendant`, attendant).toPromise<number>();
  }

  public async saveLROrDelegation(rootPA: vPotentialAttendant, lr: PotentialAttendant) {
    return await this.http.post<vPotentialAttendant>(`${this.configurationService.serverSettings.webApiServiceUrl}/AddLegalRepresenter`, { newLR: lr, referencePAID: rootPA.PAID }).toPromise<vPotentialAttendant>();
  }

  public async updatePotentialAttendantShareholderType(paid: number, stid: number) {
    return await this.http.post<void>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdatePotentialAttendantShareholderType`, { PAID: paid, STID: stid }).toPromise();
  }

  public async updatePotentialAttendantShareholderZone(paid: number, zone: string) {
    return await this.http.post<void>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdatePotentialAttendantShareholderZone`, { PAID: paid, Zone: zone }).toPromise();
  }

  public async getVipNoteStyles() {
    return await this.http.get<VipNoteStyle[]>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetVipNoteStyles`).toPromise();
  }
  public async LoadAgencies() {
    return await this.http.get<Agency[]>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadAgencies`).toPromise();
  }
  public async getSHTypes() {
    return await this.http.get<ShareholderType[]>(`${this.configurationService.serverSettings.webApiServiceUrl}/getSHTypes`).toPromise();
  }
  public async getMovementsLocked() {
    return await this.http.get<boolean>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetMovementsLocked`).toPromise();
  }
  /*[HttpGet]
        public bool GetCabinaLocked()*/
  public async getCabinaLocked() {
    return await this.http.get<boolean>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetCabinaLocked`).toPromise();
  }

  public async getIngressiDiProva() {
    return await this.http.get<boolean>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetIngressiDiProva`).toPromise();
  }
  public async getMsgIngressiAttivi() {
    return await this.http.get<boolean>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetMsgIngressiAttivi`).toPromise();
  }

  public async deleteAccountingFromBuffer(pa: vPotentialAttendant) {
    return await this.http.post<void>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteAccountingFromBuffer`, pa).toPromise();
  }
  public async loadAccountingDetailsByShareholder(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/loadAccountingDetailsByShareholder?pPAID_Shareholder=${paid}`).toPromise<any>();
  }
  public async loadAllAccountingDetailsByShareholder(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadAllAccountingDetailsByShareholder?pPAID_Shareholder=${paid}`).toPromise<any>();
  }
  public async loadAllPreAccountingDetailsByShareholder(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadAllPreAccountingDetailsByShareholder?pPAID_Shareholder=${paid}`).toPromise<any>();
  }

  public async changePreAccountDelegatorToPhysicalInBuffer(paid: number, paidShareholder: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChangePreAccountDelegatorToPhysicalInBuffer?pPAID=${paid}&pPAID_Shareholder=${paidShareholder}`).toPromise<any>();
  }
  public async loadDetailFromBuffer() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadDetailFromBuffer`).toPromise<any>();
  }
  public async loadAccountingFromBuffer() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadAccountingFromBuffer`).toPromise<any>();
  }
  public async getPAStatus(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPAStatus?paid=${paid}`).toPromise<any>();
  }

  public async insertDetailsPackageIntoBuffer(paid: number, preaccount: boolean, lastAccount: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertDetailsPackageIntoBuffer?pPaid=${paid}&preAccount=${preaccount}&lastAccount=${lastAccount}`).toPromise<any>();
  }
  public async loadLastAccountingConfiguration(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadLastAccountingConfiguration?pPAID=${paid}`).toPromise<any>();
  }
  public async loadRepresentedShareholdersDetails(paid: number, meetingValidity: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadRepresentedShareholdersDetails?pPAID=${paid}&meetingValidity=${meetingValidity}`).toPromise<any>();
  }
  public async addRepresentedShareholdersDetailsToBuffer(paid: number, meetingValidity: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AddRepresentedShareholdersDetailsToBuffer?pPAID=${paid}&meetingValidity=${meetingValidity}`).toPromise<any>();
  }
  public async checkIfShareholderHasSelectedOnlinePartecipation(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/CheckIfShareholderHasSelectedOnlinePartecipation?pPAID=${paid}`).toPromise<any>();
  }
  public async preparePAFromRegisteredPackageAsPhysical(paid: number, paid_shareholder: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/PreparePAFromRegisteredPackageAsPhysical?PAID=${paid}&PAID_Shareholder=${paid_shareholder}`).toPromise<any>();
  }
  //CheckAndAddLegalRelationship(int pLRPaid, int pPaid)
  public async checkAndAddLegalRelationship(pLRPaid: number, pPaid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/checkAndAddLegalRelationship?pLRPaid=${pLRPaid}&pPaid=${pPaid}`).toPromise<any>();
  }
  /*
   public class RegisterRequest
      {
          public vPotentialAttendant pPA { get; set; }
          public string pComment { get; set; }
      }
      [HttpPost]
      public IHttpActionResult Register([FromBody]RegisterRequest request)
   */
  public async register(pPAID: number, pComment: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/Register`, { pPAID: pPAID, pComment: pComment }).toPromise<any>();
  }
  /*
public class AddAccountingDetailRequest {
          public  AccountingDetail accountingDetail { get; set; }
          public bool bypassIsInside { get; set; }
      }
      [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
      public void AddAccountingDetail([FromBody]AddAccountingDetailRequest request)
*/
  public async addAccountingDetail(accountingDetail: AccountingDetail, bypassIsInside: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AddAccountingDetail`, { accountingDetail: accountingDetail, bypassIsInside: bypassIsInside }).toPromise<any>();
  }
  /*
public class AddPreregistrationDetailsToBufferRequest
      {
          public int pPAID { get; set; }
          public bool onlyPhysical { get; set; }
      }
      [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
      public IHttpActionResult AddPreregistrationDetailsToBuffer([FromBody] AddPreregistrationDetailsToBufferRequest request)
*/
  public async addPreregistrationDetailsToBuffer(pPaid: number, onlyPhysical: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AddPreregistrationDetailsToBuffer`, { pPaid: pPaid, onlyPhysical: onlyPhysical }).toPromise<any>();
  }
  /*[HttpGet]
      public IHttpActionResult AnyEventActive()
      {
          using (EventManager em = new EventManager(LoggedUser))
          {
              return Ok(em.AnyEventActive());
          }
      }*/
  public async anyEventActive() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AnyEventActive`).toPromise<any>();
  }
  /*[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
      public IHttpActionResult PackageEntranceWithRemoveDelegationFromRegisteredPackage([FromBody]PackageEntranceWithRemoveDelegationFromRegisteredPackageRequest request)
      {
          using (MovementManager mm = new MovementManager(LoggedUser))
          {
              mm.PackageEntranceWithRemoveDelegationFromRegisteredPackage(request.PAID, request.PAID_Shareholder, GetClientIp(), LoggedUser.PID);
              return Ok();
          }
      }*/
  public async packageEntranceWithRemoveDelegationFromRegisteredPackage(PAID: number, PAID_Shareholder: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/PackageEntranceWithRemoveDelegationFromRegisteredPackage`, { PAID: PAID, PAID_Shareholder: PAID_Shareholder }).toPromise<any>();
  }
  /*public class ConfirmRegistrationAndMoveInRequest {
          public vPotentialAttendant pPA { get; set; }
          public int pRegisteredBy { get; set; }
          public string pTagRFID { get; set; }
          public string pComment { get; set; }
          public bool DoNotPrint { get; set; }
          public List<Report> OptionalReports { get; set; }
      }
      [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
      public List<ConfirmRegistrationCheck> ConfirmRegistrationAndMoveIn(ConfirmRegistrationAndMoveInRequest request)*/
  public async confirmRegistrationAndMoveIn(pPA: vPotentialAttendant, pRegisteredBy: number, pTagRFID: string, pComment: string, DoNotPrint: boolean, OptionalReports: Report[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ConfirmRegistrationAndMoveIn`, { pPA: pPA, pRegisteredBy: pRegisteredBy, pTagRFID: pTagRFID, pComment: pComment, DoNotPrint: DoNotPrint, OptionalReports: OptionalReports }).toPromise<any>();
  }
  /*public class MoveInRequest {
          public vPotentialAttendant pPA { get; set; }
          public bool DoNotPrint { get; set; }
          public List<Report> OptionalReports { get; set; }
      }
      [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
      public void MoveIn([FromBody]MoveInRequest request)*/
  public async moveIn(pPA: vPotentialAttendant, DoNotPrint: boolean, OptionalReports: Report[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/MoveIn`, { pPA: pPA, DoNotPrint: DoNotPrint, OptionalReports: OptionalReports }).toPromise<any>();
  }
  /*public IHttpActionResult GetPAVoteCardDeliveryInformation(int pVCID, bool isCollecting, int pPAID_Shareholder, bool isEntrance, string barcodeIngresso)*/
  public async getPAVoteCardDeliveryInformation(pVCID: number, isCollecting: boolean, pPAID_Shareholder: number, isEntrance: boolean, barcodeIngresso: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPAVoteCardDeliveryInformation?pVCID=${pVCID}&isCollecting=${isCollecting}&pPAID_Shareholder=${pPAID_Shareholder}&isEntrance=${isEntrance}&barcodeIngresso=${barcodeIngresso}`).toPromise<any>();
  }

  /*public class DeliverVoteCardsRequest
      {
          public int pVCID { get; set; }
          public List<VoteCardDeliveryInformation> pacchetto { get; set; }
          public DateTime operationDateTime { get; set; }
          public bool isCollecting { get; set; }
          public bool fromDeliveryPage { get; set; }
      }
      public class DeliverVoteCardResponse
      {
          public int Delivered { get; set; }
          public string ErrorMessage { get; set; }
      }
      [HttpPost]
      public IHttpActionResult DeliverVoteCards(DeliverVoteCardsRequest request)*/
  public async deliverVoteCards(pVCID: number, pacchetto: VoteCardDeliveryInformation[], operationDateTime: Date, isCollecting: boolean, fromDeliveryPage: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeliverVoteCards`, { pVCID: pVCID, pacchetto: pacchetto, operationDateTime: operationDateTime, isCollecting: isCollecting, fromDeliveryPage: fromDeliveryPage }).toPromise<any>();
  }
  /*public class PrintPADeliveredCardReport {
          public bool isCollecting { get; set; }
          public int pVCID { get; set; }
          public int pPAID { get; set; }
          public DateTime operationDate { get; set; }
      }
      [HttpPost]
      public IHttpActionResult PrintPADeliveredCardReport([FromBody] PrintPADeliveredCardReport request)*/
  public async printPADeliveredCardReport(isCollecting: boolean, pVCID: number, pPAID: number, operationDate: Date) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/PrintPADeliveredCardReport`, { isCollecting: isCollecting, pVCID: pVCID, pPAID: pPAID, operationDate: operationDate }).toPromise<any>();
  }
  /*[HttpGet]
      public IHttpActionResult LoadVoteCards([FromUri]bool pOnlyWithPollEntities)*/
  public async loadVoteCards(pOnlyWithPollEntities: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadVoteCards?pOnlyWithPollEntities=${pOnlyWithPollEntities}`).toPromise<VoteCard[]>();
  }

  /* /// <summary>
        ///     Removes the corrisponding detail from buffer
        /// </summary>
        /// <param name="accountingDetail"> </param>
        /// <param name="pLoggedUser"> </param>
        [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
        public void DeleteDetailFromBuffer([FromBody]AccountingDetail accountingDetail)
        {
            using (MovementManager mm = new MovementManager(LoggedUser))
            {
                mm.DeleteDetailFromBuffer(accountingDetail, LoggedUser.HardwareID);
            }
        }*/
  public async deleteDetailFromBuffer(accountingDetail: AccountingDetail) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteDetailFromBuffer`, accountingDetail).toPromise<any>();
  }

  /*[HttpGet]
        public string LoadTagRFIDByPA([FromUri]int pPAID)
        {
            using (MovementManager mm = new MovementManager(LoggedUser))
            {
                return mm.LoadRFIDCodeByPA(pPAID);
            }
        }*/
  public async loadTagRFIDByPA(pPAID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadTagRFIDByPA?pPAID=${pPAID}`).toPromise<any>();
  }
  /* [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
        public void MoveOut([FromBody]vPotentialAttendant pPA)*/
  public async moveOut(pPA: vPotentialAttendant) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/MoveOut`, pPA).toPromise<any>();
  }

  public async ReEntryWithoutExit(pPA: vPotentialAttendant) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ReEntryWithoutExit`, pPA).toPromise<any>();
  }
  /*public class DeRegisterRequest {
            public vPotentialAttendant pPA { get; set; }
            public string pComment { get; set; }
        }
        [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
        public void DeRegister([FromBody] DeRegisterRequest request)*/
  public async deregister(pPA: vPotentialAttendant, pComment: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeRegister`, { pPA: pPA, pComment: pComment }).toPromise<any>();
  }
  /*[HttpGet]
        public PotentialAttendantNullifyStatus GetNullifyStatus(int pPAID, bool onlyLastMovement)*/
  public async getNullifyStatus(pPAID: number, onlyLastMovement: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetNullifyStatus?pPAID=${pPAID}&onlyLastMovement=${onlyLastMovement}`).toPromise<any>();
  }
  /* public class NullifyRequest {
            public vPotentialAttendant pPA { get; set; }
            public string pComment { get; set; }
            public int pOperatorID { get; set; }
            public bool deleteExtraResource { get; set; }
            public bool onlyLastMovement { get; set; }
        }
        [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement, CapabilityItems.CanNullify })]
        public void Nullify([FromBody]NullifyRequest request)*/
  public async nullify(pPA: vPotentialAttendant, pComment: string, pOperatorID: number, deleteExtraResource: boolean, onlyLastMovement: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/Nullify`, { pPA: pPA, pComment: pComment, pOperatorID: pOperatorID, deleteExtraResource: deleteExtraResource, onlyLastMovement: onlyLastMovement }).toPromise<any>();
  }
  /*[HttpGet]
        public List<ReportItem> GetHistoricMovementsWithDetailsOfAnAttendant(int pPAID, DateTime? pTimeStamp, int pMeetingValidity)*/
  public async getHistoricMovementsWithDetailsOfAnAttendant(pPAID: number, pTimeStamp: Date, pMeetingValidity: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetHistoricMovementsWithDetailsOfAnAttendant?pPAID=${pPAID}&pTimeStamp=${pTimeStamp}&pMeetingValidity=${pMeetingValidity}`).toPromise<any>();
  }
  /*[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
        public void ChangeDelegatorInBuffer([FromBody]vPotentialAttendant pPA)*/
  public async changeDelegatorInBuffer(pPA: vPotentialAttendant) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChangeDelegatorInBuffer`, pPA).toPromise<any>();
  }

  //#region PREACCOUNTING
  //  public class LoadPreaccountingDetailsByShareholderRequest { 
  //            public int pPAID_Shareholder { get; set; }
  //            public List < AccountingDetailStatus > status { get; set; }
  //}
  //public class LoadPreaccountingDetailsByShareholderResponse {
  //  public List<AccountingDetail> Details { get; set; }
  //            public List < AccountingDetailStatus > DetailsStatus{ get; set; }
  //        }
  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public LoadPreaccountingDetailsByShareholderResponse LoadPreaccountingDetailsByShareholder(LoadPreaccountingDetailsByShareholderRequest request)

  public async loadPreaccountingDetailsByShareholder(pPAID_Shareholder: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPreaccountingDetailsByShareholder`, pPAID_Shareholder).toPromise<any>();
  }

  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public bool InsertDetailsPackageIntoPreaccountBuffer([FromBody]int pPAID)
  public async insertDetailsPackageIntoPreaccountBuffer(pPAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertDetailsPackageIntoPreaccountBuffer`, pPAID).toPromise<any>();
  }

  //[HttpGet, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public List < AccountingDetail > LoadRepresentedShareholdersDetailsForPreaccounting(int pPAID, int meetingValidity)
  public async loadRepresentedShareholdersDetailsForPreaccounting(pPAID: number, pMeetingValidity: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadRepresentedShareholdersDetailsForPreaccounting?pPAID=${pPAID}&pMeetingValidity=${pMeetingValidity}`).toPromise<any>();
  }

  //public class RegisterPreaccountRequest {
  //  public vPotentialAttendant pPA { get; set; }
  //            public string pComment { get; set; }
  //        }
  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public void RegisterPreaccount([FromBody]RegisterPreaccountRequest request)
  public async registerPreaccount(pPAID: number, pComment: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RegisterPreaccount`, { pPAID: pPAID, pComment: pComment }).toPromise<any>();
  }

  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public void AddPreaccountingDetail([FromBody]AccountingDetail accountingDetail)
  //{
  public async addPreaccountingDetail(accountingDetail: AccountingDetail) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AddPreaccountingDetail`, accountingDetail).toPromise<any>();
  }
  //public class PreAccountingRequest {
  //  public vPotentialAttendant pPA { get; set; }
  //            public int pRegisteredBy { get; set; }
  //            public string pComment { get; set; }
  //        }
  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public List < ConfirmRegistrationCheck > PreAccounting([FromBody]PreAccountingRequest request)
  public async preAccounting(pPA: vPotentialAttendant, pRegisteredBy: number, pComment: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/PreAccounting`, { pPA: pPA, pRegisteredBy: pRegisteredBy, pComment: pComment }).toPromise<any>();
  }

  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public List < ConfirmRegistrationCheck > ConfirmPreAccounting([FromBody]PreAccountingRequest request)
  public async confirmPreAccounting(pPA: vPotentialAttendant, pRegisteredBy: number, pComment: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ConfirmPreAccounting`, { pPA: pPA, pRegisteredBy: pRegisteredBy, pComment: pComment }).toPromise<any>();
  }

  //[HttpGet, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public vPotentialAttendant LoadPreaccountingFromBuffer()
  public async loadPreaccountingFromBuffer() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPreaccountingFromBuffer`).toPromise<any>();
  }

  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public void DeletePreaccountingFromBuffer([FromBody]vPotentialAttendant pPA)
  public async deletePreaccountingFromBuffer(pPA: vPotentialAttendant) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletePreaccountingFromBuffer`, pPA).toPromise<any>();
  }

  //[HttpGet, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public List < AccountingDetail > LoadPreaccountingDetailFromBuffer()
  public async loadPreaccountingDetailFromBuffer() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPreaccountingDetailFromBuffer`).toPromise<any>();
  }
  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public void DeletePreaccountingDetailFromBuffer([FromBody]AccountingDetail accountingDetail)
  public async deletePreaccountingDetailFromBuffer(accountingDetail: AccountingDetail) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletePreaccountingDetailFromBuffer`, accountingDetail).toPromise<any>();
  }
  //public class RemoveDelegationFromPreaccountingPackageRequest {
  //  public int PAID { get; set; }
  //            public int PAID_Shareholder { get; set; }
  //        }
  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public void RemoveDelegationFromPreaccountingPackage([FromBody]RemoveDelegationFromPreaccountingPackageRequest request)
  public async removeDelegationFromPreaccountingPackage(PAID: number, PAID_Shareholder: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RemoveDelegationFromPreaccountingPackage`, { PAID: PAID, PAID_Shareholder: PAID_Shareholder }).toPromise<any>();
  }

  //[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
  //        public void ChangeDelegatorInPreaccountingBuffer([FromBody]vPotentialAttendant pPA)
  public async changeDelegatorInPreaccountingBuffer(pPA: vPotentialAttendant) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChangeDelegatorInPreaccountingBuffer`, pPA).toPromise<any>();
  }
  /*public class AddRepresentedShareholdersDetailsToPreregistrationBufferRequest {
            public int pPAID { get; set; }
            public int meetingValidity { get; set; }
        }
        [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
        public int AddRepresentedShareholdersDetailsToPreregistrationBuffer([FromBody]AddRepresentedShareholdersDetailsToPreregistrationBufferRequest request)
        {*/
  public async addRepresentedShareholdersDetailsToPreregistrationBuffer(pPAID: number, meetingValidity: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AddRepresentedShareholdersDetailsToPreregistrationBuffer`, { pPAID: pPAID, meetingValidity: meetingValidity }).toPromise<any>();
  }
  /*public class DeRegisterFromPreRegistrationRequest
        {
            public vPotentialAttendant pPA { get; set; }
            public string pComment { get; set; }
        }
        [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoPreregistration })]
        public void DeRegisterFromPreRegistration(DeRegisterFromPreRegistrationRequest request)*/
  public async deRegisterFromPreRegistration(pPA: vPotentialAttendant, pComment: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeRegisterFromPreRegistration`, { pPA: pPA, pComment: pComment }).toPromise<any>();
  }

  //#endregion

  /*[HttpGet]
        public bool IsReprintEnabled()*/
  public async isReprintEnabled() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/IsReprintEnabled`).toPromise<any>();
  }
  /*[HttpGet]
        public bool ReprintBarcodeIngressoUpdate(int paid)*/
  public async reprintBarcodeIngressoUpdate(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ReprintBarcodeIngressoUpdate?paid=${paid}`).toPromise<any>();
  }
  /*[HttpGet]
        public void Reprint([FromUri]int PAID)*/
  public async reprint(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/Reprint?PAID=${paid}`).toPromise<any>();
  }
  /*[HttpGet]
        public void RegisterReprint([FromUri]int PAID)*/
  public async registerReprint(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RegisterReprint?PAID=${paid}`).toPromise<any>();
  }
  /*[HttpGet]
        public void PreAccountReprint([FromUri]int PAID)*/
  public async preAccountReprint(paid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/PreAccountReprint?PAID=${paid}`).toPromise<any>();
  }
  /*public class RemoveDelegationFromRegisteredPackageRequest {
            public int PAID { get; set; }
            public int PAID_Shareholder { get; set; }
        }
        [HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanDoMovement })]
        public void RemoveDelegationFromRegisteredPackage([FromBody]RemoveDelegationFromRegisteredPackageRequest request)*/
  public async removeDelegationFromRegisteredPackage(PAID: number, PAID_Shareholder: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RemoveDelegationFromRegisteredPackage`, { PAID: PAID, PAID_Shareholder: PAID_Shareholder }).toPromise<any>();
  }

  //#region EVENTI IN REGIA

  public async getEventiRegia() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetEventiRegia`).toPromise<any>();
  }

  public async resetEventoAssemblea(pEAID: number, includeOnline:boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ResetEventoAssemblea?pEAID=${pEAID}&includeOnline=${includeOnline}`).toPromise<any>();
  }
  public async resetEventSimple(pEAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ResetEventSimple`, { EAID: pEAID }).toPromise<any>();
  }

  public async apriEventoAssemblea(pEAID: number, scrutinio: boolean, majorityVote: boolean, includeOnline:boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ApriEventoAssemblea`, { pEAID: pEAID, scrutinio: scrutinio, majorityVote: majorityVote, includeOnline: includeOnline }).toPromise<any>();
  }
  public async openEventSimple(pEAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/OpenEventSimple`, { EAID: pEAID}).toPromise<any>();
  }

  public async chiudiEventoAssemblea(pEAID: number, scrutinio: boolean, majorityVote: boolean, includeOnline: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChiudiEventoAssemblea`, { pEAID: pEAID, scrutinio: scrutinio, majorityVote: majorityVote, includeOnline: includeOnline }).toPromise<any>();
  }

  public async closeEventSimple(pEAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/CloseEventSimple`, { EAID: pEAID }).toPromise<any>();
  }

  public async attivaScrutinioEventoAssemblea(pEAID: number, majorityVote: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AttivaScrutinioEventoAssemblea`, { pEAID: pEAID, majorityVote: majorityVote }).toPromise<any>();
  }

  public async disattivaScrutinioEventoAssemblea(pEAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DisattivaScrutinioEventoAssemblea`, { pEAID: pEAID }).toPromise<any>();
  }

  public async insertEventoAssemblea(pPEID: number, meetingValidity: number, descr: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertEventoAssemblea`, { pPEID: pPEID, meetingValidity: meetingValidity, descr: descr }).toPromise<any>();
  }

  // comandi regia

  public async activateIngressiDiProva() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ActivateIngressiDiProva`).toPromise<any>();
  }

  public async deactivateIngressiDiProva() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeactivateIngressiDiProva`).toPromise<any>();
  }

  public async activateMsgIngressiAttivi() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ActivateMsgIngressiAttivi`).toPromise<any>();
  }

  public async deactivateMsgIngressiAttivi() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeactivateMsgIngressiAttivi`).toPromise<any>();
  }

  /*[HttpGet]
        public void LockCabina()*/
  public async lockCabina() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LockCabina`).toPromise<any>();
  }

  public async unLockCabina() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UnLockCabina`).toPromise<any>();
  }

  public async lockMovements() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LockMovements`).toPromise<any>();
  }

  public async unLockMovements() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UnLockMovements`).toPromise<any>();
  }

  // PREREG
  public async apriPreregistrazioni(dt: Date) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ApriPreregistrazioni`, { dt: dt }).toPromise<any>();
  }

  public async chiudiPreRegistrazioni(dt: Date) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChiudiPreRegistrazioni`, { dt: dt }).toPromise<any>();
  }

  public async undoApriPreregistrazioni() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UndoApriPreregistrazioni`, {}).toPromise<any>();
  }

  public async undoChiudiPreRegistrazioni() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UndoChiudiPreRegistrazioni`, {}).toPromise<any>();
  }



  //#endregion

  public async clearDB(pFilter: any) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ClearDB`, pFilter).toPromise<any>();
  }
  /*[HttpGet]
        public string LoadDefaultProjectionPage()*/
  public async loadDefaultProjectionPage() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadDefaultProjectionPage`).toPromise<any>();
  }
  /*public class GetProjectionRealTimeDataRequest {
            public DateTime? RequestDate { get; set; }
            public List<string> Placeholders { get; set; }
        }
        [HttpPost]
        public List<PlaceholderType> GetProjectionRealTimeData([FromBody]GetProjectionRealTimeDataRequest request)*/
  public async getProjectionRealTimeData(RequestDate: Date, Placeholders: string[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetProjectionRealTimeData`, { RequestDate: RequestDate, Placeholders: Placeholders }).toPromise<any>();
  }

  public async getProjectionPollResultData(EAID: number, PEID: number, Placeholders: string[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetProjectionPollResultData`, { EAID: EAID, PEID: PEID, Placeholders: Placeholders }).toPromise<any>();
  }

  public async getProjectionOnlineSpeechData(STID: number, Placeholders: string[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetProjectionOnlineSpeechData`, { STID: STID, Placeholders: Placeholders }).toPromise<any>();
  }
  /*public List<SysConfig> GetSysConfigTableForEditing(int accessLevel)
        {
            using (ApplicationManager appManager = new ApplicationManager(LoggedUser))
            {
                return appManager.GetSysConfigTable(accessLevel, "it-IT");
            }
        }*/
  public async getSysConfigTableForEditing(accessLevel: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetSysConfigTableForEditing?accessLevel=${accessLevel}`).toPromise<any>();
  }
  public async getSysConfigProjection() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetSysConfigProjection`).toPromise<any>();
  }
  /*public int CheckIfSysConfigModificationIsAllowed(int configID)*/
  public async checkIfSysConfigModificationIsAllowed(configID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/CheckIfSysConfigModificationIsAllowed?configID=${configID}`).toPromise<any>();
  }
  /*[HttpPost]
        public bool SetEventiAssembleaToGenericMV()*/
  public async setEventiAssembleaToGenericMV() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SetEventiAssembleaToGenericMV`, {}).toPromise<any>();
  }
  /*[HttpPost]
        public bool SetDelegationNumberForOrdinaryMeeting()*/
  public async setDelegationNumberForOrdinaryMeeting() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SetDelegationNumberForOrdinaryMeeting`, {}).toPromise<any>();
  }
  /*[HttpPost]
        public bool ShareholderTypeResetMaxSharesBC()*/
  public async shareholderTypeResetMaxSharesBC() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ShareholderTypeResetMaxSharesBC`, {}).toPromise<any>();
  }
  /*[HttpPost]
        /// <summary>
        ///     Save a list of SysConfig item Add a new record if SysConfig does not exist otherwise update current
        /// </summary>
        /// <param name="sysConfigList"> </param>
        public bool SaveSysConfigList(List<SysConfig> sysConfigList)*/
  public async saveSysConfigList(sysConfigList: SysConfig[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveSysConfigList`, sysConfigList).toPromise<any>();
  }

  public async saveSysConfigProjection(sysConfigList: SysConfig[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveSysConfigProjection`, sysConfigList).toPromise<any>();
  }

  /*[HttpGet]
        public List<String> GetListTagConfig()*/
  public async getListTagConfig() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetListTagConfig`).toPromise<any>();
  }
  /*[HttpGet, RequireCapabilities(new int[] { CapabilityItems.CanAccessConfigurationPage, CapabilityItems.CanManagePrincipalAndRoles })]
          public List<Principal> GetPrincipalList()*/
  public async getPrincipalList() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPrincipalList`).toPromise<any>();
  }

  public async getOperatoriCabinaList() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetOperatoriCabinaList`).toPromise<Principal[]>();
  }
  /*[HttpGet, RequireCapabilities(new int[] { CapabilityItems.CanAccessConfigurationPage })]
        public Principal FindPrincipalByUsername(string userName)*/
  public async findPrincipalByUsername(userName: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/FindPrincipalByUsername?userName=${userName}`).toPromise<any>();
  }
  /*[HttpGet, RequireCapabilities(new int[] { CapabilityItems.CanAccessConfigurationPage })]
        public Principal FindPrincipal(int pid)*/
  public async findPrincipal(pid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/FindPrincipal?pid=${pid}`).toPromise<any>();
  }
  /*[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanAccessConfigurationPage, CapabilityItems.CanManagePrincipalAndRoles })]
        public void UpdatePrincipal(Principal obj)*/
  public async updatePrincipal(principal: Principal) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdatePrincipal`, principal).toPromise<any>();
  }
  /*[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanAccessConfigurationPage, CapabilityItems.CanManagePrincipalAndRoles })]
        public void AddPrincipal(Principal obj)*/
  public async addPrincipal(principal: Principal) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AddPrincipal`, principal).toPromise<any>();
  }
  /*
[HttpPost, RequireCapabilities(new int[] { CapabilityItems.CanAccessConfigurationPage, CapabilityItems.CanManagePrincipalAndRoles })]
        public void DeletePrincipal(Principal obj, bool OnlyDisable)
*/
  public async deletePrincipal(principal: Principal, onlyDisable: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletePrincipal`, { Principal: principal, OnlyDisable: onlyDisable }).toPromise<any>();
  }
  /*[HttpGet]
        public List<Role> LoadRoles()*/
  public async loadRoles() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadRoles`).toPromise<any>();
  }
  /*GetReportPDF(reportId)*/
  public async GetReportPDF(reportId: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetReportPDF?reportId=${reportId}`).toPromise<any>();
  }

  public async enqueuePrintJobAsync(request: ReportRequest) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EnqueuePrintJobAsync`, { Request: request, CustomProperties: request.Parameters.CustomPropertiesList }).toPromise<any>();
  }

  public async enqueuePrintedJobAsync(request: ReportRequest) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EnqueuePrintedJobAsync`, { Request: request, CustomProperties: request.Parameters.CustomPropertiesList }).toPromise<any>();
  }

  /*public List<vEventoAssemblea> GetEvents4ReportPresences()*/
  public async getEvents4ReportPresences() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetEvents4ReportPresences`).toPromise<any>();
  }
  /*[HttpGet]
        public HttpResponseMessage GetExportedReportResult(string reportId)*/
  public async getExportedReportResult(reportId: string) {
    let response = this.http.get(`${this.configurationService.serverSettings.webApiServiceUrl}/GetExportedReportResult?reportId=${reportId}`, { responseType: "blob", observe: 'response' })
      .subscribe(data => {
        const contentDisposition = data.headers.get('content-disposition');
        const filename = this.getFilenameFromContentDisposition(contentDisposition);
        const blob = data.body;
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = filename;
        anchor.href = url;
        anchor.click();
      });

  }
  private getFilenameFromContentDisposition(contentDisposition: string): string {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  /*[HttpPost]
        public bool SaveEventsReportTitles(List<vEventoAssemblea> eventi)*/
  public async saveEventsReportTitles(eventi: vEventoAssemblea[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveEventsReportTitles`, eventi).toPromise<any>();
  }
  /*[HttpPost]
        public async void EnqueuePrintSummaryAsync(EnqueuePrintSummaryJobAsyncRequest summaryRequest)*/
  public async enqueuePrintSummaryAsync(requests: ReportRequest[]) {
    let body: any[] = [];
    requests.forEach(request => {
      body.push({
        Request: request,
        CustomProperties: request.Parameters.CustomPropertiesList
      });
    });
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EnqueuePrintSummaryAsync`, body).toPromise<any>();
  }
  /*[HttpPost]
        public bool UpdateEventoAssemblea(EventoAssemblea evento)*/
  public async updateEventoAssemblea(evento: vEventoAssemblea) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdateEventoAssemblea`, evento).toPromise<any>();
  }
  /*[HttpPost]
        public bool DeleteEventoAssemblea(int pEAID)
   */
  public async deleteEventoAssemblea(pEAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteEventoAssemblea`, pEAID).toPromise<any>();
  }
  /*public List<PollDetail> LoadPollDetailsByPEID(int pPEID)*/
  public async LoadPollDetailsByPEID(pPEID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPollDetailsByPEID?pPEID=${pPEID}`).toPromise<PollDetail[]>();
  }

  public async LoadPollEntitiesStored() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPollEntitiesStored`).toPromise<PollEntity[]>();
  }

  /*[HttpGet]
        public List<Role> LoadRoles()*/
  public async LoadPollEntities() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPollEntities`).toPromise<PollEntity[]>();
  }

  public async GetPollEntityByPEID(pPEID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPollEntityByPEID?pPEID=${pPEID}`).toPromise<PollEntity>();
  }

  public async GetPollEntitiesFromSTID(STID: number, excluded: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPollEntitiesFromSTID?STID=${STID}&excluded=${excluded}`).toPromise<PollEntity2PeidStid[]>();
  }

  public async GetPollEntitiesFromSCID(SCID: number, excluded: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPollEntitiesFromSCID?SCID=${SCID}&excluded=${excluded}`).toPromise<PollEntity2PeidStid[]>();
  }

  public async UpdateTablePeid2Stid(tablePS: PollEntity2PeidStid[], stid: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdateTablePeid2Stid`, { tablePS: tablePS, stid: stid }).toPromise<any>();
  }

  public async UpdateTablePeid2Category(tablePS: PollEntity2PeidStid[], scid: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdateTablePeid2Category`, { tablePS: tablePS, scid: scid }).toPromise<any>();
  }

  public async CheckMajorityVoted(vcid: number, peid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/CheckMajorityVoted?vcid=${isNullOrUndefined(vcid) ? '' : vcid}&peid=${isNullOrUndefined(peid) ? '' : peid}`).toPromise<number>();
  }

  public async registerMajorityVoteOnEntity(peid: number, pdids: number[], majorityOverwriteVotes: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RegisterMajorityVoteOnEntity`, { peid: peid, pdids: pdids, majorityOverwriteVotes: majorityOverwriteVotes }).toPromise<any>();
  }
  //public void RegisterMajorityVote(List<vPollDetail> pPollDetailList, bool majorityOverwriteVotes)

  public async RegisterMajorityVote(pPollDetailList: vPollDetail[], majorityOverwriteVotes: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RegisterMajorityVote`, { pPollDetailList: pPollDetailList, majorityOverwriteVotes: majorityOverwriteVotes }).toPromise<any>();
  }

  public async registerParticolarVoteOnEntity(paid: number, pollVotes: PollVote[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RegisterParticolarVoteOnEntity`, { paid: paid, pollVotes: pollVotes }).toPromise<any>();
  }

  public async attachNewPollEvent(vEA: vEventoAssemblea, copyPEID: number, IncludeNonVoters: boolean, IncludePrevotes: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AttachNewPollEvent`, { vEA: vEA, copyPEID: copyPEID, IncludeNonVoters: IncludeNonVoters, IncludePrevotes: IncludePrevotes }).toPromise<any>();
  }

  public async loadPollDetailsVotedByCode(code: string, VCID: number, PEID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPollDetailsVotedByCode`, { code: code, VCID: VCID, PEID: PEID }).toPromise<any>();
  }

  public async loadPollDetailsVotedByShareholder(paidShareholder: number, VCID: number, PEID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPollDetailsVotedByShareholder`, { paidShareholder: paidShareholder, VCID: VCID, PEID: PEID }).toPromise<PollVote[]>();
  }

  public async LoadPreVotesByShareholder(paidShareholder: number, PEID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPreVotesByShareholder`, { paidShareholder: paidShareholder, PEID: PEID }).toPromise<PollVote[]>();
  }

  public async onlineVoteOpen(vEA: vEventoAssemblea) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/OnlineVoteOpen`, vEA).toPromise<any>();
  }

  public async onlineVoteClose(vEA: vEventoAssemblea) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/OnlineVoteClose`, vEA).toPromise<any>();
  }

  public async registerOnlineVote(peid: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/MergeOnlineVotesWithLocal`, peid).toPromise<any>();
  }

  public async deleteOnlineVote(peid: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteOnlineVotesFromLocal`, peid).toPromise<any>();
  }

  public async editOnlineTimeOpenVote(vEA: vEventoAssemblea) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ModificaOraAperturaOnlineEventoAssemblea`, vEA).toPromise<any>();
  }

  public async editOnlineTimeCloseVote(vEA: vEventoAssemblea) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ModificaOraChiusuraOnlineEventoAssemblea`, vEA).toPromise<any>();
  }

  public async editTimeOpenVote(vEA: vEventoAssemblea) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SetTimestampAperturaEventoAssemblea`, vEA).toPromise<any>();
  }

  public async editTimeCloseVote(vEA: vEventoAssemblea) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SetTimestampChiusuraEventoAssemblea`, vEA).toPromise<any>();
  }

  public async savePollEntitiesAndDetails(pollEntities: PollEntity[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SavePollEntitiesAndDetails`, pollEntities).toPromise<any>();
  }

  public async savePollEntity(pollEntity: PollEntity) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SavePollEntity`, pollEntity).toPromise<any>();
  }

  public async savePollDetail(pollDetail: PollDetail) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SavePollDetail`, pollDetail).toPromise<any>();
  }

  public async deletePollEntities(PEIDs: number[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletePollEntities`, PEIDs).toPromise<any>();
  }

  public async deletePollDetails(PDIDs: number[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletePollDetails`, PDIDs).toPromise<any>();
  }

  public async insertPollStandardVote(standardType: number, title: string, descr: string, pollType: number, evaluationType: number, voteType: number, createEvent: boolean, multipleItemMaxNum: number, votePresentationOrder: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertPollStandardVote`, { standardType: standardType, title: title, descr: descr, pollType: pollType, evaluationType: evaluationType, voteType: voteType, createEvent: createEvent, multipleItemMaxNum: multipleItemMaxNum, votePresentationOrder: votePresentationOrder }).toPromise<any>();
  }

  public async updatePollEntity(key: any, values: any) {
    /*In fase di update, in values, mi vengono restituiti solo i campi modificati. A questi campi gli aggiungo il key e lo mando al servizio di update*/
    values.key = key;
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdatePollEntity`, values).toPromise<any>();
  }

  public async updateShareholderType(key: any, values: any) {
    /*In fase di update, in values, mi vengono restituiti solo i campi modificati. A questi campi gli aggiungo il key e lo mando al servizio di update*/
    values.key = key;
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdateShareholderType`, values).toPromise<any>();
  }

  public async insertShareholderType(shareHolderType: ShareholderType) {
    console.log(shareHolderType);
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertShareholderType`, shareHolderType).toPromise<any>();
  }

  public async deleteShareholderType(STID: number) {
    let shareHolderType: ShareholderType = new ShareholderType();
    shareHolderType.STID = STID;
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteShareholderType`, shareHolderType).toPromise<any>();
  }


  public async importShareholderList(potentialAttendant: vPotentialAttendant[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ImportShareholderList`, potentialAttendant).toPromise<any>();
  }

  public async importLegalRepresentativeList(potentialAttendant: vPotentialAttendant[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ImportLegalRepresentativeList`, potentialAttendant).toPromise<ImportResults>();
  }

  public async getNewShareholderName() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetNewShareholderName`).toPromise<string>();
  }

  public async getNewShareholderID() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetNewShareholderID`).toPromise<string>();
  }

  public async getvShareholders() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetvShareholders`).toPromise<any>();
  }

  public async insertOrUpdatevShareholders(shareholder: vShareholders) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertOrUpdatevShareholders`, shareholder).toPromise<vShareholders>();
  }

  public async deletevShareholders(shareholder: vShareholders) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletevShareholders`, shareholder).toPromise<void>();
  }

  public async importDelegators(req: ImportDelegatorsRequest[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ImportDelegators`, req).toPromise<DelegatorsToDelegate[]>();
  }


  public async getLegalRepresentatives() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetLegalRepresentatives`).toPromise<any>();
  }


  public async getRepresentedShareholder() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetRepresentedShareholder`).toPromise<any>();
  }

  public async deletevLegalRepresent(represent: vLegalRepresent) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletevLegalRepresent`, represent).toPromise<any>();
  }

  public async insertOrUpdatevLegalRepresent(represent: vLegalRepresent) {
    return await this.http.post<vLegalRepresent>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertOrUpdatevLegalRepresent`, represent).toPromise<vLegalRepresent>();
  }


  public async findPotentialAttendantByCSID(CSID: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/FindPotentialAttendantByCSID`, { pCSID: CSID }).toPromise<any>();
  }


  public async LoadProjectionGroupPages(pPGID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadProjectionGroupPages?pPGID=${pPGID}`).toPromise<ProjectionPage[]>();
  }

  public async LoadProjectionGroupPagesNoContent(pPGID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadProjectionGroupPagesNoContent?pPGID=${isNullOrUndefined(pPGID) ? '' : pPGID}`).toPromise<ProjectionPage[]>();
  }


  public async LoadProjectionPages(onlyEnabled: boolean, action: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadProjectionPages?onlyEnabled=${onlyEnabled}&action=${action}`).toPromise<ProjectionPage[]>();
  }

  public async saveProjectionPage(pPPage: ProjectionPage, pB64: string = null) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveProjectionPage`, { pPPage: pPPage, pB64: pB64 }).toPromise<number>();
  }

  public async duplicateProjectionPage(pPPID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DuplicateProjectionPage`, pPPID).toPromise<boolean>();
  }

  public async deleteProjectionPage(pPPID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteProjectionPage`, pPPID).toPromise<boolean>();
  }

  public async LoadProjectionImages(includeContent: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadProjectionImages?includeContent=${includeContent}`).toPromise<ProjectionImageContent[]>();
  }


  public async saveProjectionGroup(pPGroup: ProjectionGroup) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveProjectionGroup`, pPGroup).toPromise<number>();
  }

  public async duplicateProjectionGroup(pPGID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DuplicateProjectionGroup`, pPGID).toPromise<boolean>();
  }

  public async deleteProjectionGroup(pPGID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteProjectionGroup`, pPGID).toPromise<boolean>();
  }


  public async LoadProjectionGroups(groupType: number, onlyEnabled: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadProjectionGroups?groupType=${groupType}&onlyEnabled=${onlyEnabled}`).toPromise<ProjectionGroup[]>();
  }

  public async LoadGroupPages(pPGID: number, onlyEnabled: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadGroupPages?pPGID=${pPGID}&onlyEnabled=${onlyEnabled}`).toPromise<ProjectionPageToGroup[]>();
  }

  public async SaveProjectionPageToGroup(pPPageToGroup: ProjectionPageToGroup) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveProjectionPageToGroup`, pPPageToGroup).toPromise<number>();
  }

  public async DeleteProjectionPageToGroup(pPPageToGroup: ProjectionPageToGroup) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteProjectionPageToGroup`, pPPageToGroup).toPromise<number>();
  }

  public async GetSpeechTopics() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetSpeechTopics`).toPromise<SpeechTopic[]>();
  }

  public async EditSpeechTopic(key: any, values: any) {
    /*In fase di update, in values, mi vengono restituiti solo i campi modificati. A questi campi gli aggiungo il key e lo mando al servizio di update*/
    values.key = key;
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EditSpeechTopic`, values).toPromise<any>();
  }

  public async DeleteSpeechTopic(key: any) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteSpeechTopic`, key).toPromise<any>();
  }

  public async loadCurrentProjectionPage() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadCurrentProjectionPage`).toPromise<CurrentProjectionPageResponse>();
  }

  public async loadProjectionPageByPPID(pPPID: number, pPEID: number, pSTID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadProjectionPageByPPID?pPEID=${pPEID}&pSTID=${pSTID}`, pPPID ).toPromise<CurrentProjectionPageResponse>();
  }

  public async loadProjectionPageRtfContentByPPID(pPPID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadProjectionPageRtfContentByPPID`, pPPID).toPromise<string>();
  }

  public async getResetSummary() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetResetSummary`).toPromise<ResetSummary>();
  }

  public async deleteUnusedBeforeImport() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteUnusedBeforeImport`).toPromise<any>();
  }

  public async deleteRole(role: Role) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteRole`, role).toPromise<any>();
  }

  public async insertRole(role: Role) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertRole`, role).toPromise<any>();
  }

  public async LoadAllCapabilities() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadAllCapabilities`).toPromise<any>();
  }


  public async LoadCapabilitiesByRid(pRID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadCapabilitiesByRid?pRID=${pRID}`,).toPromise<any>();
  }

  public async UpdateRole(role: Role) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdateRole`, role).toPromise<any>();
  }

  public async UpdateRoleCapabilities(roleCapabilities: DictionaryCapability[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdateRoleCapabilities`, roleCapabilities).toPromise<any>();
  }

  public async GetRealTimePollsList() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetRealTimePollsList`,).toPromise<RealTimePoll>();
  }



  public async GetRealTimePolls(pPEID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetRealTimePolls?pPEID=${pPEID}`,).toPromise<any>();
  }

  public async GetDBConnected() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetDBConnected`,).toPromise<SoftwareInfo>();
  }


  public async InsertStandardPollDetail(parentPEID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertStandardPollDetail`, parentPEID).toPromise<any>();
  }

  public async CopySelectedVote(pPEID: number, includePrevotes: boolean, includeNonVoters: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/CopySelectedVote?includePrevotes=${includePrevotes}&includeNonVoters=${includeNonVoters}`, pPEID).toPromise<any>();
  }


  public async InsertVoteCard(pVoteCard: VoteCard) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertVoteCard`, pVoteCard).toPromise<VoteCard>();
  }


  public async GetPreVotesByPEID(peid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPreVotesByPEID?peid=${peid}`,).toPromise<PreVote[]>();
  }

  public async GetTagConfigByPEID(peid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetTagConfigByPEID?peid=${peid}`,).toPromise<PollEntityTagConfig[]>();
  }


  public async CheckPreVotes(peid: number, preVotes: PreVote[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/CheckPreVotes?peid=${peid}`, preVotes).toPromise<PreVote[]>();
  }


  public async DeletePreVotes(peid: number, preVotes: PreVote[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeletePreVotes?peid=${peid}`, preVotes).toPromise<PreVote[]>();
  }

  public async ManageTagConfiguration(action: string, peid: number, tags: PollEntityTagConfig[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ManageTagConfiguration?action=${action}&peid=${peid}`, tags).toPromise<PollEntityTagConfig[]>();
  }

  public async SaveShareholderPreVotes(peid: number, csid:string, preVotes: PreVote[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveShareholderPreVotes?peid=${peid}&csid=${csid}`, preVotes).toPromise<PreVote[]>();
  }


  public async SavePreVotes(peid: number, preVotes: PreVote[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SavePreVotes?peid=${peid}`, preVotes).toPromise<PreVote[]>();
  }


  public async GetCollectedVotesStatus(peid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetCollectedVotesStatus?pPEID=${peid}`).toPromise<CollectedVotesStatus>();
  }

  public async GetAllCollectedVotesStatus() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetAllCollectedVotesStatus`).toPromise<CollectedVotesStatus[]>();
  }

  public async GetAllPollVoteStats() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetAllPollVoteStats`).toPromise<PollVoteStats[]>();
  }

  public async GetPollVoteStats(peid:number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPollVoteStats?pPEID=${peid}`).toPromise<PollVoteStats>();
  }


  public async LoadActualPollDetailsByCode(code: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadActualPollDetailsByCode?Code=${code}`).toPromise<vPollDetail[]>();
  }

  public async LoadAccountingDetailsByCode(code: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadAccountingDetailsByCode?Code=${code}`).toPromise<AccountingDetail[]>();
  }



  public async LoadVotesInBufferByPEID(pPEID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadVotesInBufferByPEID?pPEID=${pPEID}`).toPromise<vActualVoteBuffer[]>();
  }

  public async GetCurrentSmartphoneOpenedPEID() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetCurrentSmartphoneOpenedPEID`).toPromise<GetCurrentSmartphoneOpenedPEIDResponse>();
  }

  public async ConfirmAllValidVotesInBuffer(pPEID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ConfirmAllValidVotesInBuffer`, pPEID).toPromise<boolean>();
  }

  public async AssociateOfflineVotesToPDID(pPEID: number, pPDID: number, pAVIDs: number[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/AssociateOfflineVotesToPDID?pPEID=${pPEID}&pPDID=${pPDID}`, pAVIDs).toPromise<boolean>();
  }

  public async EVOT_DeleteActualVoteBufferItems(AVIDs: number[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EVOT_DeleteActualVoteBufferItems`, AVIDs).toPromise();
  }

  public async LoadVoteBufferStatus() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadVoteBufferStatus`).toPromise<VoteBufferStatus[]>();
  }

  public async GetActualOpenPollEntities() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetActualOpenPollEntities`).toPromise<PollEntity[]>();
  }

  public async GetSmartphoneStatus() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetSmartphoneStatus`).toPromise<SmartphoneOperationStatus[]>();
  }

  public async SmartphoneOpenVoteCollection(pPEID: number, preferences: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SmartphoneOpenVoteCollection`, { pPEID: pPEID, preferences: preferences }).toPromise<boolean>();
  }

  public async SmartphoneSendClosingVoteCollection(pPEID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SmartphoneSendClosingVoteCollection`, pPEID).toPromise<boolean>();
  }
  public async SmartphoneCloseVoteCollection(pPEID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SmartphoneCloseVoteCollection`, pPEID).toPromise<boolean>();
  }

  public async GetEventoRegiaByPEID(peid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetEventoRegiaByPEID?peid=${peid}`).toPromise<vEventoAssemblea>();
  }

  public async SetSmartphoneStatus(PID: number, status: SmartphoneStatus, operatore: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SetSmartphoneStatus`, { PID: PID, status: status, operatore: operatore }).toPromise<ServiceSmartphoneResponse>();
  }

  public async RemoveFromSmartphoneOperationStatus(pPID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RemoveFromSmartphoneOperationStatus`, pPID).toPromise<ServiceSmartphoneResponse>();
  }

  public async RemoveAllSmartphoneOperationStatus() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RemoveAllSmartphoneOperationStatus`, null).toPromise<ServiceSmartphoneResponse>();
  }

  public async SmartphoneNotifyCleanAll() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SmartphoneNotifyCleanAll`, null).toPromise<boolean>();
  }

  public async RequireSmartphoneLogs() {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RequireSmartphoneLogs`, null).toPromise<boolean>();
  }

  public async RequireInstantSmartphoneLog(pPID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/RequireInstantSmartphoneLog`, pPID).toPromise<boolean>();
  }

  public async GetLastLocalDataResetDate() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetLastLocalDataResetDate`).toPromise<ServiceSmartphoneResponse>();
  }

  public async GetCurrentVoteStatus() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetCurrentVoteStatus`).toPromise<ServiceSmartphoneResponse>();
  }

  public async GetShareholdersFromPAID(PAID: number, PAIDShareholder: number, PEID: number, allData: boolean, barcodeIngresso: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetShareholdersFromPAID?PAID=${PAID}&PAIDShareholder=${PAIDShareholder}&PEID=${PEID}&allData=${allData}&barcodeIngresso=${barcodeIngresso}`).toPromise<ServiceSmartphoneResponse>();
  }



  public async SendShareholderVote(PAID: number, PAIDShareholder: number, PDID: number,
                                  executedOn: Date, executedBy: number, code: string,
                                  offlineVote: string, onlyCurrent: boolean, barcodeIngresso: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SendShareholderVote`, { PAID: PAID, PAIDShareholder: PAIDShareholder, PDID: PDID
                          , executedOn: executedOn, executedBy: executedBy, code: code , offlineVote: offlineVote, onlyCurrent: onlyCurrent, barcodeIngresso: barcodeIngresso
    }).toPromise<ServiceSmartphoneResponse>();
  }

  public async GetShareholdersFromCSID(CSID: string, PEID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetShareholdersFromCSID?CSID=${CSID}&PEID=${PEID}`).toPromise<ServiceSmartphoneResponse>();
  }

  public async EVOT_SaveVoteByCode(Code: string, pPollDetailList: any, BatchCode: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EVOT_SaveVoteByCode?Code=${Code}&BatchCode=${BatchCode}`, pPollDetailList).toPromise<string>();
  }

  public async EVOT_SaveNewCustomPollDetail(pNewDetail: any) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EVOT_SaveNewCustomPollDetail`, pNewDetail).toPromise<number>();
  }

  public async EVOT_LoadAccountingDetailsNewAfterVote(code: string, PEID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EVOT_LoadAccountingDetailsNewAfterVote?Code=${code}&PEID=${PEID}`).toPromise<AccountingDetail[]>();
  }

  lastPollingBoothStatusSent: Date = new Date();

  public async SetPollingBoothStatus(PID: number, cabina: string, status: PollingBoothStatus, barcode: string, partecipante: string) {
    if (status != PollingBoothStatus.KEEP_ALIVE ||
      ((new Date().getTime() - new Date(this.lastPollingBoothStatusSent).getTime()) / 1000) >= 30) {
      this.lastPollingBoothStatusSent = new Date();
      return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SetPollingBoothStatus`, { PID: PID, cabina:cabina, status: status, barcode: barcode, partecipante: partecipante }).toPromise<ServicePollingBoothResponse>();
    }
  }

  public async GetPollingBoothStatus() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPollingBoothStatus`).toPromise<PollingBoothOperationStatus[]>();
  }

  public async SendToPollingBooth(barcode: any, PID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SendToPollingBooth?pid=${PID}`, { barcode: barcode }).toPromise<string>();
  }

  public async LogoutPollingBooth(PID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LogoutPollingBooth`, { pid: PID }).toPromise<string>();
  }

  public async GetPrintedReportList() {
    return await this.http.get<PrintedReport[]>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPrintedReportList`).toPromise();
  }

  public async getVotazioniRegia() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetVotazioniRegia`).toPromise<vEventoAssemblea[]>();
  }

  public async LoadHistoryAccountingDetails(pPAID: number, vcid: number, peid: number, pTimeStamp: Date, barcodeIngresso: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadHistoryAccountingDetails?pPAID=${pPAID}&vcid=${isNullOrUndefined(vcid) ? '' : vcid}&peid=${isNullOrUndefined(peid) ? '' : peid}&pTimeStamp=${isNullOrUndefined(pTimeStamp) ? '' : pTimeStamp}&barcodeIngresso=${isNullOrUndefined(barcodeIngresso) ? '' : barcodeIngresso}`).toPromise<AccountingDetail[]>();
  }

  public async LoadPollEntitiesByVoteCardID(pVCID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadPollEntitiesByVoteCardID?pVCID=${pVCID}`).toPromise<PollEntity[]>();
  }

  public async LoadVoteCardByVCID(pVCID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadVoteCardByVCID?pVCID=${pVCID}`).toPromise<VoteCard>();
  }

  public async GetEventiRegiaByVoteCardID(vcid: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetEventiRegiaByVoteCardID?vcid=${vcid}`).toPromise<vEventoAssemblea[]>();
  }

  public async GetPrintedReport2EventList() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPrintedReport2EventList`).toPromise<StoredReport2Event[]>();
  }

  public async GetCulture() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetCulture`).toPromise<Culture[]>();
  }

  public async GetStoredReportList(isCustom: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetStoredReportList?isCustom=${isCustom}`).toPromise<Report[]>();
  }

  public async GetExtraResources() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetExtraResources?type=`).toPromise<ExtraResource[]>();
  }

  public async GetExtraResourcesTypes(forCheckIn: boolean) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetExtraResourcesTypes?forCheckIn=${forCheckIn}`).toPromise<ExtraResourceType[]>();
  }

  public async GetPrinterServers() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetPrinterServers`).toPromise<string[]>();
  }

  public async DeleteStoredReport2Event(revid: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteStoredReport2Event`, revid).toPromise<any>();
  }

  public async SaveStoredReport2Event(storedReport2Event: StoredReport2Event) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveStoredReport2Event`, storedReport2Event).toPromise<any>();
  }

  public async DeleteStoredReport(reid: number, isCustom: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteStoredReport?reportType=&isCustom=${isCustom}`, reid).toPromise<any>();
  }

  public async SaveStoredReport(storedReport: Report) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveStoredReport`, storedReport).toPromise<any>();
  }

  public async GetDefaultLayoutForDesigner(reportType: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetDefaultLayoutForDesigner`, reportType).toPromise<any[]>();
  }


  public async GetAllSmartphoneLog() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetAllSmartphoneLog`).toPromise<SmartphoneLog[]>();
  }

  public async GetSmartphoneLogDetailsBySLID(SLID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetSmartphoneLogDetailsBySLID?SLID=${SLID}`).toPromise<SmartphoneLogDetail[]>();
  }


  public async GetActiveJobByType(JobType: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetActiveJobByType?JobType=${JobType}`).toPromise<AsseBackgroundJob>();
  }

  public async GetLastCompletedJobByType(JobType: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetLastCompletedJobByType?JobType=${JobType}`).toPromise<AsseBackgroundJob>();
  }

  public async GetCompletedJobsByType(JobType: string) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetCompletedJobsByType?JobType=${JobType}`).toPromise<AsseBackgroundJob[]>();
  }

  public async CancelJob(JobId: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/CancelJob?JobId=${JobId}`,null).toPromise<boolean>();
  }

  public async ExportCredentials(JobId: string) {
    let response = this.http.get(`${this.configurationService.serverSettings.webApiServiceUrl}/ExportCredentials?JobId=${JobId}`, { responseType: "blob", observe: 'response' })
      .subscribe(data => {
        const contentDisposition = data.headers.get('content-disposition');
        const filename = this.getFilenameFromContentDisposition(contentDisposition);
        const blob = data.body;
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = filename;
        anchor.href = url;
        anchor.click();
      });

  }

  public async MassiveUsersCreation(PropertyForUserName: string, PropertyForPassword: string, SendCredentialsMail: boolean, ForcePasswordChange: boolean, ExportCredentials: boolean, CreateUserForDelegant: boolean)
  {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/MassiveUsersCreation`, {
      PropertyForUserName: PropertyForUserName,
      PropertyForPassword: PropertyForPassword,
      SendCredentialsMail: SendCredentialsMail,
      ForcePasswordChange: ForcePasswordChange,
      ExportCredentials: ExportCredentials,
      CreateUserForDelegant: CreateUserForDelegant
    }).toPromise<boolean>();
  }


  public async GetTopicsOnline() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetTopicsOnline`).toPromise<SpeechTopicOnline[]>();
  }

  public async GetTopicOnlineByTID(TID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetTopicOnlineByTID?TID=${TID}`).toPromise<SpeechTopicOnline>();
  }

  public async GetRequestsOnline(TID: number, OnlyWithPendingResponse: boolean, StateFilter:number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetRequestsOnline?TID=${TID}&OnlyWithPendingResponse=${OnlyWithPendingResponse}&StateFilter=${StateFilter}`).toPromise<SpeechRequestOnline[]>();
  }

  public async GetRequestOnline(requestId: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetRequestOnline?requestId=${requestId}`).toPromise<SpeechRequestOnline>();
  }

  public async ToggleTopic(TID: number, Open: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ToggleTopic?TID=${TID}&Open=${Open}`,null).toPromise<any>();
  }

  public async GetLocalizedTopicConfig(TID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetLocalizedTopicConfig?TID=${TID}`).toPromise<TopicLocalization[]>();
  }

  public async SaveLocalizedTopicConfig(TID: number, topicLocalizations: TopicLocalization[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveLocalizedTopicConfig?TID=${TID}`, topicLocalizations).toPromise<boolean>();
  }

  public async ChangeTopicRequestState(RequestId: number, State: number, Message: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChangeTopicRequestState?RequestId=${RequestId}&State=${State}&Message=${Message}`, null).toPromise<any>();
  }

  public async GetTopicRequestAttachment(AttachmentId: number) {
    let response = this.http.get(`${this.configurationService.serverSettings.webApiServiceUrl}/GetTopicRequestAttachment?AttachmentId=${AttachmentId}`, { responseType: "blob", observe: 'response' })
      .subscribe(data => {
        const contentDisposition = data.headers.get('content-disposition');
        const filename = this.getFilenameFromContentDisposition(contentDisposition);
        const blob = data.body;
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.download = filename;
        anchor.href = url;
        anchor.click();
      });

  }


  public async GetLocalizationsMail() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetLocalizationsMail`).toPromise<any>();
  }

  public async ChangeLocalization(cuid: number, group: string, name: string, value: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChangeLocalization?cuid=${cuid}&group=${group}&name=${name}&value=${value}`, null).toPromise<boolean>();
  }


  public async GetAllPreSysConfigs() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetAllPreSysConfigs`).toPromise<PreSysConfig[]>();
  }

  public async SavePreSysConfigs(configs: PreSysConfig[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SavePreSysConfigs`, configs).toPromise<boolean>();
  }

  public async GetLocalizedStreamingURLConfig() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetLocalizedStreamingURLConfig`).toPromise <ResourcesDictionaryItem<string>>();
  }

  public async SaveLocalizedStreamingURLConfig(configs: ResourcesDictionaryItem<string>) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SaveLocalizedStreamingURLConfig`, configs).toPromise<boolean>();
  }

  public async GetZoomVideoTokenTest() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetZoomVideoTokenTest`).toPromise<string>();
  }

  ///#region Zones
  public async loadZones() {
    return await this.http.get<ShareholderZone[]>(`${this.configurationService.serverSettings.webApiServiceUrl}/loadZones`).toPromise();
  }
  public async loadZonesWithoutCount() {
    return await this.http.get<ShareholderZone[]>(`${this.configurationService.serverSettings.webApiServiceUrl}/loadZonesWithoutCount`).toPromise();
  }
  public async insertOrUpdateZone(zone: ShareholderZone) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/insertOrUpdateZone`, zone).toPromise<void>();
  }
  public async deleteZone(zone: ShareholderZone) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/deleteZone`, zone).toPromise<void>();
  }
  public async deleteUnusedZones() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/deleteUnusedZones`).toPromise<void>();
  }
  public async addMissingZones() {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/addMissingZones`).toPromise<void>();
  }
  ///#endregion


  public async LoadCategories() {
    return await this.http.get<ShareholderCategory[]>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadCategories`).toPromise();
  }

  public async LoadShareholderCategories(PAID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LoadShareholderCategories?PAID=${PAID}`).toPromise<ShareholderCategory[]>();
  }

  public async ChangeOwnedCategories(SCID: number, PAID: number, owned: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ChangeOwnedCategories?SCID=${SCID}&PAID=${PAID}&owned=${owned}`, null).toPromise<any>();
  }

  public async InsertCategory(category: ShareholderCategory) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertCategory`, category).toPromise<void>();
  }

  public async InsertOrUpdateCategory(category: ShareholderCategory) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/InsertOrUpdateCategory`, category).toPromise<void>();
  }

  public async UpdateCategory(category: ShareholderCategory) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/UpdateCategory`, category).toPromise<void>();
  }

  public async DeleteCategory(category: ShareholderCategory) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/DeleteCategory`, category).toPromise<void>();
  }


  public async GetOwnedCategories(PAID: number) {
    return await this.http.get<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/GetOwnedCategories?PAID=${PAID}`).toPromise<ShareholderCategory[]>();
  }

  public async importCategory(req: ImportCategoryRequest[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ImportShareholderToCategory`, req).toPromise<ShareholderToCategory[]>();
  }

  public async ImportComunicazioneMT(comunicazione: ComunicazioneMT[], pIsPreview: boolean) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ImportComunicazioneMT?pIsPreview=${pIsPreview}`, comunicazione).toPromise<ComunicazioneMT[]>();
  }

  //private List<ComunicazioneMT> loadComunicazioniListFromFile(string fileContent, string nomeFile)
  public async LoadComunicazioniListFromFile(fileContent: string, nomeFile: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/loadComunicazioniListFromFile`, { fileContent: fileContent, nomeFile: nomeFile }).toPromise<ComunicazioneMT[]>();
  }

  public async EnteringFromPollClient(PAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EnteringFromPollClient?PAID=${PAID}`, null).toPromise<string>();
  }

  public async SetZoneAndCategoryIfEnabled(PAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/SetZoneAndCategoryIfEnabled`, PAID).toPromise<string>();
  }

  public async StartPollVotingSession(PAID: number) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/StartPollVotingSession`, PAID).toPromise<void>();
  }

  public async EndPollVotingSession(PAID: number, esito: string) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/EndPollVotingSession?esito=${esito}`, PAID).toPromise<void>();
  }

  public async LogPollVotingSession(session: EPollVotingSessions) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/LogPollVotingSession`, session).toPromise<void>();
  }

  //test import principal
  public async importPrincipal(req: ImportPrincipalRequest[]) {
    return await this.http.post<any>(`${this.configurationService.serverSettings.webApiServiceUrl}/ImportPrincipal`, req).toPromise<Principal[]>();
  }  
}

interface DictionaryCapability {
  RID: number;
  capabilities: Capability[];

}
