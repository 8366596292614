

/// <summary>
///     Tipo di relazione tra PAID
/// </summary>
export enum DatabaseReturnCode {
  // Only messages


  Ok = 0,
  YesBut = 1, //OK Con messaggio warning (Usato nell'import IMPORT)


  // Critical severity, obstructive


  TransactionAborted = 10,
  DuplicateKey = 1001,


  // Normal severity, but obstructive


  IncorrectParameters = 20,
  VotesExceedShreholderPieces = 21,
  NoDetais = 22,
  PotentialAttendantNotRegistered = 23,
  TagNotAssociated = 24,
  EventNotOpen = 25,
  ShareholderNotIn = 26,
  VoteDoneOrMaxNumberExceeded = 27,
  TagUnknown = 28,
  TagDuplicated = 29,
  MappingUnknown = 220,
  ScrutinioCabinaNonCoerente = 250,

  EventActive = 201,
  NoMajorityOnIncompleteRange = 202,

  PotentialAttendantAlreadyEntered = 210,

  PotentialAttendantNotExists = 220,
  PotentialAttendantAlreadyRegistered = 221,
  PotentialAttendantAlreadyPreaccounted = 222,

  BarcodeIngressoAnnullato = 230,

  CodiceSchedaAltraVotazione = 240,
  CodiceSchedaAnnullato = 241,
  CodiceSchedaInesistente = 242,


  // Warning, not obstructive


  NoSGreaterNominal = 30,
  ConfirmError = 32,
  AccountingDetailAlreadyInserted = 36,
  ShareholderCanVote = 37,
  AnnulmentDenied = 38,
  ShareholderCanVoteBecauseEvents = 39,	   //Il socio non puó votare perché non era presente nell'intervallo di valutazione.

  IsNotShareholder = 40,
  IsNotLegalRepresent = 41,
  IsNotFosterCare = 42,
  ShareholderAndDelegator = 43,
  CanNotAccess = 44,
  CanNotDelegate = 45,
  CanNotHaveDelegate = 46,
  CanNotNullify = 47, //CAPABILITY 2
  CanNotNullifyNotarilPrints = 49,
  CanNotNullifyAfterDeliveredVotacards = 90,
  CanNotNullifyAfterVote = 91,
  CanNotNullifyAfterEvent = 92,
  ErrorCalculatingCustomShares = 48,

  //EVENTS REGIA
  CanNotDeleteWithChildren = 50,
  CanNotDeleteSystem = 51,
  CanNotDeleteOpenEvents = 52,
  CanNotResetPollInClosedEvents = 53,
  VotesPresentCanNotDelete = 54,
  VoteFieldBinding = 55,

  RoleAssignedToPrincipal = 60,
  VoteCardAssignedToPollEntity = 61,
  ShareholderTypeAssignedToShareholder = 62,
  ShareholderAFette = 63,
  CanNotDelegateNotShareholder = 64,
  MaxDelegateNumberExceeded = 65,
  AgencyAssignedToShareholder = 66,
  CodeUnknown = 67,
  DirectorMovementNotPossible = 68,
  VoteStationClosed = 69,

  CanNotBackInFlowForVoteDone = 70,

  VoteCardWithPollEntities = 80,
  VoteCardWithStoreReport = 81,
  VoteCardWithVotes = 82,
  VoteCardWithNotCompatiblePollEntities = 83,
  VoteCardConcurrencyDelivery = 84,
  VoteCardConcurrencyDeliveryAndVoted = 85,
  VoteCardNotDelivered = 86,
  OMRVoteCardPermissionDenied = 87,

  PollNotWellConfigured_PE = 88,
  PollNotWellConfigured_PD = 89,

  AuthorizationFailed = 100,
  MovementsLocked = 101,
  DatabaseNotReadyForImport = 102,
  NeedShareholderImport = 103,
  NoAgency = 104,
  NoVipNoteStyle = 105,

  PreAccountingClosed = 110,
  MovementAlreadyPerformed = 111,

  AlreadyRegisteredToSpeech = 121,
  SpeechTopicIDUnknown = 122,
  SpeechTopicInUse = 123,

  WarningVotesAlreadyPresents = 130,
  ScanIDNotExists = 131,
  BarcodeSchedaNotExists = 132,
  PesoSchedaNonPresente = 133,
  ProgressivoNonPresente = 134,
  ErrorFailedVoteCardUpdate = 140,
  ErrorFailedVoteCardVoteRegistration = 141,
  ErrorOMRVoteCardNotPresent = 150,
  ErrorNoMajorityVoteOnSecretPoll = 180,

  ConfigurationError = 980,

  UnknowError = 990,
  Null = 999,


  ResourceMaxUnit = 400,
  ResourceTypeInUse = 401,
  ResourseInUsebyPA = 402,
  ResourceDescrAlreadyUsed = 403,
  ResourceTypeNotExists = 404,
  ResourceUnitUsedOverMax = 405,
  ResourceIDNotValid = 406,


  VipNoteStyleAssignedST = 450,
  VipNoteStyleAssignedPA = 451,

  GuestAlreadyRegistered = 500,
  GuestAlreadyInside = 501,
  GuestNotRegistered = 502,
  GuestIDAlreadyUsed = 503,
  GuestAlreadyDeregistered = 504,
  GuestAlreadyDeleted = 505,
  GuestMissBusinessName = 506,
  GuestCodeIncorrect = 507,
  GuestInsert = 508,
  GuestLogger = 509,

  DelegatorNotFound = 600,
  DelegateNotFound = 601,


  DelegatoCanNotAccess = 630,
  DelegatoNoCertification = 631,
  DelegatoNonHaRL = 632,

  DeleganteCanNotAccess = 650,
  DeleganteNoCertification = 651,

  RLNotShareholderCreated = 610,
  PANotUpdated = 611,
  PAUpdated = 612,

  SysConfigDataTypeDiffers = 701,
  StoredReportVCIDNotExists = 702,
  StoredReport2EventREIDPrimaryNotExists = 703,
  StoredReport2EventREIDSecondaryNotExists = 704,
  ReportVersionREIDNotExists = 705,
  SysConfigObsolete = 706,
  CapabilityObsolete = 707,

  VCIDNotExists = 710,
  PEIDNotExists = 711,

  ShareholderHasMovements = 901,



}

export enum ImportReturnCode {
  Debug = -1,
  LineOk = 0,
  LineWarning = 1,
  LineError = 2,
  Completed = 10,
  Cancelled = 11,
  CriticalError = 12,
  CompletedWithError = 13
}
export enum PotentialAttendantPageGridFilter {
  All = -1,
  Shareholders = 0,
  Legals = 1,
  Others = 9
}
export enum ImportSetType {
  ShareholderImport = 0,
  LegalRepresentative = 1,
  Agency = 2,
  Guest = 3,
  Delegators = 4
}

export enum ImportPotentialAttendantOperation {
  GetConfig,
  Import
}

export enum LoginResults {
  None,
  UsernameNotFound,
  DisabledUser,
  WrongPassword,
  AlreadyLoggedIn,
  LoggedIn,
  UnknownError,
  DatabaseConnectionFault,
  SecurityConnectionError,
  EscalationFailed,
}

export enum PotentialAttendantRelationShipType {
  Shareholder = 10,
  LegalRepresentation = 20,
  Delegation = 40
}


//export enum PollCountType {
//  majority,
//  detail,
//  detailQuick,
//  electronic
//}


export enum PollEventTypes {
  VotingStart = 1,
  VotingClosed = 2,
  VoteCountingStart = 10,
  VoteCountingEnd = 11
}

export enum MovementEventTypes {
  Entry = 1,
  Exit = 2,
  Cancellation = 10,
  Reprint = 11,
  EnterGuest = 21,
  ExitGuest = 22,
  PreAccount = 30,
  RemovePreAccount = 31,
  PreAccountReprint = 32,

  DeliverVoteCard = 40,
  PADeliveredVoteCards = 41,
  PACollectedVoteCards = 42,

  Account = 50,
  RemoveAccount = 51,
  AccountReprint = 52,
  PollReceipt = 60,
  NumberReceipt = 61,
  VotingIstructions = 62,
  RapprDesignato = 63,
}

/// <summary>
/// Type of meeting event
/// </summary>
export enum MeetingEventTypes {
  Generic = 0,
  PreAccounting = 1,
  Speech = 2,
  Ordinary = 3,
  Extra = 4
}

export enum MeetingGeneralType {
  Generic = -1,
  OrdinaryExtra = 2,
}

export enum MeetingValidity {
  Ordinary = 0,
  Extra = 1,
  Both = 2,
}

export enum ReportCustomProperties {
  ShowStructureParentChild,
  ShowSummaryData,
  ShowDetailData,
  ShowMainData,
  PollEventPEID,
  Sorting,

  ViewOnlyPAWithShowInStatiscticsFlagActive,

  //MOVEMENT
  MovementIn,
  MovementOut,
  MovementCancelled,
  MovementFrom,
  MovementFromEventDescr,
  MovementTo,
  MovementToEventDescr,
  MovementOfShareholder,

  //SHAREHOLDER REPORT
  ShowShareholderTotalNum,
  ShowShareholderTotalNumOfShare,

  PhoenixExporter_UseCDG,

  //ShowNumbers,
  //ShowNumOfShares,
  //ShowNumPercentages,
  //ShowDelegatesAndDelegantingsNum,
  //ShowDoubleFaceRoleNums,

  //LETTER
  PAIDList,

  MovementPAID,
  MovementPAID_Shareholder,
  MovementPAIDRef,
  VoteCardID,

  VoteCardCollecting,
  VoteCardPackagePAID,
  VoteCardPackageOperationDate,

  CustomMergedReportID,

  SingleMovement,
  SinglePoll,
  SingleMovementWithDel,

  ValutazioneIntervallo,
  BarCode,
  //EVENTREP

  FromEvent, //Per Distinguerlo dalla chiamata dal web (Ristorante)
  TipologiaAssemblea,  //ORDINARIA = 0 - STRAORDINARIA = 1
  ClientSavePath, //Salvataggio Batch in questa cartella
  ReportFileName, // nome proposto per il report
  AssembleaEventType, // valori come MeetingGeneralType

  IncludeNonVoters,
  OperatorDetails,
  IsNotShareholder, //gestione printsinglemovementwithdel

  ExcludePollDetailByName, //escludi preferenza con nome dai risultati delle votazioni (case insensitive, generalmente = 'favorevole') 
  ConfigurationQR,     //XML di Stampa del qr di configurazione smartphone 

  PrincipalBadgeCard,
  PrincipalBadgeCardPrefix,
  PollType,
  VoteList,
  PDID,
  BatchCode,
  PEID,
  CurrPage,
  TotPage,

  //eventi assemblea
  EAID,

  SpeechAccounting_PAID,
  SpeechAccounting_TID,

  RistampaScheda,
  CodiceScheda,

  MovementEventType,
  Votanti,
  VIID,
  CUID,
  DistintaPerZona,
  DistintaPerCategoria,

  PID,
  FromDate,
  ToDate
}

export enum ReportOuput {
  PDF,
  XLSX,
  PHOENIX,
  COMPUTERSHARE
}



export enum ReportTypes {
  Undefined = -1,
  ShareholderPresents = 0,
  Shareholders = 1,
  Movements = 2,
  RealTimePolls = 3,
  Participants = 5,
  ParticipantsWithPolls = 6,
  Accounting = 7,
  Events = 8,             //   ISCustom = true
  InvitationLetters = 9,  //   ISCustom = true
  VoteCard = 10,          //   ISCustom = true
  VoteCardDelivered = 12,
  VoteCardCollected = 13,
  ShareholderCanVotePoll = 14,
  RealTimePollsNumber = 15,
  RealTimePollsAction = 16,
  GuestEvent = 21,        //   ISCustom = true
  GuestParticipants = 22,
  GuestInside = 23,
  //GuestMovements = 24, //obsoleta
  Lottery = 30,
  SpeechAccounting = 35,
  SpeechAccountingDetail = 36,
  ShareholderPresentsPreAccounting = 40,
  ShareholderPresentsWithDelegationInRow = 41,
  ConfigurationQR = 54,
  ParticipantsLegal = 55, //legacy
  ShareholderPresentsLegal = 56, //legacy
  ShareholderPresentsDetail = 57,
  CustomMergedReport = 90, //somma di più report configurabile 
  PrincipalLoginCard = 100,
  PADeliveredVoteCardsReport = 200, // vote card consegnate
  PACollectedVoteCardsReport = 201, // vote card ritirate

  ReportPartecipantiFisici = 300,
  ReportPartecipantiAlbero = 301,
  ReportRisultatiAggregato = 302,
  ShareholderPresentsNum = 303,
  ShareholderPresentsAz = 304,
  PollReceipt = 400,
  NumberReceipt = 401,
  Votanti = 402,
  VotingIstructions = 403,
  RapprDesignato = 404,

  CoopVotanti = 501,
  CoopVoti = 502,
  CoopRisultatiElettive = 503,
  CoopSessioniVoto = 504,
  CoopNumeroVotanti=505
}


export function GetDescrByReportType(reportType: ReportTypes): string {
  switch (reportType) {
    case ReportTypes.ShareholderPresents:
      return "Sintesi Presenze";
    case ReportTypes.ShareholderPresentsNum:
      return "Sintesi Presenze Numeri";
    case ReportTypes.ShareholderPresentsAz:
      return "Sintesi Presenze Azioni";
    case ReportTypes.ShareholderPresentsDetail:
      return "Dettaglio Presenze";
    case ReportTypes.Shareholders:
      return "Elenco Soci";
    case ReportTypes.Movements:
      return "Movimenti";
    case ReportTypes.RealTimePolls:
      return "Mozioni";
    case ReportTypes.RealTimePollsAction:
      return "Mozioni Azioni";
    case ReportTypes.RealTimePollsNumber:
      return "Mozioni Numeri";
    case ReportTypes.Participants:
      return "Partecipanti";
    case ReportTypes.ParticipantsWithPolls:
      return "Partecipanti e votazioni";
    case ReportTypes.Events:
      return "Biglietto di ammissione";
    case ReportTypes.InvitationLetters:
      return "Invito";
    case ReportTypes.VoteCard:
      return "Scheda di voto";
    case ReportTypes.VoteCardDelivered:
      return "Distinta schede di voto consegnate";
    case ReportTypes.VoteCardCollected:
      return "Distinta schede di voto riconsegnate";
    case ReportTypes.GuestEvent:
      return "Biglietto di ammissione ospite";
    case ReportTypes.GuestParticipants:
      return "Ospiti partecipanti";
    case ReportTypes.GuestInside:
      return "Ospiti presenti";
    //case ReportTypes.GuestMovements:
    //  return "Movimenti ospiti";
    case ReportTypes.Lottery:
      return "Premi e vincitori";
    case ReportTypes.SpeechAccounting:
      return "Iscritti ad Intervenire";
    case ReportTypes.CustomMergedReport:
      return "Report unico";
    case ReportTypes.ShareholderPresentsPreAccounting:
      return "Report Pre Registrazioni";
    case ReportTypes.ShareholderCanVotePoll:
      return "Report soci ammessi al voto";
    case ReportTypes.PrincipalLoginCard:
      return "Badge login utente";
    case ReportTypes.PADeliveredVoteCardsReport:
      return "Schede di voto consegnate al socio";
    case ReportTypes.PACollectedVoteCardsReport:
      return "Schede di voto ritirate al socio";
    case ReportTypes.Accounting:
      return "Registrazioni";
    case ReportTypes.ShareholderPresentsWithDelegationInRow:
      return "Dettaglio presenze";
    case ReportTypes.ReportPartecipantiFisici:
      return "Partecipanti Fisici";
    case ReportTypes.ReportPartecipantiAlbero:
      return "Partecipanti con relazioni";
    case ReportTypes.ReportRisultatiAggregato:
      return "Aggregato risultati votazioni";
    case ReportTypes.ParticipantsLegal:
      return "Partecipanti formato legale";
    case ReportTypes.ShareholderPresentsLegal:
      return "Presenti formato legale";
    case ReportTypes.SpeechAccountingDetail:
      return "Dettaglio Intervento";
    case ReportTypes.ConfigurationQR:
      return "Configuraione QR";
    case ReportTypes.PollReceipt:
      return "Ricevuta votazione";
    case ReportTypes.NumberReceipt:
      return "Numero ricevute";
    case ReportTypes.Votanti:
      return "Votanti / Non Votanti";
    case ReportTypes.VotingIstructions:
      return "Istruzioni di voto";
    case ReportTypes.RapprDesignato:
      return "Istruzione di voto (vuota)";
    case ReportTypes.Undefined:
    default:
      return "Non definito";
  }
}


export function GetDescrByMovementEventType(reportType: MovementEventTypes): string {
  switch (reportType) {
    case MovementEventTypes.Entry:
      return "Ingresso socio";
    case MovementEventTypes.Exit:
      return "Uscita socio";
    case MovementEventTypes.Cancellation:
      return "Annullo socio";
    case MovementEventTypes.Reprint:
      return "Ristampa socio";
    case MovementEventTypes.EnterGuest:
      return "Ingresso ospite";
    case MovementEventTypes.ExitGuest:
      return "Uscita ospite";
    case MovementEventTypes.PreAccount:
      return "Pre registrazione Socio";
    case MovementEventTypes.RemovePreAccount:
      return "Rimozione pregistrazione Socio";
    case MovementEventTypes.PreAccountReprint:
      return "Ristampa pre registrazione socio";
    case MovementEventTypes.DeliverVoteCard:
      return "Consegna scheda";
    case MovementEventTypes.PADeliveredVoteCards:
      return "Lista schede consegnate al socio";
    case MovementEventTypes.PACollectedVoteCards:
      return "Lista schede ritirate al socio";
    case MovementEventTypes.Account:
      return "Registrazione Socio";
    case MovementEventTypes.RemoveAccount:
      return "Rimozione registrazione Socio";
    case MovementEventTypes.AccountReprint:
      return "Ristampa registrazione socio";
    case MovementEventTypes.PollReceipt:
      return "Ricevuta di votazione";
    case MovementEventTypes.NumberReceipt:
      return "Numero ricevute";
    case MovementEventTypes.VotingIstructions:
      return "Istruzione di voto";
    case MovementEventTypes.RapprDesignato:
      return "Rappresentante designato";
    default:
      return "Non definito";
  }
}

export enum SysConfigDataTypes {
  TypeNone = 0,
  TypeInteger = 1,
  TypeBoolean = 2,
  TypeDouble = 3,
  TypeString = 4,
  TypeDateTime = 5,
  TypeColor = 6,
  TypeImage = 7,
  TypeMeetingType = 8,
}

export enum SysConfigCriticalLevel {
  LVL_Normal = 0,
  LVL_Dangerous = 1,
  LVL_Fatal = 2,
}

export enum SysConfigTargetTypes {
  Base = 1,
  Extra = 2,
  Extra2 = 4,
  Mobile = 8,
  MobileExtra = 16,
  Reporting = 32,
  EVote = 64,
  Dashboard = 128,
  License = 256
}
export enum PrincipalEventType {
  EVENT_LOGGEDIN = 100,
  EVENT_LOGGEDOUT = 101,

  EVENT_SMARTPHONE_STARTVOTECOLLECTION = 400,
  EVENT_SMARTPHONE_STOPVOTECOLLECTION = 401,
}

export enum MeetingPageMode {
  DEFAULT = 0,
  DOPPIO_STEP = 1,
  PRE_REGISTRAZIONI = 2,
  CREATE_ENTRANCE = 3,
}

export enum MovementMode {
  TUTTI = 0,
  SOLO_INGRESSI = 1,
  SOLO_USCITE = 2,
}

export enum RealTimeStatMode {
  NORMAL = 0,
  PREACC = 2
}

export enum SpeechAccountingStatusTypes {

  DeveIntervenire = 1,
  NonInterverra = 2,
  Intervenuto = 4
}


export enum SmartphoneStatus {
  NONE = 0,
  RESUMED = 1,
  PAUSED = 2,
  PAUSED_WITH_VOTES = 3,
  CLOSED = 4,
  CLOSED_WITH_VOTES = 5,
  SCANNING_CODE = 6,
  TIMEOUT_SCANNING_CODE = 7,
  COLLECTING_VOTE = 8,
  TIMEOUT_COLLECTING_VOTE = 9,
  VOTE_SENT = 10,
  REQUESTING_TIME = 11,
  REQUESTING_ASSISTANCE = 12,
  ENTER_ADMIN_MODE = 13,
  EXIT_ADMIN_MODE = 14,
  KEEP_ALIVE = 15,
  WAITING_NEXT_SCAN = 16,
  LOGGED_IN = 17,
  DONE = 18,
  ERROR_SEND_OFFLINE_VOTE = 19,
}

export enum PollingBoothStatus {
  NONE = 0,
  SEARCH_CODE=1,
  CLOSED = 2,
  CLOSED_WITHOUT_VOTING = 3,
  TIMEOUT_VOTING = 4,
  VOTING = 5,
  VOTE_SENT = 6,
  KEEP_ALIVE = 7,
  WAITING_NEXT_SCAN = 8,
  LOGGED_IN = 9,
  DUPLICATED = 10,
  DISCONNECTED=11,
  
}

export enum MovementPageErrors {
  NONE = 0,
  DELEGATION_NO_DELEGATION = 100,
  DELEGATION_OVER_MAX_DELEGATION = 101,
  DELEGATION_OVER_MAX_DELEGATION_EXTRA = 102,
}

export enum ParticularActionType {
  CHANGE_SHAREHOLDER = 0,
  ADD_DETAIL = 1,
  REMOVE_DETAIL = 2,
  CHANGE_PACKAGE = 3,
}

export enum ProjectionPageType {
  STATIC = 0,
  REAL_TIME_DATA = 1,
  VOTE_MANAGEMENT = 2,
  VOTE_RESULTS = 3,
  SPEECH = 4,
  LOTTERY = 5,
  MESSAGE = 6,
  PROIEZIONE_VOTANTI = 7,
}

export enum ProjectionGroupType {
  ALL_GROUP_TYPES = 0,
  ASSEMBLEA = 1,
  VISUALIZZAZIONE = 2,
}

export enum ProjectionOverlayControl {
  EMPTY = 0,
  TITLE = 1,
  IMAGE = 2,
  TIMER = 3,
  COUNTDOWN = 4,
  QUORUM = 5,
  QUORUM_EXTRA = 6,
}

export enum ProjectionControlPosition {
  TOP_LEFT = 0,
  TOP_CENTER = 1,
  TOP_RIGHT = 2,
  BOTTOM_LEFT = 3,
  BOTTOM_CENTER = 4,
  BOTTOM_RIGHT = 5,
  CENTER = 6,
  CENTER_LEFT = 7,
  CENTER_RIGHT = 8,
}

export enum DetachedScreens {
  DEFAULT = 0,
  SCANNER = 1,
  SMARTPHONE = 2,
  PROJECTION = 3,
}

// regia
export enum EventType {
  Evento = 0,
  Votazione = 1,
}

export enum PollTypes {
  Palese = 0,
  Segreta = 1,
}

export enum PollInterval {
  Apertura = 0,
  Intervallo = 1,
  Chiusura = 2,
}


// esportazione
export enum LocalisationExportType {
  SHAREHOLDERTYPE_DESCR = 1,
  SHAREHOLDERTYPE_VINCOLO = 2,
}

export enum VoteType {
  Manuale = 0,
  Smartphone = 1,
  Televoter = 2,
  Scanner = 3,
  Cabina = 4,
}

export enum PollCountType {
  Detail = 0,
  DetailQuick = 1,
  DetailSecret = 2, // usa i codici di voto
  DetailSecretQuick = 3, // usa i codici di voto
  Majority = 4,
  //MajoritySecret = 5, // TODO: ha senso votare tutti i codici e poi andarci sopra codice per codice?
  PreVote = 6,
}

export enum PollSelectorType {
  Single = 0,
  SingleQuick = 1,
  Card = 2, 
  CardQuick = 3,
  Majority = 4,
  MajorityCard = 5,
  PreVote = 6,
}

export enum ActualVoteStatus {
  NotVoted = 0,
  OnlyBulkVotes = 1,
  CustomVotesPresent = 2,
}

export enum TelevoterVoteType {
  Maggioranza = 0,
  OpzioniNumeriche = 1,
}

export enum VoteCollectionMode {
  TutteAttive = 0,
  ContrariAstenuti = 1,
  AstenutiContrari = 2,
}

export enum VoteStatus {
  Chiuso = 0,
  Aperto = 1,
  ProssimaChisura = 2,
}

export enum VoteCollectionActivePreferences {
  Tutti = 0,
  Contrari = 1,
  Astenuti = 2,
}

export enum TelevoterDeviceType {
  DT_ENGAGE = 1,
  DT_SUNVOTE = 2,
}

export enum SummaryTypeEnum {
  ST_MEETINGPAGE_CUSTOM_SUMMARY = 0,
  ST_PREREGISTRATION_CUSTOM_SUMMARY = 1,
  ST_EXTRARESOURCES = 2,
  ST_AWARDEXTRACTIONS = 3,
  ST_MEETING_STATISTICS = 4,
  ST_SCRUTINIO_SCANNER = 5,
}

export enum VotePresentationOrder {
  ORDER_NUMBER = 0,
  ORDER_SHARES = 1,
  ORDER_SORTN = 2,
}

export enum ReportPresentationOrder {
  ORDER_CODICE = 0,
  ORDER_ALFABETICO = 1,
  ORDER_PACCHETTO_ALFABETICO = 2,
  ORDER_ORA_MOVIMENTO = 3,
  ORDER_OPERATORE = 4,
}

export enum VoteCardUtilsAction {
  NONE = 0,
  RISTAMPA = 1,
  RIGENERA = 2,
  STORNO = 3,
  ANNULLA_CONSEGNA = 4,
  CONSEGNA = 5,
  ANNULLA_RITIRO = 6,
  RITIRO = 7,
}
export enum MovementPageStatus {
  READY = 0,
  NEW_ACCOUNTING = 1,
  NEW_ENTRANCE = 2,
  REGISTERED = 3,
  INSIDE = 4,
  REENTER = 5
}
 export enum PrintResultsTypes {
        DocumentSentToClient,
        DocumentPrintedOnServer,
        DocumentAndLayoutSentToClient,
        DocumentPrintToClient,
        ExportedDocumentSentToClient,
        Error
}

export enum ShareholderTypeAction {
  DEFAULT = 0,
  NOSB = 1,
  NOSC = 2,
  NOSD = 3
}

export enum SpeechRequestStateOnline
{
  NEW = 0, //--> nuova richiesta da approvare
    ACCEPTED = 1, //--> richiesta approvata e verrà mostrata al presidente
    WAITING_ANSWER = 2, //--> in attesa della risposta del presidente
    ANSWERED = 3, //--> il presidente ha risposto si può replicare
    CLOSED = 4, //--> chiusa, non sono ammesse ulteriori repliche
    CANCELLED = 5, //--> annullata dal partecipante (prima che venga approvata)
    REFUSED = 6 //--> rifiutato
}

export enum SpeechTopicTypeOnline
{
  REQUEST = 0,
    PROPOSAL = 1
}

export enum EPollVotingSessionsEsito {
  OK = "OK",
  ANNULLATO = "ANNULLATO",
  TIMEOUT = "TIMEOUT"
}
