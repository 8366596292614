<dx-popup class="popup"
          [showTitle]="true"
          title="Modifica Zona Socio"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          width="600px"
          height="180px"
          [(visible)]="popupVisible" [deferRendering]="false" (onHiding)="onPopupHiding($event)">

  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{ text: 'Ok', onClick: onOkClick, type:'normal', stylingMode:'outlined' }">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton"
                    location="before"
                    toolbar="bottom"
                    [options]="{text: 'Annulla', onClick: onCancelClick, type:'default', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <dx-load-panel container="#ChangeShareholderZoneForm"
                   shadingColor="rgba(0,0,0,0.4)"
                   [(visible)]="loaderVisible"
                   [showIndicator]="true"
                   [showPane]="true"
                   [shading]="true"
                   [hideOnOutsideClick]="false">
    </dx-load-panel>
    <form id="ChangeShareholderZoneForm" action="#">
      <dx-form [(formData)]="formData">
        <dxi-item dataField="ZoneId" editorType="dxSelectBox" [editorOptions]="{dataSource:shareholderZones, valueExpr:'ZoneId', displayExpr:'ZoneId', stylingMode: 'outlined', value:selectedZone, showClearButton: true}">
          <dxo-label location="top" alignment="left" text="Zona Socio"></dxo-label>
          <dxi-validation-rule type="required" message="Selezionare un elemento"></dxi-validation-rule>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>
