import { Role } from './Role';

export class Principal {
  PID: number;
  UserName: string;
  HashedPwd: string;
  EnableBadgePassword: boolean;
  BadgePassword: string;
  FirstName: string;
  LastName: string;
  Language: string;
  IsEnabled: boolean;
  RID: number;
  ModifiedOn?: Date;
  ModifiedBy: number;
  ElectronicVoteMode: boolean;
  StationPrefix: string;
  MobileMode: boolean;
  MovementMode: number;
  Location: string;
  EnableScreenCapture: boolean;
  Token: string;
  Role: Role;

  PhoneCountryCode: string;
  PhoneNumber: string;
  PhoneNumberConfirmed: boolean;
  TwoFactorEnabled:boolean

  Modified: boolean;
  PwdInEditing: string;

  DelegationZone: string;
  ShareholderCategory: string;


  constructor(init?: Partial<Principal>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: Principal[]): Principal[] {
    return list.map(item => { return new Principal(item); });
  }
  static ToInstance(item: Principal): Principal {
    return new Principal(item);
  }

}

