<dx-load-panel container="#SearchAndShowShareholder"
               [position]="{of: '#SearchAndShowShareholder'}"
               shadingColor="rgba(0,0,0,0.4)"
               [(visible)]="loaderVisible"
               [showIndicator]="true"
               [showPane]="true"
               [shading]="true"
               [hideOnOutsideClick]="false">
</dx-load-panel>
<div style="margin-bottom:5px" id="SearchAndShowShareholder">
  <div style="padding: 0px 5px;text-align:left;">
    <dx-text-box #SearchBox [(value)]="Query" [placeholder]="'LABEL_NO_SHAREHOLDER_SELECTED' | formatMessage" stylingMode="outlined" (onKeyDown)="onKeyDownSearch($event)"
                 valueChangeEvent="keyup" style="background-color: #fff;">
      <dxi-button name="search" location="after" [options]="searchButtonOptions"></dxi-button>
    </dx-text-box>
    <span style="font-size:12px;">{{"LABEL_NO_SHAREHOLDER_SELECTED" | formatMessage}}</span>
  </div>
  <div name="InfoBox" style="border:1px solid #1e1d1d; margin:10px 5px; background-color:#555353; color:#FFF; height:100%; min-height:100%;" *ngIf="SelectedPA != null">
    <div style="height:80px; padding:5px; margin-bottom:5px; display:flex; flex-grow:1; flex-direction:column; background-color: #313030; color: #FFF;">
      <div style="display:flex; height:40px; width:100%;">
        <div id="BusinessName" style="font-size:24px; text-transform:uppercase; font-weight:bold;margin:auto 0; text-overflow:ellipsis;overflow:hidden;white-space:nowrap;" [style.color]="ShareholderForeground">
          {{SelectedPA.BusinessName}}
        </div>
        <dx-tooltip target="#BusinessName"
                    position="right"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false">
          <div *dxTemplate="let data = data of 'content'">
            {{SelectedPA.BusinessName}}
          </div>
        </dx-tooltip>
      </div>
      <div style="display:flex; height:40px; width:100%;">
        <div style="font-size:18px;text-transform:uppercase; font-weight:bold;margin:auto 0; color:#FFF; display:flex; flex-direction:row;">
          <div>
            <img *ngIf="capabilities.CanEditShareholderType" id="ShareholderTypeImg" [src]="ShareholderTypeImgUrl" style="width:40px; height:40px; object-fit:contain; cursor:pointer"
                 (click)="capabilities.CanEditShareholderType ? changeShareholderType() : false" />
            <img *ngIf="!capabilities.CanEditShareholderType" id="ShareholderTypeImg" [src]="ShareholderTypeImgUrl" style="width:40px; height:40px; object-fit:contain;"/>
            <dx-tooltip target="#ShareholderTypeImg" *ngIf="SelectedPA.AssociatedOn"
                        position="bottom"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        [hideOnOutsideClick]="false">
              <div *dxTemplate="let data = data of 'content'">
                {{SelectedPA.AssociatedOn | date: 'dd/MM/yyyy'}}
              </div>
            </dx-tooltip>
          </div>
          <div style="margin:auto 0;text-align:left">{{SelectedPA.ShareholderTypeDescr}}<br /><div style="font-size:12px; text-transform:none; text-overflow:ellipsis;overflow:hidden;white-space:nowrap;">{{ShareholderTypeDescrText}}</div></div>
        </div>
      </div>
    </div>
    <dx-scroll-view [scrollByContent]="true"
                    [scrollByThumb]="true"
                    [bounceEnabled]="false"
                     [useNative]="true">
      <div style="max-height:350px">
        <div class='info-line' [ngClass]="configs.CSIDVisible?'':'hidden'">
          <div class="info-label">{{configs.CSIDText}}</div>
          <div class="info-data">
            <div class="cell-label-container shareholder-type-cell">{{SelectedPA.CSID}}</div>
          </div>
        </div>
        <div class='info-line' [ngClass]="configs.CDGVisible?'':'hidden'">
          <div class="info-label">{{configs.CDGText}}</div>
          <div class="info-data">
            <div class="cell-label-container cdg-type-cell">{{SelectedPA.CDG}}</div>
          </div>
        </div>
        <div class='info-line' [ngClass]="configs.CertificatesDeliveryVisible?'':'hidden'">
          <div class="info-label">Certificato</div>
          <div class="info-data">
            <div class="cell-label-container certificate-type-cell">{{SelectedPA.HasCertification | BoolToYesNoLocalized | uppercase }}</div>
          </div>
        </div>
        <div *ngIf="capabilities.CanEditShareholderZone" class="info-line" [ngClass]="configs.ZonaVisible?'':'hidden'" style="cursor:pointer"
             (click)="capabilities.CanEditShareholderZone ? changeShareholderZone() : false">
          <div class="info-label">{{configs.ZonaText}}</div>
          <div class="info-data text-only">{{SelectedPA.DelegationZone}}</div>
        </div>
        <div *ngIf="!capabilities.CanEditShareholderZone" class="info-line" [ngClass]="configs.ZonaVisible?'':'hidden'">
          <div class="info-label">{{configs.ZonaText}}</div>
          <div class="info-data text-only">{{SelectedPA.DelegationZone}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.GruppoVisible?'':'hidden'">
          <div class="info-label">{{configs.GruppoText}}</div>
          <div class="info-data text-only">{{SelectedPA.DelegationGroup}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.SharesVisible?'':'hidden'" *ngIf="capabilities.CanViewShares">
          <div class="info-label">{{configs.SharesLabel}}</div>
          <div class="info-data">
            <div class="cell-label-container shares-type-cell">{{SelectedPA.NoS | number:'1.0-2'}} <pre>{{configs.SharesText}}</pre></div>
          </div>
        </div>
        <div class="info-line" [ngClass]="configs.SharesBVisible && configs.UseNoSBAndNoSC ?'':'hidden'" *ngIf="capabilities.CanViewShares">
          <div class="info-label">{{configs.SharesBLabel}}</div>
          <div class="info-data">
            <div class="cell-label-container shares-type-cell">{{SelectedPA.NoSB | number:'1.0-2'}} <pre>{{configs.SharesBText}}</pre></div>
          </div>
        </div>
        <div class="info-line" [ngClass]="configs.SharesCVisible && configs.UseNoSBAndNoSC?'':'hidden'" *ngIf="capabilities.CanViewShares">
          <div class="info-label">{{configs.SharesCLabel}}</div>
          <div class="info-data">
            <div class="cell-label-container shares-type-cell">{{SelectedPA.NoSC | number:'1.0-2'}} <pre>{{configs.SharesCText}}</pre></div>
          </div>
        </div>
        <div class="info-line" [ngClass]="configs.SharesDVisible && configs.UseNoSBAndNoSC?'':'hidden'" *ngIf="capabilities.CanViewShares">
          <div class="info-label">{{configs.SharesDLabel}}</div>
          <div class="info-data">
            <div class="cell-label-container shares-type-cell">{{SelectedPA.NoSD | number:'1.0-2'}} <pre>{{configs.SharesDText}}</pre></div>
          </div>
        </div>
        <div class="info-line" [ngClass]="configs.CFPIVAVisible?'':'hidden'">
          <div class="info-label">{{configs.CFPIVAText}}</div>
          <div class="info-data text-only">{{SelectedPA.FiscalCode}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.BirthDateVisible?'':'hidden'">
          <div class="info-label">{{configs.BirthDateText}}</div>
          <div class="info-data text-only">{{SelectedPA.BirthDate | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="info-line" [ngClass]="configs.BirthPlaceVisible?'':'hidden'">
          <div class="info-label">{{configs.BirthPlaceText}}</div>
          <div class="info-data text-only">{{SelectedPA.BirthPlace}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.AddressVisible?'':'hidden'">
          <div class="info-label">{{configs.AddressText}}</div>
          <div class="info-data text-only">{{SelectedPA.Address}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.ZipCodeVisible?'':'hidden'">
          <div class="info-label">{{configs.ZipCodeText}}</div>
          <div class="info-data text-only">{{SelectedPA.ZipCode}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.CityVisible?'':'hidden'">
          <div class="info-label">{{configs.CityText}}</div>
          <div class="info-data text-only">{{SelectedPA.City}}</div>
        </div>

        <div class="info-line" [ngClass]="configs.ProvinceVisible?'':'hidden'">
          <div class="info-label">{{configs.ProvinceText}}</div>
          <div class="info-data text-only">{{SelectedPA.StateProvince}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.CustomField1Visible?'':'hidden'">
          <div class="info-label">{{configs.CustomField1Text}}</div>
          <div class="info-data text-only">{{SelectedPA.CustomField01}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.CustomField2Visible?'':'hidden'">
          <div class="info-label">{{configs.CustomField2Text}}</div>
          <div class="info-data text-only">{{SelectedPA.CustomField02}}</div>
        </div>
        <div class="info-line" [ngClass]="configs.CustomField3Visible?'':'hidden'">
          <div class="info-label">{{configs.CustomField3Text}}</div>
          <div class="info-data text-only">{{SelectedPA.CustomField03}}</div>
        </div>
        <!--<div class="info-line" [ngClass]="configs.ShareholderDocVisible?'':'hidden'">
    <div class="info-label">{{configs.ShareholderDocText}}</div>
    <div class="info-data text-only">todo</div>
  </div>-->

        <div class="info-line" [ngClass]="configs.CategoriesVisible?'':'hidden'">
          <div class="info-label">{{configs.CategoriesText}}</div>
          <div class="info-data text-only">
            <div *ngFor="let cat of SelectedPA.Categories">
              {{cat.Descr}}
            </div>
          </div>
        </div>

        <div class="info-line" [ngClass]="configs.AgencyVisible?'':'hidden'">
          <div class="info-label">{{configs.AgencyText}}</div>
          <div class="info-data text-only">
            {{SelectedPA.AgencyDescr}} <i id="AgencyInfoIcon" class="dx-icon-info"></i>
            <dx-popover target="#AgencyInfoIcon"
                        position="right"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend">
              <div *dxTemplate="let data = model of 'content'">
                <table class="agency">
                  <tbody>
                    <tr>
                      <th>{{configs.AgencyText}}</th>
                      <td>{{SelectedPA.AgencyDescr}}</td>
                    </tr>
                    <tr>
                      <th>{{"LABEL_CODE" | formatMessage}}</th>
                      <td>{{SelectedPA.AgencyCode}}</td>
                    </tr>
                    <tr>
                      <th>{{"LABEL_RESPONSABILE" | formatMessage}}</th>
                      <td>{{SelectedPA.AgencyManager}}</td>
                    </tr>
                    <tr>
                      <th>{{"LABEL_ADDRESS" | formatMessage}}</th>
                      <td>{{SelectedPA.AgencyAddress}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </dx-popover>
          </div>
        </div>
      </div>
    </dx-scroll-view>
    <div style="padding: 0px 5px;text-align:left;" [ngClass]="SelectedPA.Vip || SelectedPA.STVip ? '' : 'hidden' ">
      <div style="font-size:12px; margin-bottom:3px;">Note di ingresso</div>
      <dx-scroll-view [scrollByContent]="true"
                      [scrollByThumb]="true"
                      [bounceEnabled]="false" *ngIf="SelectedPA.Vip" [ngClass]="SelectedPA.Vip ? '' : 'hidden' ">
        <div style="min-height:50px; max-height:100px;text-align:left; margin-bottom:5px; border-radius:5px; padding:5px;" [style.background-color]="SelectedPA.VipNoteStyle.Background" [style.color]="SelectedPA.VipNoteStyle.Foreground" [style.font-size]="SelectedPA.VipNoteStyle.FontSize">
          {{SelectedPA.VipNote}}
        </div>
      </dx-scroll-view>
      <dx-scroll-view [scrollByContent]="true"
                      [scrollByThumb]="true"
                      [bounceEnabled]="false" *ngIf="SelectedPA.STVip" [ngClass]="SelectedPA.STVip ? '' : 'hidden' ">
        <div style="min-height:50px; max-height:100px;text-align:left; margin-bottom:5px; border-radius:5px; padding:5px;" [style.background-color]="SelectedPA.STVipNoteStyle.Background" [style.color]="SelectedPA.STVipNoteStyle.Foreground" [style.font-size]="SelectedPA.STVipNoteStyle.FontSize">
          {{SelectedPA.STVipNote}}
        </div>
      </dx-scroll-view>
    </div>
  </div>
</div>
<asse-shareholder-type-selector [selectedType]="SelectedPA?.STID"></asse-shareholder-type-selector>
<asse-shareholder-zone-selector [selectedZone]="SelectedPA?.DelegationZone"></asse-shareholder-zone-selector>
