import { Component, OnInit, NgModule, ViewChild, AfterViewInit } from '@angular/core';
import {  MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { DxTextBoxModule,   DxPopupModule, DxTextBoxComponent } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';
import { isNullOrUndefined, isNullOrWhiteSpace } from '../../utils/utils';




export enum MessageBoxCodiceSbloccoResult {
  YES,
  NO,
  OK,
  CANCEL
}


@Component({
    selector: 'app-codice-sblocco',
    templateUrl: './codice-sblocco.component.html',
    styleUrls: ['./codice-sblocco.component.scss']
})
/** codice-sblocco component*/
export class CodiceSbloccoComponent implements OnInit, AfterViewInit {

  ngAfterViewInit(): void {

    this.setFocusBarcode();

  }
  ngOnInit(): void {
    this.setFocusBarcode();
  }

  closeText: string = formatMessage('LABEL_CONFERMA', "");

  codice: string='';

  @ViewChild('textBoxCodice') textBox: DxTextBoxComponent;

  setFocusBarcode() {
    try {
      if (!isNullOrUndefined(this.textBox)) {
        this.textBox.instance.focus();
      }
      else {
        setTimeout(() => {
          let txtBox = $('#textBoxCodice')
          if (txtBox && txtBox.length > 0) {
            let txtInput = txtBox[0].getElementsByTagName('input');
            if (txtInput && txtInput.length > 0) {
              txtInput[0].focus();
            }
          }

        }, 500);
      }
    } catch (e) {
      console.log(e);
    }
  }


  checkLostFocusBarcode(e) {
      this.setFocusBarcode();
  }

  async CodeInserted() {
    if (isNullOrWhiteSpace(this.codice))
      return;
    this.onCloseClick();

  }
    /** codice-sblocco ctor */
  constructor(private dialogRef: MatDialogRef<CodiceSbloccoComponent>
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService
    , private toasterService: ToastrService, private errorService: ErrorService, private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);

  }

  async onCloseClick() {
    if (this.codice == null || this.codice.trim() == '')
      this.dialogRef.close({ result: MessageBoxCodiceSbloccoResult.CANCEL });
    else {

      this.codice = this.codice.trim().replace(/\s+/g, ' ').replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });;

      this.dialogRef.close({ result: MessageBoxCodiceSbloccoResult.OK, codice: this.codice });
    }
  }

}



@NgModule({
    declarations: [
        CodiceSbloccoComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
        DxTextBoxModule,
      DxPopupModule,
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class CodiceSbloccoModule {

}
