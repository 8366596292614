import { NgModule, } from '@angular/core';
import { ApplicationPipesModule } from '../../shared/pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DxListModule, DxDrawerModule, DxToolbarModule, DxDataGridModule, DxButtonModule, DxPopupModule, DxToastModule} from 'devextreme-angular';

import { ImportPageComponent } from './import-page.component';
import { ImportPageRoutingModule } from './import-routing.module';
import { ImportDelegateComponent } from '../../shared/components/import/import-delegate/import-delegate.component';
import { ImportLegalRappresentativeComponent } from '../../shared/components/import/import-legal-rappresentative/import-legal-rappresentative.component';
import { ImportShareholderComponent } from '../../shared/components/import/import-shareholder/import-shareholder.component';
import { ShareholderComponent } from '../../shared/components/import/shareholder/shareholder.component';
import { ShareholderTypeComponent } from '../../shared/components/import/shareholder-type/shareholder-type.component';
import { ImportMontetitoliComponent } from '../../shared/components/import/import-montetitoli/import-montetitoli.component';
import { ImportCategorieComponent } from '../../shared/components/import/import-categorie/import-categorie.component';
import { TestImportPrincipalComponent } from '../../shared/components/import/test-import-principal/test-import-principal.component';

@NgModule({
  declarations: [
    
    ImportPageComponent,
    ImportCategorieComponent,
    ImportDelegateComponent,
    ImportLegalRappresentativeComponent,
    ImportShareholderComponent,
    ImportMontetitoliComponent,
    ShareholderComponent,
    ShareholderTypeComponent,
    TestImportPrincipalComponent
    
  ],
  imports: [
    ImportPageRoutingModule,
    BrowserModule,
    ApplicationPipesModule,
    CommonModule,
    DxListModule,
    DxToolbarModule,
    DxDataGridModule,
    DxButtonModule,
    DxPopupModule,
    DxToastModule,
    DxDrawerModule
  ],

})

export class ImportPageModule {
}
