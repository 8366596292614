export class PollDetail {

    PDID: number;
    PEID: number;
    Descr: string;
    TDescr: string;
    SortN: number;
    AddedOnTheFly: boolean;
    IsSpecial: boolean;
    CountIgnore: boolean;
    ElectronicReadingTag: string;
    TelevoterMapping: number;
    IsHidden: boolean;
    IsDeactivated: boolean;
    IsReconciliated: boolean;
    LongDescr: string;
    GroupDescr: string;
    FromList: boolean;
    ExternalMapping: string;
    FavAstContr: string; // F C A
  IsPrintHidden: boolean;
  IsListVote: boolean;

    IsSelected: boolean;
    get Icon(): string { return "/Assemblea.WpfApplication;component/Resources/Images/PollPage/Detail.png"; }
    IsChanged: boolean;
    get Key(): string { return "pdid" + this.PEID; }
    get Name(): string { return this.Descr; } set Name(value) { this.Descr = value; }
  get Number() { return this.FavAstContr; } set Number(value) { this.FavAstContr = value; }

    Modified: boolean=false;
  Maggioranza: boolean = false;
  MaggioranzaOnline: boolean = false;

  constructor() { }
}
