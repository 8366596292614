import { Component, HostListener, ViewChild, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import {  IdentityService, SignalrService, NotificationService, ConnectionState } from '../../shared/services';
import {  DxTextBoxComponent } from 'devextreme-angular';
import { Subscription, timer } from 'rxjs';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../shared/utils/utils';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../shared/services/error.service';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { vPollDetail } from '../../shared/models/assemblea/vPollDetail';
import { PollingBoothOperationStatus } from '../../shared/models/assemblea/PollingBoothOperationStatus';
import { RemoteCommandTypes, RemoteCommandNotification, NotificationType } from '../../shared/models/notification.model';
import { PollingBoothStatus } from '../../shared/models/assemblea/enums';
import { MessageBoxPollingBoothResult, PollingBoothSelectorComponent, PollingBoothDialogData } from '../../shared/components/polling-booth-selector/polling-booth-selector.component';
import { MessageBoxDialogData, MessageBoxImage, MessageBoxButtons, MessageBoxResult, MessageBoxComponent } from '../../shared/components/message-box/message-box.component';
import { vPotentialAttendant } from '../../shared/models/assemblea/vPotentialAttendant';
import { CapabilityItems } from '../../shared/models/assemblea/CapabilityItems';
import { SysConfigItems } from '../../shared/models/assemblea/SysConfigItems';

@Component({
  selector: 'app-polling-booth-director-page',
  templateUrl: './polling-booth-director-page.component.html',
  styleUrls: ['./polling-booth-director-page.component.scss']
})
/** polling-booth-director-page component*/
export class PollingBoothDirectorPageComponent implements AfterViewInit, OnInit, OnDestroy {

  public StatoPollingBooth: { ID: number, Name: string }[] = [{ ID: 0, Name: "NONE" },
    { ID: 1, Name: "SEARCH_CODE" },
    { ID: 2, Name: "CLOSED" },
    { ID: 3, Name: "CLOSED_WITHOUT_VOTING" },
    { ID: 4, Name: "TIMEOUT_VOTING" },
    { ID: 5, Name: "VOTING" },
    { ID: 6, Name: "VOTE_SENT" },
    { ID: 7, Name: "KEEP_ALIVE" },
    { ID: 8, Name: "WAITING_NEXT_SCAN" },
    { ID: 9, Name: "LOGGED_IN" },
    { ID: 10, Name: "DUPLICATED" },
    { ID: 11, Name: "DISCONNECTED" },
  
  ];

  public PollingBoothStatusValue(key: number) { return PollingBoothStatus[key]; }

  ngAfterViewInit(): void {

    this.setFocusBarcode();
  }


  @ViewChild('textBoxBarcode') textBox: DxTextBoxComponent;

  setFocusBarcode() {
    try {
      if (!isNullOrUndefined(this.textBox))
        this.textBox.instance.focus();
    } catch (e) {
      console.log(e);
    }
  }


  Barcode: string = '';
  lastBarcode: string = '';
  lastAttendant: string = '';

  selectedPID: number[] = [];

  countSeconds: Subscription;
  tick = 1000;

  CreateEntranceFromCabina: boolean = false;

  _ActualPollingBoothStatus: PollingBoothOperationStatus[] = [];
  get ActualPollingBoothStatus(): PollingBoothOperationStatus[] {
    return this._ActualPollingBoothStatus;
  }
  set ActualPollingBoothStatus(value: PollingBoothOperationStatus[]) {

    if (this.countSeconds != null)
      this.countSeconds.unsubscribe();
    this.countSeconds = null;

    this._ActualPollingBoothStatus = isNullOrUndefined(value) ? [] : value;

    this.countSeconds = timer(0, this.tick)
      .subscribe(() => {
        if (!isNullOrUndefined(this._ActualPollingBoothStatus)) {
          this._ActualPollingBoothStatus.forEach(value => value.CustomTimer = Math.round((new Date().getTime() - new Date(value.LastUpdate).getTime()) / 1000));
        }
      });

  }


  screenWidth: number;
  resizableWidth: number = 350;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    let space = this.screenWidth;
    this.resizableWidth = space * 45 / 100;

  }


  ngOnInit() { }

  subscriptions: Subscription = new Subscription();
  //private timerReloadStatus: NodeJS.Timer = null;
  private timerHideSendedPoll: any = null;
  newCab: string = '';

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    if (this.timerHideSendedPoll != null) {
      window.clearTimeout(this.timerHideSendedPoll);
      this.timerHideSendedPoll = null;
    }

    if (this.countSeconds != null)
      this.countSeconds.unsubscribe();
    this.countSeconds = null;
  }

  loadConfig() {

    this.CreateEntranceFromCabina = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_CreateEntranceFromCabina, this.CreateEntranceFromCabina);
  }

  /** polling-booth-director-page ctor */
  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService, private notificationService: NotificationService
    , private identityService: IdentityService, public sysConfigService: SysConfigService, private signalrService: SignalrService) {
    this.onResize();

    
    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          case RemoteCommandTypes.PollingBoothStatusUpdated:
            this.ActualPollingBoothStatus = await this.assembleaService.GetPollingBoothStatus();
            break;
          case RemoteCommandTypes.CabinaStartSession:

            this.newCab = remoteCommand.Message;
            if (this.timerHideSendedPoll != null) {
              window.clearTimeout(this.timerHideSendedPoll);
              this.timerHideSendedPoll = null;
            }

            this.timerHideSendedPoll = window.setTimeout(async () => {

              this.newCab = '';

            }, 8000);

            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });

    let SignalRConnSub = this.signalrService.connectionState$.subscribe(async (state) => {
      if (state == ConnectionState.Connected) {
        this.ActualPollingBoothStatus = await this.assembleaService.GetPollingBoothStatus();
      }
    });
    this.subscriptions.add(SignalRConnSub);

    //clearTimeout(this.timerReloadStatus);
    //this.timerReloadStatus = null;

    //this.timerReloadStatus = setInterval(async () => {

    //  this.ActualPollingBoothStatus = await this.assembleaService.GetPollingBoothStatus();

    //}, 35000);
  }

  async BarcodeInserted() {

    if (isNullOrWhiteSpace(this.Barcode))
      return;

    this.lastBarcode = '';
    this.lastAttendant = '';
    try {

      this.ngxService.start();

      if (this.CreateEntranceFromCabina) {
        var exactMatch: boolean = false;
        var barcodeIngressoAnnullato: boolean = false;
        var list: vPotentialAttendant[] = await (await this.assembleaService.findPotentialAttendantByCodes(this.Barcode, false)).Results;
        if (list.length == 1) //se la ricerca mi da un solo risultato è quello che sto cercando
        {
          if (!isNullOrWhiteSpace(list[0].BarcodeIngressoFisico)) {//se il risultato ha il barcode del fisico allora faccio la ricerca per barcode
            if (!(list[0].BarcodeIngressoFisico === this.Barcode)) {
              this.Barcode = list[0].BarcodeIngressoFisico;
              await this.assembleaService.SetZoneAndCategoryIfEnabled(list[0].PAID);
            }
          }
          else {
            var caps = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoMovement });


            if (caps) {
              var newBC = await this.assembleaService.EnteringFromPollClient(list[0].PAID);
              if (!isNullOrWhiteSpace(newBC))
                this.Barcode = newBC;
            }
          }
        }
      }

      let det: vPollDetail[] = await this.assembleaService.LoadActualPollDetailsByCode(this.Barcode);

      if (det == null || det.length == 0) {
        this.ngxService.stop();
        this.errorService.showErrorMessageDetail("L'utente corrente non può votare", "In questo momento non sono presenti votazioni per le quali l'utente possa votare", "Attenzione");
        return;
      }

      let AD = await this.assembleaService.LoadAccountingDetailsByCode(this.Barcode);
      if (AD == null || AD.length == 0) {
        this.ngxService.stop();
        this.errorService.showErrorMessageDetail("L'utente corrente non può votare", "Al momento l'utente non è abilitato a votare", "Attenzione");
        return;
      }

      let cabineDisponibili = this.ActualPollingBoothStatus.filter(x => x.Status == PollingBoothStatus.WAITING_NEXT_SCAN)

      if (isNullOrUndefined(cabineDisponibili) || cabineDisponibili.length == 0) {
        this.ngxService.stop();
        this.errorService.showErrorMessageDetail("Non ci sono cabine disponibili", "In questo momento non è disponibile nessuna cabina per il voto", "Attenzione");
        return;
      }

      let PID: number = -1;
      if (cabineDisponibili.length > 1) {
        this.ngxService.stop();
        let data: PollingBoothDialogData = new PollingBoothDialogData({
          pollingBooth: cabineDisponibili
        });
        let dialog = this.dialog.open(PollingBoothSelectorComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (isNullOrUndefined(result) || result.result != MessageBoxPollingBoothResult.OK)
          return;
        else {
          PID = result.PID;
        }
      }

      this.ngxService.start();

      this.lastAttendant = !isNullOrUndefined(AD) && AD.length > 0 && !isNullOrUndefined(AD[0].vPotentialAttendant) ? AD[0].vPotentialAttendant.BusinessName : '';
      this.lastBarcode = await this.assembleaService.SendToPollingBooth(this.Barcode, PID);


    } catch (e) {

      let dialog = this.errorService.showErrorMessage(e, "Errore nella ricerca del partecipante");
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    } finally { this.Barcode = ''; this.ngxService.stop();}
  }


  async LogoutCabina() {

    if (isNullOrUndefined(this.selectedPID) || this.selectedPID.length <= 0)
      return;

    let cabina = this.ActualPollingBoothStatus.find(x => x.PID == this.selectedPID[0])
    if (isNullOrUndefined(cabina))
      return;

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Logout Cabina',
      message: `Sicuro di voler procedere con il logout di '${cabina.Cabina}'`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Question
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return; //cancel
    }

    this.assembleaService.LogoutPollingBooth(cabina.PID);

  }

  public getBgColorStato(data: PollingBoothOperationStatus): string {
    if (data.Status == PollingBoothStatus.CLOSED) return '#000000';
    if (data.Status == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.DISCONNECTED) return '#000000';
    if (data.Status == PollingBoothStatus.DUPLICATED) return '#FF6633';
    if (data.CustomTimer > 60) return '#FFff33'
    if (data.Status == PollingBoothStatus.LOGGED_IN) return '#FFFF99';
    if (data.Status == PollingBoothStatus.SEARCH_CODE) return '#FFFF99';
    if (data.Status == PollingBoothStatus.TIMEOUT_VOTING) return '#FF6633';
    if (data.Status == PollingBoothStatus.VOTE_SENT) return '#00CCFF';
    if (data.Status == PollingBoothStatus.VOTING) return '#FFFF99';
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN) return '#66FF66';
    return 'none';
  }
  public getColorStato(data: PollingBoothOperationStatus): string {
    if (data.Status == PollingBoothStatus.CLOSED) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.DISCONNECTED) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.DUPLICATED) return '#000000';
    if (data.CustomTimer > 60) return '#000000'
    if (data.Status == PollingBoothStatus.LOGGED_IN) return '#000000';
    if (data.Status == PollingBoothStatus.SEARCH_CODE) return '#000000';
    if (data.Status == PollingBoothStatus.TIMEOUT_VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.VOTE_SENT) return '#000000';
    if (data.Status == PollingBoothStatus.VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN) return '#000000';
    return 'none';
  }
 
  public getBgColorPreviousStato(data: PollingBoothOperationStatus): string {
    if (data.PreviousStatus == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#FF6633'
    if (data.PreviousStatus == PollingBoothStatus.TIMEOUT_VOTING) return '#FF6633'
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN && data.PreviousStatus == PollingBoothStatus.LOGGED_IN) return '#FF6633'
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN && data.PreviousStatus == PollingBoothStatus.VOTING) return '#FF6633'
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN && data.PreviousStatus == PollingBoothStatus.SEARCH_CODE) return '#FFFF99'

    if (data.Status == PollingBoothStatus.CLOSED) return '#000000';
    if (data.Status == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.DISCONNECTED) return '#000000';
    if (data.Status == PollingBoothStatus.DUPLICATED) return '#FF6633';
    if (data.CustomTimer > 60) return '#FFff33'
    if (data.Status == PollingBoothStatus.LOGGED_IN) return '#FFFF99';
    if (data.Status == PollingBoothStatus.SEARCH_CODE) return '#FFFF99';
    if (data.Status == PollingBoothStatus.TIMEOUT_VOTING) return '#FF6633';
    if (data.Status == PollingBoothStatus.VOTE_SENT) return '#00CCFF';
    if (data.Status == PollingBoothStatus.VOTING) return '#FFFF99';
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN) return '#66FF66';
    return 'none';
  }
  public getColorPreviousStato(data: PollingBoothOperationStatus): string {
    if (data.PreviousStatus == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#000000'
    if (data.PreviousStatus == PollingBoothStatus.TIMEOUT_VOTING) return '#000000'
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN && data.PreviousStatus == PollingBoothStatus.LOGGED_IN) return '#000000'
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN && data.PreviousStatus == PollingBoothStatus.VOTING) return '#000000'
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN && data.PreviousStatus == PollingBoothStatus.SEARCH_CODE) return '#000000'

    if (data.Status == PollingBoothStatus.CLOSED) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.DISCONNECTED) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.DUPLICATED) return '#000000';
    if (data.CustomTimer > 60) return '#000000'
    if (data.Status == PollingBoothStatus.LOGGED_IN) return '#000000';
    if (data.Status == PollingBoothStatus.SEARCH_CODE) return '#000000';
    if (data.Status == PollingBoothStatus.TIMEOUT_VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.VOTE_SENT) return '#000000';
    if (data.Status == PollingBoothStatus.VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN) return '#000000';
    return 'none';
  }

  public getBgColorAggiornamento(data: PollingBoothOperationStatus): string {
    if (data.Status == PollingBoothStatus.CLOSED) return '#000000';
    if (data.Status == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.DISCONNECTED) return '#000000';
    if (data.Status == PollingBoothStatus.DUPLICATED) return '#FF6633';
    if (data.CustomTimer > 60) return '#FF0000'
    if (data.Status == PollingBoothStatus.LOGGED_IN) return '#FFFF99';
    if (data.Status == PollingBoothStatus.SEARCH_CODE) return '#FFFF99';
    if (data.Status == PollingBoothStatus.TIMEOUT_VOTING) return '#FF6633';
    if (data.Status == PollingBoothStatus.VOTE_SENT) return '#00CCFF';
    if (data.Status == PollingBoothStatus.VOTING) return '#FFFF99';
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN) return '#66FF66';
    return 'none';
  }

  public getColorAggiornamento(data: PollingBoothOperationStatus): string {
    if (data.CustomTimer > 60) return '#FFFFFF'
    if (data.Status == PollingBoothStatus.CLOSED) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.CLOSED_WITHOUT_VOTING) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.DISCONNECTED) return '#FFFFFF';
    if (data.Status == PollingBoothStatus.DUPLICATED) return '#000000';
    if (data.Status == PollingBoothStatus.LOGGED_IN) return '#000000';
    if (data.Status == PollingBoothStatus.SEARCH_CODE) return '#000000';
    if (data.Status == PollingBoothStatus.TIMEOUT_VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.VOTE_SENT) return '#000000';
    if (data.Status == PollingBoothStatus.VOTING) return '#000000';
    if (data.Status == PollingBoothStatus.WAITING_NEXT_SCAN) return '#000000';
    return 'none';
  }

}

