export class ImportPrincipalRequest {
  pUsername: string;
  pName: string;
  pPwd: string;
  pRoleID: number;
  pIsPreview: boolean;
  pCategory: string;
  pZone: string;

  constructor(init?: Partial<ImportPrincipalRequest>) {
    Object.assign(this, init);
  }
}
