import { Component, ViewChild, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { IdentityService, NotificationService } from '../../shared/services';
import { ErrorService } from '../../shared/services/error.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vPollDetail } from '../../shared/models/assemblea/vPollDetail';
import { PollEntity } from '../../shared/models/assemblea/PollEntity';
import { PollDetail } from '../../shared/models/assemblea/PollDetail';
import { AccountingDetail } from '../../shared/models/assemblea/AccountingDetail';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../shared/utils/utils';
import { DxTextBoxComponent } from 'devextreme-angular';
import { SysConfigItems } from '../../shared/models/assemblea/SysConfigItems';
import { DomSanitizer } from '@angular/platform-browser';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { Subscription, timer } from 'rxjs';
import { CapabilityItems, vPotentialAttendant } from '../../shared/models/assemblea/assemblea-models';
import { PollEntity2PeidStid } from '../../shared/models/assemblea/PollEntity2PeidStid';
import { ShareholderTypeAction, PollingBoothStatus, EPollVotingSessionsEsito } from '../../shared/models/assemblea/enums';
import { MessageBoxResult, MessageBoxComponent, MessageBoxImage, MessageBoxButtons, MessageBoxDialogData } from '../../shared/components/message-box/message-box.component';
import { PollingBoothVoteResult } from '../../shared/models/PollingBoothVoteResult';
import { VotoDisgiuntoDialogData, VotoDisgiuntoSelectorComponent, MessageBoxVotoDisgiuntoResult } from '../../shared/components/voto-disgiunto-selector/voto-disgiunto-selector.component';
import { NuovaPreferenzaComponent, MessageBoxNuovaPreferenzaResult } from '../../shared/components/nuova-preferenza/nuova-preferenza.component';
import { RemoteCommandNotification, RemoteCommandTypes, NotificationType } from '../../shared/models/notification.model';
import { EPollVotingSessions } from '../../shared/models/assemblea/EPollVotingSessions';
import { CodiceSbloccoComponent, MessageBoxCodiceSbloccoResult } from '../../shared/components/codice-sblocco/codice-sblocco.component';

interface PollVoteList<T> {
  [K: number]: T;
}

type CountPerPEID = { peid: number; count: number };

@Component({
  selector: 'app-polling-booth-page',
  templateUrl: './polling-booth-page.component.html',
  styleUrls: ['./polling-booth-page.component.scss']
})



/** PollingBoothPage component*/
export class PollingBoothPageComponent implements AfterViewInit, OnDestroy {

  ngAfterViewInit(): void {

    this.setFocusBarcode();

    if (!isNullOrUndefined(this.identityService.user)) {
      console.log('WAITING_NEXT_SCAN')
      this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
    }

  }


  @ViewChild('textBoxBarcode') textBox: DxTextBoxComponent;

  setFocusBarcode() {
    try {
      if (!isNullOrUndefined(this.textBox)) {
        this.textBox.instance.focus();
      }
      else {
        setTimeout(() => {
          let txtBox = $('#textBoxBarcode')
          if (txtBox && txtBox.length > 0) {
            let txtInput = txtBox[0].getElementsByTagName('input');
            if (txtInput && txtInput.length > 0) {
              txtInput[0].focus();
            }
          }
                    
        }, 1000);
      }
    } catch (e) {
      console.log(e);
    }
  }


  get ProgressPercentage(): string {
    if (this.totalStep <= 1)
      return "0%";
    return Math.floor(((this.actualStep - 1) / (this.totalStep-1)) * 100) + '%';
  }

  get TimeProgress(): string {
    return Math.floor(this.counter) + '%';
  }

  get Sfondo(): any {
    return this._DomSanitizationService.bypassSecurityTrustUrl("url('data:image;base64," + this.SfondoConvocazione + "')"); 
    debugger;
  }
  /*utilizzato per l'img del logo dell'assemblea*/
  public get LogoConfigID(): number {
    return SysConfigItems.CompanyLogo;
  }


  @HostListener('window:beforeunload', ['$event'])
  async onUnload(e?) {
    console.log({ 'WINDOW UNLOAD EVENT': e });
    if (!isNullOrUndefined(this.identityService.user)) {

      if (isNullOrWhiteSpace(this.Code))
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      else
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED_WITHOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
    }

    return true;
  }

  subscriptions: Subscription = new Subscription();
  private timerKeepAliveStatus: any = null;

  ngOnDestroy(): void {

    console.log('DESTROY')

    if (this.timerKeepAliveStatus != null) {
      window.clearInterval(this.timerKeepAliveStatus);
      this.timerKeepAliveStatus = null;
    }

    if (!isNullOrUndefined(this.identityService.user)) {
      if (isNullOrWhiteSpace(this.Code)) {
        
        this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      }
      else {
        this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED_WITHOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      }
    }
    this.subscriptions.unsubscribe();
    if (this.countDown != null)
      this.countDown.unsubscribe();
    this.countDown = null;
  }

  cabNumber: string;


  loadConfig() {
    this.LogoConvocazione = this.sysConfigService.GetSysConfigValue(SysConfigItems.CompanyLogo, this.LogoConvocazione);
    this.FontColor = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_StaticTextColor, this.FontColor);
    this.SharesLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_SharesLabel, this.SharesLabel);
    this.SfondoConvocazione = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_BackgroundImage, this.SfondoConvocazione);
    this.PageTimerTotalSeconds = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_PageTimerSeconds, this.PageTimerTotalSeconds);
    this.LastPageTimerTotalSeconds = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_LastPageTimerSeconds, this.LastPageTimerTotalSeconds);

    this.EndPageTimerEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_EndPageTimerEnabled, this.EndPageTimerEnabled);
    this.EndPageTimerSeconds = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_EndPageTimerSeconds, this.EndPageTimerSeconds);
    this.ConfirmCodeEndPage = this.sysConfigService.GetSysConfigValue(SysConfigItems.ConfirmCodeEndPage, this.ConfirmCodeEndPage);

    this.LogAllEPollVotingSessions = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_LogAllEPollVotingSessions, this.LogAllEPollVotingSessions);

    this.ConfirmSpecialPref = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ConfirmSpecialPref, this.ConfirmSpecialPref);

    this.VotoDisgiuntoEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_VotoDisgiuntoEnabled, this.VotoDisgiuntoEnabled);

    this.CreateEntranceFromCabina = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_CreateEntranceFromCabina, this.CreateEntranceFromCabina);
    this.CancelEntryOnKO = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_CancelEntryOnKO, this.CancelEntryOnKO);

    this.ShowZoneMessage = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ShowZoneMessage, this.ShowZoneMessage);
    this.ShowCircoscrizioneMessage = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ShowCircoscrizioneMessage, this.ShowCircoscrizioneMessage);
    this.ShareholderEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ShareholderEnabled, this.ShareholderEnabled);
    this.SharesEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_SharesEnabled, this.SharesEnabled);

    this.ShowBirtDateCabina = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ShowBirtDateCabina, this.ShowBirtDateCabina);
    this.ShowNosCellCabina = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ShowNosCellCabina, this.ShowNosCellCabina);
    this.ShowRelIdCabina = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ShowRelIdCabina, this.ShowRelIdCabina);

    this.SwitchHeaderFooterNg = this.sysConfigService.GetSysConfigValue(SysConfigItems.SwitchHeaderFooterNg, this.SwitchHeaderFooterNg);

    this.ShowConfirmButtonInMiddle = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShowConfirmButtonInMiddle, this.ShowConfirmButtonInMiddle);
    this.BlinkingConfirmButton = this.sysConfigService.GetSysConfigValue(SysConfigItems.BlinkingConfirmButton, this.BlinkingConfirmButton);

    this.ShowIsSpecialInBottomRow = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShowIsSpecialInBottomRow, this.ShowIsSpecialInBottomRow);

    
  }

  /** PollingBoothPage ctor */
  constructor(private router: Router,
    private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService, private notificationService: NotificationService
    , private identityService: IdentityService, public sysConfigService: SysConfigService,
    public _DomSanitizationService: DomSanitizer) {
    //var link: any = "/polling-booth/identification-step";
    //this.router.navigate([link]);
    //this.LogoConvocazione = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyLogo).ActualValue;

    if (!isNullOrUndefined(this.identityService.user)) {
      this.cabNumber = `${this.identityService.user.FirstName} ${this.identityService.user.LastName}`;
    }
    else {
      this.cabNumber = '';
    }

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          case RemoteCommandTypes.CabinaStartSession:

            if (isNullOrWhiteSpace(this.Barcode) && isNullOrWhiteSpace(this.Code)) {
              this.Barcode = remoteCommand.Message;
              await this.BarcodeInserted();
            }

            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

    this.GetNumeroAzioniPerPEID = this.GetNumeroAzioniPerPEID.bind(this);
    this.GetNumeroSociPerPEID = this.GetNumeroSociPerPEID.bind(this);
    this.PollDetailsListSelection = this.PollDetailsListSelection.bind(this);

    this.onClickedGo = this.onClickedGo.bind(this);
    this.onClickedDisgiunto = this.onClickedDisgiunto.bind(this);
    this.onClickedConferma = this.onClickedConferma.bind(this);
    this.onClickedAnnulla = this.onClickedAnnulla.bind(this);
    this.onClickedIndietro = this.onClickedIndietro.bind(this);
    this.onClickedAnnullaVoto = this.onClickedAnnullaVoto.bind(this);
    this.getRiepilogoVoto = this.getRiepilogoVoto.bind(this);

    this.clearVar = this.clearVar.bind(this);

    this.onUnload = this.onUnload.bind(this);

    if (this.timerKeepAliveStatus != null) {
      window.clearInterval(this.timerKeepAliveStatus);
      this.timerKeepAliveStatus = null;
    }

    this.timerKeepAliveStatus = window.setInterval(async () => {

      if (!isNullOrUndefined(this.identityService.user)) {
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.KEEP_ALIVE, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      }

    }, 15000);

  }


  //#region PROPERTY
  //*******************
  SharesLabel: string = "Voti";
  LogoConvocazione: string = "";
  SfondoConvocazione: string = "";
  PageTimerTotalSeconds: number = 100;
  LastPageTimerTotalSeconds: number = 100;
  EndPageTimerEnabled: boolean = true;
  EndPageTimerSeconds: number = 10;

  ConfirmCodeEndPage: string = '';

  LogAllEPollVotingSessions: boolean = false;

  ConfirmSpecialPref: boolean = true;
  VotoDisgiuntoEnabled: boolean = false;
  CreateEntranceFromCabina: boolean = false;

  CancelEntryOnKO: boolean = false;

  ShowZoneMessage: boolean = false;
  ShowCircoscrizioneMessage: boolean = false;
  SharesEnabled: boolean = false;
  ShareholderEnabled: boolean = false;

  ShowRelIdCabina: boolean = false;
  ShowBirtDateCabina: boolean = false;
  ShowNosCellCabina: boolean = false;

  SwitchHeaderFooterNg: boolean = false;
  ShowConfirmButtonInMiddle: boolean = false;
  BlinkingConfirmButton: boolean = false;
  ShowIsSpecialInBottomRow: boolean = false;

  FontColor: string = "#000";
  countDown: Subscription;
  counter = 100;
  tick = 1000;

  totalStep = 1;

  Barcode: string = '';

  _Votazioni: PollEntity[] = [];
  Accounting: AccountingDetail[] = [];
  NumeroAzioni: number = 0;
  NumeroSoci: number = 0;
  NumeroAzioniPerPEID: CountPerPEID[] = [];
  NumeroSociPerPEID: CountPerPEID[] = [];
  riepilogoVoto: PollingBoothVoteResult[] = [];
  Code: string = '';
  BatchCode: string = '';
  votoDisgiuntoInCorso: boolean = false
  CurrentPAIDShareholed: number;

  votoConfermato: boolean = false;
  //SomeVoteAlreadyExpressed: boolean = false;
  esito: number = -1;
  esito_message: string = '';
  EnteredFromCabina: boolean = false;
  PAID_EnteredFromCabina: number = -1;
  CurrentPotentialAttendant: vPotentialAttendant=null;

  sessionStartTime: Date = null;

  _actualStep: number = 1;
  get actualStep(): number { return this._actualStep }
  set actualStep(value: number) {
    this._actualStep = value;
    this.counter = 100;
    if (this.countDown != null)
      this.countDown.unsubscribe();
    this.countDown = null;
    if (this._actualStep == 1) {
      this.setFocusBarcode();
    }
    else {
      if (!isNullOrUndefined(this.identityService.user)) {
        if (this._actualStep > 2) {

          this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
        }
        else {
          this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.LOGGED_IN, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
        }
      }
      this.counter = 100;

      if (this.actualStep == this.totalStep && this.totalStep > 1) {

        if (this.EndPageTimerEnabled) {

          this.countDown = timer(this.tick, this.tick)
            .subscribe(async () => {
              if (this.counter <= 0) {
                await this.clearVar();
              }
              else {
                this.counter = this.counter - (100 / this.EndPageTimerSeconds)
              }
            });
        }
      } else {

        this.countDown = timer(this.tick, this.tick)
          .subscribe(async () => {
            if (this.counter <= 0) {
              if (!isNullOrUndefined(this.identityService.user)) {
                this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.TIMEOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
              }
              if (!this.votoConfermato) {
                //notify({ message: 'Il tempo a sua disposizione è scaduto', position: 'center', height: '100px' }, 'warning', 6000)
                this.esito = 1;
                this.esito_message = "Il tempo a disposizione per concludere l'operazione di voto è scaduto";
                if (this.LogAllEPollVotingSessions) {
                  this.assembleaService.EndPollVotingSession(this.Accounting[0].PAID, EPollVotingSessionsEsito.TIMEOUT);
                }
                else {
                  this.assembleaService.LogPollVotingSession(new EPollVotingSessions({
                    PAID: this.Accounting[0].PAID,
                    Esito: EPollVotingSessionsEsito.TIMEOUT,
                    StartSession: this.sessionStartTime,
                    EndSession: await this.assembleaService.getServerDatetime()
                  }));
                }

                if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
                  await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
                    "INGRESSO DA CABINA - Timeout sessione", this.identityService.user.PID, false, true);

              }
              this.actualStep = this.totalStep;
              
              //TODO await this.clearVar();

            }
            else {
              if (this.actualStep == this.totalStep - 1)
                this.counter = this.counter - (100 / this.LastPageTimerTotalSeconds) //timer separato per pagina di riepilogo
              else
                this.counter = this.counter - (100 / this.PageTimerTotalSeconds)
            }
          });
      }
    }
  }



  private async clearVar() {
    this.actualStep = 1;
    this._Votazioni = [];
    this.Accounting = [];
    this.NumeroAzioni = 0;
    this.NumeroSoci = 0;
    this.NumeroAzioniPerPEID = [];
    this.NumeroSociPerPEID = [];
    this.riepilogoVoto = [];
    this.Code = '';
    this.BatchCode = '';
    this.votoDisgiuntoInCorso = false;
    this.CurrentPAIDShareholed = null;
    this.votoConfermato = false;
    this.esito = -1;
    this.esito_message = '';
    this.EnteredFromCabina = false;
    this.PAID_EnteredFromCabina = -1;
    this.sessionStartTime = null;
    
    //this.SomeVoteAlreadyExpressed = false;
    this.CurrentPotentialAttendant = null;
    if (!isNullOrUndefined(this.identityService.user)) {
      this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
    }
   }

  get CurrentAccounting(): AccountingDetail {
    if (isNullOrUndefined(this.CurrentPAIDShareholed)) return null;
    else return this.Accounting.find(x => x.PAID_Shareholder == this.CurrentPAIDShareholed);
  }


  get Votazioni(): PollEntity[] {

    if (this.votoDisgiuntoInCorso) {
      if (!isNullOrUndefined(this.CurrentAccounting))
        return this.CurrentAccounting.Votazioni;
      else return this._Votazioni;
    }

    else return this._Votazioni;

  }



  public get pdChecked(): number[] {
    let res: number[] = [];
    if (this.votazioneCorrente !== null) {
      let det: any[] = [];
      if (!this.ShowIsSpecialInBottomRow || this.votazioneCorrente.AllowExternalItem)
        det = this.votazioneCorrente.PollDetails;
      else
        det = this.votazioneCorrente.PollDetails.filter(x => !x.IsSpecial);
      for (let i = 0; i < det.length; i++) {
        let item: any;
        item = det[i];
        if (item.IsChecked) {
          res.push(item.PDID);
        }
      }
    }
    return res;
  }

  public get pdCheckedSpecial(): number[] {
    let res: number[] = [];
    if (this.votazioneCorrente !== null) {
      let det: any[] = [];
      det = this.votazioneCorrente.PollDetails.filter(x => x.IsSpecial);
      for (let i = 0; i < det.length; i++) {
        let item: any;
        item = det[i];
        if (item.IsChecked) {
          res.push(item.PDID);
        }
      }
    }
    return res;
  }

  public set pdChecked(value: number[]) {
    //if (this.PollDetails !== null) {
    //  let det: any[] = [];
    //  det = this.PollDetails;
    //  for (let i = 0; i < det.length; i++) {
    //    let item: any;
    //    item = det[i];
    //    if (value.indexOf(item.PDID) >= 0)
    //      item.IsChecked = true;
    //  }
    //}
  }

  public set pdCheckedSpecial(value: number[]) {
  }
  get votazioneCorrente(): PollEntity {
    if (isNullOrUndefined(this.Votazioni) || this.Votazioni.length == 0)
      return null;

    if (this.actualStep > 2 && this.Votazioni.length > this.actualStep - 3)
      return this.Votazioni[this.actualStep - 3];

    return null;
  }

  get currentVoteNumber(): number {
    if (this.votazioneCorrente == null) return 0;

    let det: any[] = [];
    det = this.votazioneCorrente.PollDetails;
    return det.filter(x => x.IsChecked).length;

  }

  getVisiblePollDetails(PEID): PollDetail[] {
    let retval: PollDetail[] = [];
    retval = this.Votazioni.filter(x => x.PEID == PEID)[0].PollDetails.filter(x => x.IsHidden == false) 
    return retval;
  }

  private clonePollEntities(vot: PollEntity[]): PollEntity[] {
    let result = [];
    for (let i = 0; i < vot.length; i++) {
      let votazione: PollEntity = Object.assign({}, vot[i]);
      votazione.PollDetails = [];
      votazione.PollDetailsNormal = [];
      votazione.PollDetailsSpecial = [];
      let pd = vot[i].PollDetails
      for (let j = 0; j < pd.length; j++) {
        votazione.PollDetails.push(Object.assign({}, pd[j]));
        if (pd[j].IsSpecial)
          votazione.PollDetailsSpecial.push(Object.assign({}, pd[j]));
        else
          votazione.PollDetailsNormal.push(Object.assign({}, pd[j]));

      }
      result.push(votazione);
    }
    return result;
  }

  private checkedPDID(votazioni: PollEntity[]): number[] {

    let result: number[] = [];
    for (let i = 0; i < votazioni.length; i++) {
      let votazione = votazioni[i];
      for (let j = 0; j < votazione.PollDetails.length; j++) {
        let voto: vPollDetail = Object.assign(new vPollDetail, votazione.PollDetails[j]);
        if (voto.IsChecked) result.push(voto.PDID);
      }
    }

    return result;
  }


  getRiepilogoVoto(): PollingBoothVoteResult[] {

    let grouppedVoteResult: PollingBoothVoteResult[] = []

    for (let i = 0; i < this.Accounting.length; i++) {
      if (!isNullOrUndefined(this.Accounting[i].Votazioni)) {
        let gruppo = grouppedVoteResult.find(x => JSON.stringify(this.checkedPDID(x.votazioni)) == JSON.stringify(this.checkedPDID(this.Accounting[i].Votazioni)));
        if (!isNullOrUndefined(gruppo)) {
          gruppo.votanti.push(Object.assign({}, this.Accounting[i].vShareholder));
        }
        else {
          let current: PollingBoothVoteResult = { votanti: [], votazioni: [] };
          current.votanti.push(Object.assign({}, this.Accounting[i].vShareholder));

          for (let j = 0; j < this.Accounting[i].Votazioni.length; j++) {
            let votazione = Object.assign({}, this.Accounting[i].Votazioni[j])
            let voti: any[] = [];
            for (let j = 0; j < votazione.PollDetails.length; j++) {
              let voto: vPollDetail = Object.assign(new vPollDetail, votazione.PollDetails[j]);
              if (voto.IsChecked) voti.push(voto);
            }
            votazione.PollDetails = voti;
            current.votazioni.push(votazione);

          }

          grouppedVoteResult.push(current);
        }
      }
    }

    return grouppedVoteResult;
  }


  GetIconDescriptionByRelId(d): string {
    let msg: string = "";

    switch (d.RelID) {
      case 10:
        msg += "Fisico";
        break;
      case 20:
        msg += d.vShareholder.ShareholderTypeDescr;
        break;
      case 30:
        msg += "Minore";
        break;
      case 40:
        if (d.PAID != d.PAIDRef) {
          msg += "Delega";
        }
        else
          msg += "Delega";
        break;
      default:
        msg += "";
        break;
    }

    return msg;
  }

  get goButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 1 && this.actualStep < (this.totalStep-1) && !this.votoConfermato;
  };

  get disgiuntoButtonVisible(): boolean {
    return this.VotoDisgiuntoEnabled && this.Votazioni.length > 0 && this.Accounting.length > 1 && this.actualStep > 1 && this.actualStep == (this.totalStep-1) && !this.votoConfermato;
  };
  get confermaButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 1 && this.actualStep == (this.totalStep-1) && !this.votoConfermato;
  };
  get annullaButtonVisible(): boolean {
    return this.actualStep == 2 && !this.votoConfermato;
  };
  get indietroButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 2 && this.actualStep <= (this.totalStep - 1);
  };
  get annullaVotoButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 1 && this.actualStep == (this.totalStep-1) && !this.votoConfermato;
  };

  get closeEndPageButtonVisible(): boolean {
    return this.actualStep == this.totalStep && this.totalStep > 1;
  };

  //#region METHOD

  async BarcodeInserted() {
    if (isNullOrWhiteSpace(this.Barcode))
      return;

    try {

      await this.clearVar();
      this.ngxService.start();

      if (this.CreateEntranceFromCabina) {
        var exactMatch: boolean = false;
        var barcodeIngressoAnnullato: boolean = false;
        var list: vPotentialAttendant[] = await (await this.assembleaService.findPotentialAttendantByCodes(this.Barcode, false)).Results;
        if (list.length == 1) //se la ricerca mi da un solo risultato è quello che sto cercando
        {
          if (!isNullOrWhiteSpace(list[0].BarcodeIngressoFisico)) {//se il risultato ha il barcode del fisico allora faccio la ricerca per barcode
            if (!(list[0].BarcodeIngressoFisico === this.Barcode)) {
              this.Barcode = list[0].BarcodeIngressoFisico;
              await this.assembleaService.SetZoneAndCategoryIfEnabled(list[0].PAID);
            }
          }
          else {
            var caps = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoMovement });


            if (caps) {
              var newBC = await this.assembleaService.EnteringFromPollClient(list[0].PAID);
              if (!isNullOrWhiteSpace(newBC)) {
                this.Barcode = newBC;
                this.EnteredFromCabina = true;
                this.PAID_EnteredFromCabina = list[0].PAID;
              }
            }
          }
        }
      }


      await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.SEARCH_CODE, this.Barcode, '');
      

      let det: vPollDetail[] = await this.assembleaService.LoadActualPollDetailsByCode(this.Barcode);

      if (det == null || det.length == 0) {
        this.ngxService.stop();
        let dialog = this.errorService.showErrorMessageDetail("L'utente corrente non può votare", "In questo momento non sono presenti votazioni per le quali l'utente possa votare", "Attenzione");
        await dialog.afterClosed().toPromise();
        this.Barcode = '';

        if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
          await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
            "INGRESSO DA CABINA - L'utente corrente non può votare", this.identityService.user.PID, false, true);

        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
        return;
      }

      let AD = await this.assembleaService.LoadAccountingDetailsByCode(this.Barcode);
      if (AD == null || AD.length == 0) {
        this.ngxService.stop();
        let dialog = this.errorService.showErrorMessageDetail("L'utente corrente non può votare", "Al momento l'utente non è abilitato a votare", "Attenzione");
        await dialog.afterClosed().toPromise();
        this.Barcode = '';

        if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
          await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
            "INGRESSO DA CABINA - L'utente corrente non può votare", this.identityService.user.PID, false, true);

        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
        return;
      }
      
      for (let i: number = 0; i < det.length; i++) {
        let d: vPollDetail = det[i];
        if (d.IsHidden) continue;
        d.IsChecked = false;
        let v = this._Votazioni.find(x => x.PEID == d.PEID);
        if (v == null) {
          this._Votazioni.push(d.PollEntity);

          this.NumeroSociPerPEID.push({ peid: d.PollEntity.PEID, count: 0 });
          this.NumeroAzioniPerPEID.push({ peid: d.PollEntity.PEID, count: 0 });

          v = this._Votazioni.find(x => x.PEID == d.PEID);
          v.PollDetails = [];
          v.PollDetailsNormal = [];
          v.PollDetailsSpecial = [];
        }

        d.PollEntity = null;
        v.PollDetails.push(Object.assign(new PollDetail(), d));
        if (d.IsSpecial)
          v.PollDetailsSpecial.push(Object.assign(new PollDetail(), d));
        else
          v.PollDetailsNormal.push(Object.assign(new PollDetail(), d));
      }

      for (let i = 0; i < this._Votazioni.length; i++) {
        let item = this._Votazioni[i];
        if (item.AllowExternalItem) {

          let InsertBeforeNItem = 0;
          for (let j = 0; j < item.PollDetails.length; j++) {
            if (item.PollDetails[j].IsSpecial) {
              InsertBeforeNItem++;
            }
          }

          let VPD = item.PollDetails[0];

          let pd: vPollDetail = new vPollDetail();
          pd.Descr = 'Inserisci una nuova preferenza';
          pd.IsSpecial = false;
          pd.AddedOnTheFly = false;
          pd.IsVoted = false;
          pd.IsHidden = false;
          pd.PEID = VPD.PEID;
          //pd.PollEntityODG = VPD.PollEntityODG;
          //pd.PollEntityTitle = VPD.PollEntityTitle;
          //pd.PollEntityDescr = VPD.PollEntityDescr;
          pd.IsNewPreferenceRequest = true;
          //pd.IsFound = false;
          pd.IsChecked = false;

          if (InsertBeforeNItem == 0) {
            item.PollDetails.push(Object.assign(new PollDetail(), pd));
          }
          else {
            item.PollDetails.splice(item.PollDetails.length - InsertBeforeNItem, 0, Object.assign(new PollDetail(), pd));
          }

        }
      }

      for (let i: number = 0; i < AD.length; i++) {
        let item = AD[i];
        let ExcludedPoll: PollEntity2PeidStid[] = [];

        if (item.vShareholder != null) {   // TRICK!!!
          item.vShareholder.FirstName = item.vShareholder.BusinessName; //stampa solo il nome nel riepilogo di voto veloce

          if (item.vShareholder.STID != null)
            ExcludedPoll = PollEntity2PeidStid.ToListOfInstance(await this.assembleaService.GetPollEntitiesFromSTID(item.vShareholder.STID, true));
        }
        if (isNullOrUndefined(ExcludedPoll)) ExcludedPoll = [];

        if (item.PAID == item.PAIDRef) {
          if (item.RelID == 10) {
            this.Accounting.push(item);
            this.CurrentPotentialAttendant = item.vShareholder;
          }
          else if (item.RelID == 20) {

            this.Accounting.push(item);
            this.CurrentPotentialAttendant = item.vShareholder;

            this.NumeroAzioni += item.NoS;
            this.NumeroSoci++;

            this.NumeroAzioniPerPEID.forEach(val => val.count += item.NoS);
            this.NumeroSociPerPEID.forEach(val => val.count++);

            if (ExcludedPoll.length > 0) {
              for (let i = 0; i < ExcludedPoll.length; i++) {
                let ep = ExcludedPoll[i];

                if (isNullOrUndefined(this._Votazioni.find(x => x.PEID == ep.PEID)))
                  continue;

                if (ep.IsExcluded) {
                  this.NumeroSociPerPEID.find(x => x.peid == ep.PEID).count--;
                  this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
                } else {
                  this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
                  switch (ep.TypeNos) {
                    case ShareholderTypeAction.DEFAULT:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoS;
                      break;
                    case ShareholderTypeAction.NOSB:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSB;
                      break;
                    case ShareholderTypeAction.NOSC:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSC;
                      break;
                    case ShareholderTypeAction.NOSD:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSD;
                      break;
                  }
                }
              }
            }

            this.CurrentPotentialAttendant.BusinessName = this.CurrentPotentialAttendant.BusinessName + " Legale Rapp. " + item.vPotentialAttendant.BusinessName;
          }
        }
        if (item.RelID != 20 && item.RelID != 30) {
          this.NumeroAzioni += item.NoS;
          this.NumeroSoci++;

          this.NumeroAzioniPerPEID.forEach(val => val.count += item.NoS);
          this.NumeroSociPerPEID.forEach(val => val.count++);

          if (ExcludedPoll.length > 0) {
            for (let i = 0; i < ExcludedPoll.length; i++) {
              let ep = ExcludedPoll[i];

              if (isNullOrUndefined(this._Votazioni.find(x => x.PEID == ep.PEID)))
                continue;

              if (ep.IsExcluded) {
                this.NumeroSociPerPEID.find(x => x.peid == ep.PEID).count--;
                this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
              } else {
                this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
                switch (ep.TypeNos) {
                  case ShareholderTypeAction.DEFAULT:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoS;
                    break;
                  case ShareholderTypeAction.NOSB:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSB;
                    break;
                  case ShareholderTypeAction.NOSC:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSC;
                    break;
                  case ShareholderTypeAction.NOSD:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSD;
                    break;
                }
              }
            }
          }

        }

      }


      let DelegantiAccountingDetails = AD.filter(ss => ss.RelID == 40);

      for (let i: number = 0; i < DelegantiAccountingDetails.length; i++) {
        let paitem = DelegantiAccountingDetails[i];
        this.Accounting.push(paitem);
      }

      // In caso di deleghe a non socio non c'è un current potential attendant. Imposta come current la prima delle deleghe
      if (this.CurrentPotentialAttendant == null && this.Accounting != null && this.Accounting.length > 0) {
        this.CurrentPotentialAttendant = this.Accounting[0].vShareholder;
      }


      //this.SomeVoteAlreadyExpressed = false;
      ////TODO non funziona nemmeno sul client cabine in quanto alla stored non va bene il null come PEID
      //let AD_canVote = await this.assembleaService.EVOT_LoadAccountingDetailsNewAfterVote(this.Barcode, null);
      //console.log({ 'AD_canVote': AD_canVote });
      //if (AD_canVote != null && AD_canVote.length > 0) {
      //  for (let i = 0; i < AD_canVote.length;i++)
      //  {
      //    let item = AD_canVote[i];
      //    for (let j = 0; j < this.Accounting.length;j++)
      //    {
      //      let sad = this.Accounting[j];
      //      if (sad.PAID_Shareholder == item.PAID_Shareholder) {
      //        this.SomeVoteAlreadyExpressed = true;

      //        sad.HasAlreadyVoted = true;
      //        // uncheck vote
      //        sad.IsChecked = false;
      //        // TODO: se uno non può votare indicare il voto come disgiunto???
      //        break;
      //      }
      //    }
      //  }
      //  console.log({ 'Accounting': this.Accounting });
      //}


      this.Code = this.Barcode;

      this.sessionStartTime = await this.assembleaService.getServerDatetime();

      if (this.LogAllEPollVotingSessions) {
        this.assembleaService.StartPollVotingSession(AD[0].PAID);
      }

      this.BatchCode = '';
      this.votoDisgiuntoInCorso = false;
      this.CurrentPAIDShareholed = null;
      this.Barcode = '';
      this.totalStep = this._Votazioni.length + 3+1;
      this.actualStep = 2;
      this.ngxService.stop();
    } catch (e) {

      if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
        await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
          "INGRESSO DA CABINA - Errore non previsto", this.identityService.user.PID, false, true);

      let dialog = this.errorService.showErrorMessage(e, "Errore nella ricerca del socio");
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
      this.Barcode = '';
      await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
    }

  }



  onClickedGo(e) {
    if (this.votazioneCorrente !== null) {
      let det: any[] = [];
      det = this.votazioneCorrente.PollDetails;
      if (det.filter(x => x.IsChecked).length == 0) {
        notify({
          position: 'center', height: '100px',
          contentTemplate: function () {
            return $("<h3 />").text('Attenzione per proseguire è necessario esprimere almeno un voto!')
              .css("color", "black");
          }
        }, 'warning', 3000)
        return;
      }


    }


    //TODO aggiungere valutazioni se this.votoDisgiuntoInCorso e partecipante ha diritto alla votazione?
    if (this.actualStep < (this.totalStep-1)) this.actualStep++;


    if (!this.votoDisgiuntoInCorso && this._Votazioni.length > 0 && this.actualStep == (this.totalStep-1)) {

      for (let i = 0; i < this.Accounting.length; i++) {
        this.Accounting[i].Votazioni = this.clonePollEntities(this._Votazioni);
      }

    }

    this.riepilogoVoto = this.getRiepilogoVoto();

  }




  async onClickedDisgiunto(e) {

    let data: VotoDisgiuntoDialogData = new VotoDisgiuntoDialogData({
      accounting: this.Accounting
    });
    let dialog = this.dialog.open(VotoDisgiuntoSelectorComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (isNullOrUndefined(result) || result.result != MessageBoxVotoDisgiuntoResult.OK)
      return;
    else {
      this.votoDisgiuntoInCorso = true;
      this.CurrentPAIDShareholed = result.PAID_Shareholder;
      this.actualStep = 3; //TODO valutare se partecipante selezionato ha diritto per quella votazione altrimenti passare direttamente alle successive?
    }


  }
  async onClickedConferma(e) {

    this.counter = 100;

    if (this.ShowZoneMessage) {

      if (this.countDown != null)
        this.countDown.unsubscribe();
      this.countDown = null;

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Conferma voto",
        message: `Attenzione, stai votando per la tua zona di appartenenza ('${this.CurrentPotentialAttendant.DelegationZone}') quindi potrai partecipare solo all'assemblea della tua zona. Confermi?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });

      this.countDown = timer(this.tick, this.tick)
        .subscribe(async () => {
          if (this.counter <= 0) {
            if (!isNullOrUndefined(this.identityService.user)) {
              this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.TIMEOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
            }
            if (!this.votoConfermato) {
              dialog.close(MessageBoxResult.CANCEL);
              //notify({ message: 'Il tempo a sua disposizione è scaduto', position: 'center', height: '100px' }, 'warning', 6000)
              this.esito_message = "Il tempo a disposizione per concludere l'operazione di voto è scaduto"
              this.esito = 1;
              if (this.LogAllEPollVotingSessions) {
                this.assembleaService.EndPollVotingSession(this.Accounting[0].PAID, EPollVotingSessionsEsito.TIMEOUT);
              }
              else {
                this.assembleaService.LogPollVotingSession(new EPollVotingSessions({
                  PAID: this.Accounting[0].PAID,
                  Esito: EPollVotingSessionsEsito.TIMEOUT,
                  StartSession: this.sessionStartTime,
                  EndSession: await this.assembleaService.getServerDatetime()
                }));
              }

              if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
                await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
                  "INGRESSO DA CABINA - Timeout sessione", this.identityService.user.PID, false, true);
            }
            this.actualStep = this.totalStep;
            //TODO await this.clearVar();

          }
          else {
            this.counter = this.counter - (100 / this.LastPageTimerTotalSeconds)
          }
        });

      let res = await dialog.afterClosed().toPromise();
      if (res == MessageBoxResult.CANCEL) {
        return false;
      }else if (res != MessageBoxResult.YES) {
        this.counter = 100;
        if (this.countDown != null)
          this.countDown.unsubscribe();
        this.countDown = null;

        this.countDown = timer(this.tick, this.tick)
          .subscribe(async () => {
            if (this.counter <= 0) {
              if (!isNullOrUndefined(this.identityService.user)) {
                this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.TIMEOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
              }
              if (!this.votoConfermato) {
                //notify({ message: 'Il tempo a sua disposizione è scaduto', position: 'center', height: '100px' }, 'warning', 6000)
                this.esito_message = "Il tempo a disposizione per concludere l'operazione di voto è scaduto"
                this.esito = 1;
                if (this.LogAllEPollVotingSessions) {
                  this.assembleaService.EndPollVotingSession(this.Accounting[0].PAID, EPollVotingSessionsEsito.TIMEOUT);
                }
                else {
                  this.assembleaService.LogPollVotingSession(new EPollVotingSessions({
                    PAID: this.Accounting[0].PAID,
                    Esito: EPollVotingSessionsEsito.TIMEOUT,
                    StartSession: this.sessionStartTime,
                    EndSession: await this.assembleaService.getServerDatetime()
                  }));
                }

                if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
                  await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
                    "INGRESSO DA CABINA - Timeout sessione", this.identityService.user.PID, false, true);
              }
              this.actualStep = this.totalStep;
              //await this.clearVar();

            }
            else {
              this.counter = this.counter - (100 / this.LastPageTimerTotalSeconds)
            }
          });
        return false;
      }
    }
    try {
      this.ngxService.start();
      let pPollDetailList: PollVoteList<vPollDetail[]> = {};

      for (let i = 0; i < this.Accounting.length; i++) {
        let details: vPollDetail[] = [];
        let item = this.Accounting[i];
        for (let j = 0; j < item.Votazioni.length; j++) {
          let votazione = item.Votazioni[j];
          for (let j = 0; j < votazione.PollDetails.length; j++) {
            let voto: vPollDetail = Object.assign(new vPollDetail, votazione.PollDetails[j]);

            if (voto.AddedOnTheFly && voto.IsChecked) {

              voto.CountIgnore = false;
              voto.ElectronicReadingTag = null;
              voto.IsSpecial = false;
              voto.IsHidden = true;
              voto.IsDeactivated = false;
              voto.AddedOnTheFly = true;
              voto.SortN = 300; // TODO: check
              voto.TelevoterMapping = null;
              voto.IsPrintHidden = false;
              voto.IsListVote = false;
              voto.PDID = await this.assembleaService.EVOT_SaveNewCustomPollDetail(voto);

            }

            if (voto.IsChecked) details.push(voto);
          }
        }
        pPollDetailList[item.vShareholder.PAID] = details;
      }

      this.BatchCode = await this.assembleaService.EVOT_SaveVoteByCode(this.Code, pPollDetailList, this.BatchCode);

      await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.VOTE_SENT, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);

      this.ngxService.stop();

      //notify({ message: 'Il voto è stato inviato correttamente', position: 'center', height: '100px' }, 'success', 1000);

      this.votoConfermato = true;
      this.esito_message = "Il voto è stato registrato correttamente";
      this.esito = 0;

      if (this.LogAllEPollVotingSessions) {
        this.assembleaService.EndPollVotingSession(this.Accounting[0].PAID, EPollVotingSessionsEsito.OK);
      }
      

      if (this.countDown != null)
        this.countDown.unsubscribe();
      this.countDown = null;

      this.actualStep = this.totalStep;

      //this.counter = 10;

      //this.countDown = timer(this.tick, this.tick)
      //  .subscribe(async () => {
      //    if (this.counter <= 0) {
      //      this.actualStep = this.totalStep;
      //      //TODO await this.clearVar();
      //    }
      //    else {
      //      this.counter = this.counter - (10)
      //    }
      //  });




    } catch (e) {
      this.counter = 100;
      let dialog = this.errorService.showErrorMessage(e, "Errore nell'invio del voto. Riprova");
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }
  }


  async onClickedAnnulla(e) {
    this.esito_message = "Il socio ha annullato l'operazione di voto";
    this.esito = 2;

    if (this.LogAllEPollVotingSessions) {
      this.assembleaService.EndPollVotingSession(this.Accounting[0].PAID, EPollVotingSessionsEsito.ANNULLATO);
    }
    else {
      this.assembleaService.LogPollVotingSession(new EPollVotingSessions({
        PAID: this.Accounting[0].PAID,
        Esito: EPollVotingSessionsEsito.ANNULLATO,
        StartSession: this.sessionStartTime,
        EndSession: await this.assembleaService.getServerDatetime()
      }));
    }

    if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
      await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
        "INGRESSO DA CABINA - Annullato dal socio", this.identityService.user.PID, false, true);

    this.actualStep = this.totalStep;
    //await this.clearVar();
  }
  async onClickedIndietro(e) {
    if (this.votoConfermato) {
      //TODO await this.clearVar();
      return;
    }
    if (this.votoDisgiuntoInCorso) {
      if (this.actualStep > 3) this.actualStep--; //TODO aggiungere valutazioni su abilitazione partecipante a votazione?
    }
    else {
      if (this.actualStep > 0) this.actualStep--;

      if (this.actualStep == 1) {
        this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
      }
    }

  }
  async onClickedAnnullaVoto(e) {
    this.esito_message = "Il socio ha annullato l'operazione di voto";
    this.esito = 2;

    if (this.LogAllEPollVotingSessions) {
      this.assembleaService.EndPollVotingSession(this.Accounting[0].PAID, EPollVotingSessionsEsito.ANNULLATO);
    }
    else {
      this.assembleaService.LogPollVotingSession(new EPollVotingSessions({
        PAID: this.Accounting[0].PAID,
        Esito: EPollVotingSessionsEsito.ANNULLATO,
        StartSession: this.sessionStartTime,
        EndSession: await this.assembleaService.getServerDatetime()
      }));
    }

    if (this.EnteredFromCabina && this.PAID_EnteredFromCabina >= 0)
      await this.assembleaService.nullify(new vPotentialAttendant({ PAID: this.PAID_EnteredFromCabina }),
        "INGRESSO DA CABINA - Annullato dal socio", this.identityService.user.PID, false, true);

    this.actualStep = this.totalStep;
    //await this.clearVar();
  }

  async onClickedCloseEndPage(e) {
    if (!this.EndPageTimerEnabled && !isNullOrWhiteSpace(this.ConfirmCodeEndPage)) {
      let dialog = this.dialog.open(CodiceSbloccoComponent);
      let result = await dialog.afterClosed().toPromise();

      if (result.result == MessageBoxCodiceSbloccoResult.OK) {
        if (this.ConfirmCodeEndPage != result.codice) {
          notify({ message: 'Il codice è errato', position: 'center', height: '100px' }, 'error', 3000)
          return;
        }
      }
      else {
        notify({ message: 'Digitare il codice di sblocco', position: 'center', height: '100px' }, 'error', 3000)
        return;
      }
    }
    await this.clearVar();
  }

  checkLostFocusBarcode(e) {
    if (this.actualStep == 1) {
      this.setFocusBarcode();

    }
  }

  GetNumeroSociPerPEID(peid: number): number {
    return this.NumeroSociPerPEID.find(x => x.peid == peid).count;
  }

  GetNumeroAzioniPerPEID(peid: number) {
    return this.NumeroAzioniPerPEID.find(x => x.peid == peid).count;
  }

  private TempPDID = 0;



  async PollDetailsListSelection(e) {
    console.log({ 'PollDetailsListSelection': e });
    let that = this;
    if (e.removedItems.length > 0) {
      let PollEntity = that.votazioneCorrente;
      let VPD: vPollDetail = e.removedItems[0];
      let PD: any;
      PD = PollEntity.PollDetails.find(x => x.PDID == VPD.PDID);
      PD.IsChecked = false;
    }
    else if (e.addedItems.length > 0) {
      let PollEntity = that.votazioneCorrente;
          let VPD: vPollDetail = e.addedItems[0];
          let PD: any;
          PD = PollEntity.PollDetails.find(x => x.PDID == VPD.PDID);
          PD.IsChecked = true;
          if (PollEntity.AllowMultipleItem) {
            if (that.ConfirmSpecialPref) {
              let res: boolean = false;
              if (VPD.IsSpecial) {

                let data: MessageBoxDialogData = new MessageBoxDialogData({
                  //title: formatMessage("mess", ""),
                  title: "Conferma operazione",
                  //message: formatMessage("wwww", ""),
                  message: "La preferenza speciale, rimuoverà tutte le altre preferenze. Procedere ugualmente?",
                  //details: "Sei sicuro di voler procedere?",
                  buttons: MessageBoxButtons.YES_NO,
                  image: MessageBoxImage.Warning
                });
                let dialog = that.dialog.open(MessageBoxComponent, { data: data });
                let resp = await dialog.afterClosed().toPromise();
                res = resp == MessageBoxResult.YES

              }

              if (VPD.IsSpecial && res) {
                //se stai votando un nullo o astenuto resetta tutti gli altri check
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID) {
                    item.IsChecked = false;
                  }
                }
              } else if (VPD.IsSpecial && !res) {
                PD.IsChecked = false;
              }
              else {
                //se non stai votando un nullo o un astenuto resetta tutti i nulli o astenuti 
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID && item.IsSpecial) {
                    item.IsChecked = false;
                  }
                }
              }

            }
            else {
              if (VPD.IsSpecial) {
                //se stai votando un nullo o astenuto resetta tutti gli altri check
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID) {
                    item.IsChecked = false;
                  }
                }
              }
              else {
                //se non stai votando un nullo o un astenuto resetta tutti i nulli o astenuti 
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID && item.IsSpecial) {
                    item.IsChecked = false;
                  }
                }
              }
            }
            //Check Massino numero di preferenze
            if (that.currentVoteNumber > PollEntity.MultipleItemMaxNum) {
              if (PollEntity.MultipleItemMaxNum == 1) {
                notify({
                  position: 'center', height: '100px',
                  contentTemplate: function () {
                    return $("<h3 />").text('Attenzione è possibile esprimere una sola preferenza!')
                      .css("color", "black");
                  }
                }, 'warning', 3000)

                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID) {
                    item.IsChecked = false;
                  }
                }
              }
              else {

                if (VPD.IsNewPreferenceRequest) {
                  notify({
                    position: 'center', height: '100px',
                    contentTemplate: function () {
                      return $("<h3 />").text(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`)
                        .css("color", "black");
                    }
                  }, 'warning', 3000);
                }
                else {

                  notify({
                    position: 'center', height: '100px',
                    contentTemplate: function () {
                      return $("<h3 />").text(`Attenzione sono possibili ${PollEntity.MultipleItemMaxNum} preferenze!`)
                        .css("color", "black");
                    }
                  }, 'warning', 3000);

                }
                PD.IsChecked = false;
              }
            }

            //Check Massimo numero di tag
            if (PollEntity.UseMaxItemsPerTag) {


              // numero max di preferenze per il tag di questa preferenza
              let n: number = 0;
              let t = PollEntity.TagConfig.filter(x => x.TAG == VPD.TAG);
              if (!isNullOrUndefined(t) && t.length > 0)
                n = t[0].MaxAllowedItems;

              // quante preferenze ho già con questo tag ?
              let det: any[] = [];
              det = that.votazioneCorrente.PollDetails;
              let m: number = det.filter(x => x.IsChecked && x.pollDetail.TAG == VPD.TAG).length;


              if (m > n) {
                notify({
                  position: 'center', height: '100px',
                  contentTemplate: function () {
                    return $("<h3 />").text(`Attenzione sono possibili ${n} preferenze con TAG ${VPD.TAG}!`)
                      .css("color", "black");
                  }
                }, 'warning', 3000)
                console.log(`Attenzione sono possibili ${n} preferenze con TAG ${VPD.TAG}!`);
                PD.IsChecked = false;

              }


            }


          }
          else {

            if (that.currentVoteNumber > 1 && VPD.IsNewPreferenceRequest) {
              notify({
                position: 'center', height: '100px',
                contentTemplate: function () {
                  return $("<h3 />").text(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`)
                    .css("color", "black");
                }
              }, 'warning', 3000);
              PD.IsChecked = false;
            } else {

              for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                let item: any;
                item = PollEntity.PollDetails[i];
                if (item.PDID != VPD.PDID) {
                  item.IsChecked = false;
                }
              }
            }
          }


          if (VPD.IsNewPreferenceRequest && VPD.IsChecked) {
            PD.IsChecked = false;

            let IsNullOrAbstention = false;
            for (let i = 0; i < PollEntity.PollDetails.length; i++) {
              let item: any;
              item = PollEntity.PollDetails[i];
              if (item.IsChecked && item.IsSpecial) {
                IsNullOrAbstention = true;
                break;
              }
            }
            if (IsNullOrAbstention || that.currentVoteNumber >= PollEntity.MultipleItemMaxNum) {

              notify({
                position: 'center', height: '100px',
                contentTemplate: function () {
                  return $("<h3 />").text(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`)
                    .css("color", "black");
                }
              }, 'warning', 3000);
              console.log(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`);
              return;
            }

            let vote: string = 'Fuffa Puppa';
            let dialog = that.dialog.open(NuovaPreferenzaComponent);
            let result = await dialog.afterClosed().toPromise();

            if (result.result == MessageBoxNuovaPreferenzaResult.OK) {
              vote = result.preferenza;

              if (vote !== null && vote !== '') {

                let nuovaPreferenza = true;
                let InsertBeforeNItems = 0; // inserisci prima della voce che consente di inserire nuove preferenze
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.IsSpecial)
                    InsertBeforeNItems++;
                  if (item.Descr == vote) {
                    nuovaPreferenza = false;
                    break;
                  }
                }

                if (nuovaPreferenza) {
                  let pd: vPollDetail = new vPollDetail();
                  pd.Descr = vote;
                  pd.IsSpecial = false;
                  pd.AddedOnTheFly = true;
                  pd.IsVoted = false;
                  pd.IsHidden = false;
                  pd.PEID = VPD.PEID;
                  // assegna un pdid temporaneo:
                  pd.PDID = that.TempPDID;
                  //pd.PollEntityODG = VPD.pollDetail.PollEntityODG;
                  //pd.PollEntityTitle = VPD.pollDetail.PollEntityTitle;
                  //pd.PollEntityDescr = VPD.pollDetail.PollEntityDescr;
                  pd.IsChecked = true;


                  PollEntity.PollDetails.splice(PollEntity.PollDetails.length - InsertBeforeNItems, 0, Object.assign(new PollDetail(), pd));


                  that.TempPDID++;
                }

                else {
                  notify({
                    position: 'center', height: '100px',
                    contentTemplate: function () {
                      return $("<h3 />").text(`Il nominativo inserito è già presente fra le preferenze che è possibile esprimere.`)
                        .css("color", "black");
                    }
                  }, 'warning', 3000);

                }

              }


            }


          }

        }

      }
    

  


}
