
<h3>Importazione Principal</h3>
<p>

</p>



<div class="dx-field" style="margin:20px 0 10px 0">

</div>
<div class="dx-field" style="margin:0">

  <input type="file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" id="file_selector_principal"
         (change)="loadFilePrincipal($event)" style="margin:0" />
</div>



<div style="align-content: center; align-items: center; align-self: center; text-align: center; margin: 5px 0 15px 0">

  <dx-button class="dx-button-success"
             [disabled]="principal.length==0 || errors.length>0 || messages.length>0"
             (onClick)="ImportPrincipal()"
             text='{{"LABEL_AVVIA_IMPORT" | formatMessage}}'>
  </dx-button>

</div>

<div *ngIf="errors.length>0">
  <span class="dx-validationsummary-item" style="cursor:default">{{"LABEL_ERRORS_IMPORT" | formatMessage}}</span>
  <ul>
    <li *ngFor="let msg of errors" class="dx-validationsummary-item" style="cursor:default">{{msg}}</li>
  </ul>
</div>

<div *ngIf="messages.length>0">
  <ul>
    <li *ngFor="let msg of messages">{{msg}}</li>
  </ul>
</div>

<dx-data-grid [dataSource]="principal" id="gridContainer"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false">
  <dxo-editing [allowUpdating]="false"
               [allowAdding]="false"
               [allowDeleting]="messages.length<=0"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
  </dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>


  <dxo-paging [enabled]="true" [pageSize]="15">
  </dxo-paging>
  <dxo-pager [visible]="true"
             displayMode="compact"
             [allowedPageSizes]="[10,15,20]"
             [showPageSizeSelector]="true"
             [showInfo]="true"
             [showNavigationButtons]="true">
  </dxo-pager>


  <dxi-column dataField="username" [allowSorting]="false" caption='username'>
    <dxi-validation-rule type="required" message="Campo obbligatorio">
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="name" [allowSorting]="false" caption='name'>
    <dxi-validation-rule type="required" message="Campo obbligatorio">
    </dxi-validation-rule>
  </dxi-column>


  <dxi-column dataField="pwd" [allowSorting]="false" caption='password'>
    <dxi-validation-rule type="required" message="Campo obbligatorio">
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="rid" [allowSorting]="false" caption='roleId'>
    <dxi-validation-rule type="required" message="Campo obbligatorio">
    </dxi-validation-rule>
  </dxi-column>

</dx-data-grid>
