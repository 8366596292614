import { Component, NgModule, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxTextBoxModule, DxToolbarModule, DxDataGridModule, DxDataGridComponent } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { Principal } from '../../../models/assemblea/Principal';
import { AssembleaService } from '../../../services/assemblea.service';
import { SysConfigService } from '../../../services/sys-config.service';
import { Role } from '../../../models/assemblea/Role';
import { AssembleaError } from '../../../models/assemblea/AssembleaError';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxComponent, MessageBoxImage, MessageBoxResult } from '../../message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import { LogService } from '../../../services/log.service';
import * as _ from 'lodash';
import { MovementMode } from '../../../models/assemblea/enums';
import { Exceptions } from '../../../models/assemblea/constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-principals-config',
  templateUrl: './principals-config.component.html',
  styleUrls: ['./principals-config.component.scss']
})
/** principals-config component*/
export class PrincipalsConfigComponent {
  get ToolbarDisabled(): boolean {
    return isNullOrUndefined(this.PrincipalList.find(v => v.Modified == true));
  }
  get RemoveDisabled(): boolean {
    if (this.grid !== undefined)
      return this.grid.instance.getSelectedRowKeys().length == 0;
    return true;
  }
  PrincipalList: Principal[] = [];
  RolesList: Role[] = [];
  MovementsModeList: any[] = [
    { id: 0, descr: 'TUTTI' },
    { id: 1, descr: 'SOLO INGRESSI' },
    { id: 2, descr: 'SOLO USCITE' }
  ];
  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  ShowPasswordRequest: boolean = false;
  PrincipalInEditing: Principal = null;
  ScrollToNew: boolean = false;
  ScrolledToNew: boolean = false;

  gridHeight: number = 300;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerHeight > 550) {
      this.gridHeight = window.innerHeight - 220
    }
    else {
      this.gridHeight = 550
    }
  }

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  /** principals-config ctor */
  constructor(private asseService: AssembleaService, private sysConfigService: SysConfigService
    , private dialog: MatDialog, private logService: LogService, private identityService: IdentityService
    , private changeDetector: ChangeDetectorRef, private ngxService: NgxUiLoaderService) {

    this.onResize();

    asseService.getPrincipalList().then(async (result) => {
      this.PrincipalList = Principal.ToListOfInstance(result);
      await this.FillPrincipalRole();
    });

    this.addNew = this.addNew.bind(this);
    this.removeSelected = this.removeSelected.bind(this);
    this.save = this.save.bind(this);
    this.undoEdit = this.undoEdit.bind(this);
  }
  private async loadPrincipals() {
    this.ngxService.start();
    try {
      this.PrincipalList = Principal.ToListOfInstance(await this.asseService.getPrincipalList());
    } finally {
      this.ngxService.stop();
    }
    await this.FillPrincipalRole();
  }
  async save() {
    await this.savePrincipals();
    await this.loadPrincipals();
    this.grid.instance.refresh();
  }
  async addNew() {
    this.grid.instance.beginUpdate();
    if (!this.ToolbarDisabled) {
      this.savePrincipals();
    }
    let username: string = this.GetNewPrincipalUsername();
    let pwd = "a"; //CoreHelper.GetBytesHexRepresentation(Encoding.Default.GetBytes(CoreHelper.GetSHA1Hash("a")));
    let idRuolo: number = this.GetNewPrincipalRole();

    // creazione di un utente con proprietà di base
    let principal: Principal = new Principal({
      UserName: username,
      PwdInEditing : pwd,
      IsEnabled : true,
      RID : idRuolo,
      MobileMode: false,
      MovementMode: MovementMode.TUTTI,
      ElectronicVoteMode : false,
      StationPrefix :"",
      Language : "it-IT",
      FirstName : "",
      LastName : "",
      Location : "SITO1",
      EnableScreenCapture : false,
      EnableBadgePassword : false,
      BadgePassword: null,
      PhoneNumberConfirmed: false,
      TwoFactorEnabled: false,
      DelegationZone: null,
      ShareholderCategory:null

    });
    
    await this.insertNewPrincipal(principal);
    if (!this.InError) {
      await this.loadPrincipals();
      principal = this.PrincipalList.find(p => p.UserName == username);
      if (!isNullOrUndefined(principal)) {
        this.ScrollToNew = true;
        this.grid.instance.refresh();
      }
    }
    this.grid.instance.endUpdate();
  }
  private GetNewPrincipalUsername():string {
    if (this.PrincipalList == null) {
      this.PrincipalList = [];
    }

    let nuovi: number = this.PrincipalList.filter(v => v.UserName.indexOf("Nuovo") >= 0).length;

    if (nuovi == 0) {
      return "Nuovo";
    }

    let newName: string = `Nuovo${nuovi}`;
    while (this.PrincipalList.filter(v => v.UserName.indexOf(newName) >= 0).length > 0) {
      nuovi++;
      newName = `Nuovo${nuovi}`;
    }

    return newName;
  }

  private GetNewPrincipalRole():number {
    // cerca di evitare, se possibile di creare il nuovo utente come amministratore
    if (isNullOrUndefined(this.RolesList) || this.RolesList.length == 0) {
      return -1; // admin dovrebbe sempre essere presente, il caricamento vuoto potrebbe essere un errore
    }

    try {
      let ruolo: Role = _.sortBy(this.RolesList.filter(v => v.IsSystem == false), ["RID"])[0];
      if (ruolo == null) {
        return -1;
      }
      else {
        return ruolo.RID;
      }
    }
    catch
    {
      return -1;
    }

  }

  private async savePrincipals() {
    this.InError = false;
    if (this.PrincipalList != null) {
      this.ngxService.start();
      await asyncForEach(this.PrincipalList, async (p:Principal) => {
        if (p.Modified) {
          try {
            await this.asseService.updatePrincipal(p);
          }
          catch (e) {
            this.InError = true;
            if ('IsAssembleaException' in e) {
              let error: AssembleaError = <AssembleaError>e;
              if (e.IsAssembleaException) {
                let data: MessageBoxDialogData = new MessageBoxDialogData({
                  title: "Assemblea - Errore inatteso",
                  message: `Non è stato possibile aggiornare l'operatore ${p.UserName}`,
                  details: error.Detail.UIMessage,
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Error
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: data });
                this.ngxService.stop();
                await dialog.afterClosed().toPromise();
                this.ngxService.start();
              }
            }
            console.error(e);
          }
        }
      });
      this.ngxService.stop();
    }
  }
  private async insertNewPrincipal(p: Principal) {
    try {
      if (p != null) {
        this.ngxService.start();
        await this.asseService.addPrincipal(p);
        this.InError = false;
        this.ngxService.stop();
      }
      else {
        this.InError = true;
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Assemblea - Errore inatteso",
          message: `Non è stato possibile inserire il nuovo operatore ${p.UserName}`,
          details: "Oggetto ricevuto nullo",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: `Non è stato possibile inserire il nuovo operatore ${p.UserName}`,
            details: error.Detail.UIMessage,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
  private async deletePrincipal(p: Principal): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        this.ngxService.start();
        await this.asseService.deletePrincipal(p, false);
        this.InError = false;
        this.ngxService.stop();
      }
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          if (error.Code == Exceptions.SQL_ERROR_DB.code) {
            result = true;
          } else {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea - Errore inatteso",
              message: `Non è stato possibile eliminare l'operatore ${p.UserName}`,
              details: error.Detail.UIMessage,
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();
          }
        }
      }
      console.error(e);
    }
    return result;
  }
  private async disablePrincipal(p: Principal) {
    try {
      if (!isNullOrUndefined(p)) {
        this.ngxService.start();
        await this.asseService.deletePrincipal(p, true);
        this.InError = false;
        this.ngxService.stop();
      }
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: `Non è stato possibile disabilitare l'operatore ${p.UserName}`,
            details: error.Detail.UIMessage,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
  public async removeSelected() {
    let notDeleted: Principal[] = [];
    let selectedPrincipals = this.grid.instance.getSelectedRowKeys();
    if (selectedPrincipals.length == 0) return;
    let selectedUserNames = this.PrincipalList.filter(x => !isNullOrUndefined(selectedPrincipals.find(y => y == x.PID))).map(x => x.UserName);
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: `Eliminazione operatori`,
      message: `Gli ${selectedPrincipals.length} operatori selezionati saranno eliminati definitivamente, procedere?`,
      details: selectedUserNames.join(", "),
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result == MessageBoxResult.NO) {
      return;
    }

    this.grid.instance.beginUpdate();

    if (!this.ToolbarDisabled) {
      await this.savePrincipals();
    }

    await asyncForEach(selectedPrincipals, async (pKey) => {
      let p: Principal = this.PrincipalList.find(x => x.PID == pKey);
      if (!isNullOrUndefined(p)) {
        if (await this.deletePrincipal(p)) {
          notDeleted.push(p);
        }
      }
    });

    let errors: boolean = false;
    if (notDeleted != null && notDeleted.length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Disattivazione operatori",
        message: `Attenzione: ${notDeleted.length} operatori non possono essere eliminati in quanto hanno già eseguito movimenti o modifiche.\nProcedere con la disabilitazione degli operatori?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        this.grid.instance.endUpdate();
        return;
      }

      await asyncForEach(notDeleted, async (p) => {
        await this.disablePrincipal(p);
        if (this.InError) {
          errors = true;
        }
      });
    }

    if (errors) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Disattivazione operatori",
        message: `Attenzione: si è verificato un errore durante la disattivazione degli operatori`,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();
    }

    await this.loadPrincipals();
    this.grid.instance.endUpdate();
    this.grid.instance.refresh();
  }

  scrolle

  onContentReady(e) {
    
    window.setTimeout(async () => {
      
      let scrollable = this.grid.instance.getScrollable();
      if (scrollable !== undefined) {
        
        if (this.ScrollToNew)
        {
          try {
            this.ngxService.start();
            this.ScrollToNew = false;
            this.ScrolledToNew = true;
            scrollable.scrollTo(scrollable.scrollHeight());
            let idx: number = -1;
            let cont: number = 0;
            do {
              await new Promise(f => window.setTimeout(f, 100));
              idx = this.grid.instance.getRowIndexByKey(this.PrincipalList[this.PrincipalList.length - 1].PID);
              cont++;
            } while (idx == -1 && cont < 10);
            
            if (idx >= 0) {
              this.grid.instance.editRow(idx);
            }
          } finally {
            this.ngxService.stop();
          }

        }else  {
          scrollable.update();
        } 
      }
    }, 100);
  }
  async undoEdit() {
    await this.loadPrincipals();
    this.grid.instance.refresh();
  }
  private async removeRow(e) {
    let principal: Principal = this.PrincipalList.find(p => p.PID == e.key);
    try {
      if (!isNullOrUndefined(principal)) {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: 'Eliminazione operatore',
          message: `L'operatore ${principal.UserName} sarà eliminato definitivamente, procedere ?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (result != MessageBoxResult.YES) {
          return true; //cancel
        }

        if (!this.ToolbarDisabled) {
          await this.savePrincipals();
        }

        let cannotDelete = await this.deletePrincipal(principal);

        if (cannotDelete) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: 'Disattivazione operatore',
            message: `Attenzione: L'operatore non può essere eliminato in quanto ha già eseguito movimenti o modifiche.\nProcedere con la disabilitazione dell'operatore ${principal.UserName}?`,
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Warning
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          let result = await dialog.afterClosed().toPromise();
          if (result != MessageBoxResult.YES) {
            return true; //cancel
          }

          await this.disablePrincipal(principal);
        }
        await this.loadPrincipals();
        this.grid.instance.refresh();
      }
    }
    catch (e) {
      this.logService.EnqueueLog({
        UserName: this.identityService.user.UserName,
        Origin: "PRINCIPALS_CONFIG",
        Operation: "onRowRemoving",
        Level: "ERROR",
        DateTime: new Date(),
        Data: e
      });
      console.error(e);
    }
  }
  onRowRemoving(e) {
    e.cancel = this.removeRow(e);
  }
  
  async onRowUpdating(e) {
    let principal: Principal = this.PrincipalList.find(p => p.PID == e.key);
    Object.assign(principal, e.newData);
    if (e.newData["EnableBadgePassword"] !== undefined && e.newData["EnableBadgePassword"] === true) {
      //sto modificando l'attivazione del badgePassword, se lo sto attivando devo verificare che sia specificata una password
      try {
        if (isNullOrWhiteSpace(principal.BadgePassword)) {
          if (isNullOrWhiteSpace(principal.PwdInEditing)) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: 'Attenzione',
              message: 'Inserisci o cambia la password per attivare il login con il badge',
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Warning
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();
            e.cancel = true;
            //e.newData["EnableBadgePassword"] = false;
            principal.EnableBadgePassword = false;
            this.grid.instance.refresh(true);
            return;
          }
        }
      }
      catch (e) {
        this.logService.EnqueueLog({
          UserName: this.identityService.user.UserName,
          Origin: "PRINCIPALS_CONFIG",
          Operation: "BADGE_PASSWORD_CHECK",
          Level: "ERROR",
          DateTime: new Date(),
          Data: e
        });
        console.error(e);
      }
    }
    principal.Modified = true;
    await this.save();
  }

  private async FillPrincipalRole() {
    try {
      this.ngxService.start();
      this.RolesList = await this.asseService.loadRoles();
      this.InError = false;
      if (this.PrincipalList != null) {
        this.PrincipalList.forEach(p => {
          let role: Role = this.RolesList.find(r => r.RID == p.RID);

          if (!isNullOrUndefined(role)) {
            p.Role = role;
          }
        });
      }
      this.ngxService.stop();
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Non è stato possibile caricare i possibili Ruoli",
            details: error.Detail.UIMessage,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
}


//@NgModule({
//    declarations: [
//        PrincipalsConfigComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxTextBoxModule,
//        DxToolbarModule,
//        DxDataGridModule
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
//        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
//        /* { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/ 
//    ],
//    bootstrap: []
//})

//export class PrincipalsConfigModule {

//}
