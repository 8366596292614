

export class EPollVotingSessions {
  PAID: number;
  StartSession: Date;
  PID: number;
  EndSession?: Date;
  Esito: string;
  
  

  constructor(init?: Partial<EPollVotingSessions>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: EPollVotingSessions[]): EPollVotingSessions[] {
    return list.map(item => { return new EPollVotingSessions(item); });
  }
  static ToInstance(item: EPollVotingSessions): EPollVotingSessions {
    return new EPollVotingSessions(item);
  }

}

