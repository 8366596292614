import { Component, NgModule, HostListener, ViewChild, AfterViewInit, OnDestroy, PipeTransform, Pipe} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthService, ConfigurationService, IdentityService } from '../../services';
import { DxButtonModule, DxButtonComponent } from 'devextreme-angular/ui/button';
import { DxTextBoxModule, DxTextBoxComponent } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { DxLoadPanelModule } from "devextreme-angular/ui/load-panel";
import { isNullOrWhiteSpace } from '../../utils/utils';
import { Subscription, timer } from 'rxjs';
import { formatMessage } from 'devextreme/localization';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { BrowserModule } from '@angular/platform-browser';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements AfterViewInit, OnDestroy {
    
  @ViewChild("UsernameTxtBox") UsernameTxtBox: DxTextBoxComponent;
  @ViewChild("EnterButton") EnterButton: DxButtonComponent;
  @ViewChild("TFAButton") TFAButton: DxButtonComponent;
  login = '';
  password = '';
  otp = '';
  returnUrl: string;
  error = '';
  appTitle: string;
  loadPanelVisible: boolean = false;

  @HostListener('document:keydown.enter', ['$event']) HotKeyEnterExecuted(event: KeyboardEvent) {   
    this.EnterButton.instance.element().click();
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  user = null;

  constructor(private authService: AuthService, public configService: ConfigurationService,
    private route: ActivatedRoute, public identityService: IdentityService,
    private router: Router) {
      this.configService.OnSettingsLoaded.subscribe(x => {
          this.appTitle = this.configService.serverSettings.appTitle;
      });
  }

  ngAfterViewInit(): void {
    this.UsernameTxtBox.instance.focus();
  }

  countDown: Subscription;
  counter = 120;
  tick = 1000;
  
  ngOnDestroy() {
    if (this.countDown != null)
      this.countDown.unsubscribe();
    this.countDown = null;
  }

  //ngOnInit(): void {
  //  this.counter = 120;

  //  this.countDown = timer(0, this.tick)
  //    .subscribe(() => { if (this.counter <= 0) { this.countDown = null; return; } else { --this.counter } });
  //}

  onLoginClick(args) {
    if (!args.validationGroup.validate().isValid) {
      return;
    }
    this.loadPanelVisible = true;
    var that = this;
    this.authService.logIn(this.login, this.password)
      .pipe(first())
      .subscribe(
        data => {

          this.user = data;
          this.error = null;

          if (!data.TwoFactorEnabled || isNullOrWhiteSpace(data.PhoneNumber)) {
            this.router.navigate(['/home']);
          }
          else {

            this.counter = data.OTPcountDown;

            this.countDown = timer(0, this.tick)
              .subscribe(() => {
                if (this.counter <= 0) {
                  this.backLogin(null);
                  return;
                }
                else {
                  --this.counter
                }
              });
          }


          this.loadPanelVisible = false;
        },
        error => {
          console.log({ error });
          try {
            if ('IsAssembleaException' in error)
              this.error = formatMessage(error.Code, "");
            else
              this.error = error.Message;
          } catch (e) {
            console.log({ e });
            this.error = formatMessage("TEXT_ERROR_GENERIC", "");
          }
          this.loadPanelVisible = false;
        });
    args.validationGroup.reset();
  }

  on2FAClick(args) {
    if (!args.validationGroup.validate().isValid) {
      return;
    }
    this.loadPanelVisible = true;
    var that = this;
    this.authService.verify2FA(this.user, this.otp)
      .pipe(first())
      .subscribe(
        data => {
          this.error = null;
          if (this.countDown != null)
            this.countDown.unsubscribe();
          this.countDown = null;

          this.router.navigate(['/home']);
          this.loadPanelVisible = false;
        },
        error => {
          console.log(error);
          try {
            if ('IsAssembleaException' in error)
              this.error = formatMessage(error.Code, "");
            else
              this.error = error.Message;
          } catch (e) {
            console.log(e);
            this.error = formatMessage("TEXT_ERROR_GENERIC", "");
          }
          this.loadPanelVisible = false;
        });
    args.validationGroup.reset();

  }


  backLogin(e) {
    this.user = null;
    this.error = null;
    if (this.countDown != null)
      this.countDown.unsubscribe();
    this.countDown = null;
  }
}


@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxButtonModule,
    //    DxCheckBoxModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
    DxLoadPanelModule,
    BrowserModule,
    ApplicationPipesModule,
    //DxToolbarModule,
    //DxDataGridModule,
    //DxListModule,
    //DxBoxModule,
    //NgxUiLoaderModule
  ],
    declarations: [LoginFormComponent, FormatTimePipe],
    exports: [LoginFormComponent]
})
export class LoginFormModule { }
