<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item location="before"
              cssClass="header-title"
              *ngIf="title && titleVisible"
              [text]="title">
    </dxi-item>
    <dxi-item location="before" *ngIf="!isCabinaPage"
              widget="dxButton"
              [options]="{              
                icon: 'fas fa-arrow-circle-left',
                onClick: navigateToHome,
              disabled:backDisabled
              }" >
    </dxi-item>
    <dxi-item location="after"
              locateInMenu="auto"
              menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button class="user-button authorization"
                   height="100%"
                   stylingMode="text">
          <div *dxTemplate="let data of 'content'">
            <app-user-panel [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>
    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
    </div>
    <dxi-item location="center" template="logo" locateInMenu="never"  *ngIf="!isCabinaPage">

    </dxi-item>
    <div *dxTemplate="let data of 'logo'">
      <img src="./assets/images/logo_assemblea_noreflection.png" />
    </div>
  </dx-toolbar>
</header>
