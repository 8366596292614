export class ShareholderType {
  STID: number;
  Descr: string;
  DescrizioneVincolo: string;
  CanDelegate: boolean;
  CanDelegateNotShareholder: boolean;
  CanHaveDelegate: boolean;
  MaxDelegate: number;
  MaxDelegateExtra: number;
  HasLegalRepresentative: boolean;
  CanAccess: boolean;
  CanVote: boolean;
  CanWinAwards: boolean;
  ShowInStatistics: boolean;
  ImgID: string;
  PrintSumInStatistics: boolean;
  STVip: boolean;
  STVipNote: string;
  STVipNoteType?: number;
  IALID?: number;
  IALID2?: number;
  ShareholdersCount?: number;
  MaxShares: number;
  MaxSharesB: number;
  MaxSharesC: number;
  MaxSharesD: number;
  MaxSharesExtra: number;
  MaxSharesBExtra: number;
  MaxSharesCExtra: number;
  MaxSharesDExtra: number;
  DelegateOnlySameType: boolean;
  DelegationOnlyFromSameType: boolean;
  DelegaPerZona: boolean;
  DelegaPerGruppo: boolean;
  MaxRepresentedShareholder: number;
  MaxRepresentedShareholderExtra: number;
  MaxLegalRepresentation: number;
  MaxLegalRepresentationExtra: number;
  EnableMaxLegalRepresentation: boolean;
  EnableMaxRepresentedShareholders: boolean;
  EnableMaxNoS: boolean;
  EnableMaxNoSB: boolean;
  EnableMaxNoSC: boolean;
  EnableMaxNoSD: boolean;

  CanViewRealTimeDataOnline: boolean;
  CanViewDocumentationOnline: boolean;
  CannotRegisterOnline: boolean;
  ExcludedFrom: number;
  ActualPoll: string[];

  Modified: boolean;

  constructor(init?: Partial<ShareholderType>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: ShareholderType[]): ShareholderType[] {
    return list.map(item => { return new ShareholderType(item); });
  }
  static ToInstance(item: ShareholderType): ShareholderType {
    return new ShareholderType(item);
  }
}
